import { forwardRef, useEffect, useImperativeHandle } from 'react';
import BaseForm from 'components/atoms/base-form';
import BaseFormGroup from 'components/atoms/base-form/group';
import BaseFormItem from 'components/atoms/base-form/item';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import BaseInput from 'components/atoms/base-input';
import { mergeFieldsValue } from 'infrastructure/functions';

import { agencyRegistrationValidationSchema } from './validation-schema';

import type { AgencyInfo } from 'infrastructure/classes/agency/agency-info';
import type { IAgencyRegistration } from 'infrastructure/interfaces';
import type {
  IIndependentFormProps,
  IIndependentFormRef,
} from 'infrastructure/interfaces/common/i-independent-form';

const prepareValue = (data?: AgencyInfo): IAgencyRegistration => {
  let defaultValue: IAgencyRegistration = {
    agencyId: null,
  };
  if (data) {
    defaultValue = mergeFieldsValue(defaultValue, data);
  }

  return defaultValue;
};

const FormDisplayName = 'AgencyRegistrationForm';

interface IProps extends IIndependentFormProps {
  data?: AgencyInfo;
}

const AgencyRegistrationForm = forwardRef<
  IIndependentFormRef<IAgencyRegistration>,
  IProps
>((props, ref) => {
  const { data } = props;

  const { t } = useTranslation();

  const formik = useFormik<IAgencyRegistration>({
    initialValues: prepareValue(data),
    onSubmit: () => {},
    validateOnChange: false,
    validationSchema: agencyRegistrationValidationSchema(),
  });

  const submitForm = async () => {
    await formik.submitForm();
    const isValid = await formik
      .validateForm()
      .then((res) => !Object.keys(res).length);

    return {
      values: formik.values,
      formName: FormDisplayName,
      isValid,
    };
  };

  useImperativeHandle(ref, () => ({
    submit: submitForm,
    reset: formik.resetForm,
  }));

  useEffect(() => {
    if (data) formik.resetForm({ values: prepareValue(data) });
  }, [data]);

  return (
    <BaseForm formik={formik}>
      <BaseFormGroup label={t('labels.registration')}>
        <BaseFormItem
          name="agencyId"
          label={t('labels.registrationId')}
          isBlackLabel
        >
          <BaseInput />
        </BaseFormItem>
      </BaseFormGroup>
    </BaseForm>
  );
});

AgencyRegistrationForm.displayName = FormDisplayName;

export default AgencyRegistrationForm;
