import React from 'react';
import BaseTableCard from 'components/atoms/base-table-card';
import BaseGrid from 'components/atoms/base-grid';
import { useTranslation } from 'react-i18next';

import { useAgencyViewForm } from './hook';

import type { AgencyInfo } from 'infrastructure/classes/agency/agency-info';

interface IProps {
  info: AgencyInfo;
}

const AgencyViewForm: React.FC<IProps> = (props) => {
  const { info } = props;

  const { t } = useTranslation();

  const {
    contactInformation,
    agencyAddress,
    agencyDetails,
    agencyRegistration,
  } = useAgencyViewForm({ info });

  return (
    <BaseGrid rowGap={40}>
      <BaseGrid columns={2} columnGap={20}>
        <BaseTableCard
          title={t('labels.contactInformation')}
          items={contactInformation}
        />
        <BaseTableCard title={t('labels.address')} items={agencyAddress} />
      </BaseGrid>

      <BaseGrid columns={2} columnGap={20}>
        <BaseTableCard
          title={t('labels.registration')}
          items={agencyRegistration}
        />
        <BaseTableCard
          title={t('labels.irs')}
          columns={2}
          items={agencyDetails}
        />
      </BaseGrid>
    </BaseGrid>
  );
};

export default AgencyViewForm;
