import useUser from 'utils/useUser';
import {
  useRpmActivity,
  useSocketActions,
  useActivityFilter,
} from 'infrastructure/hooks/rpm';
import { useAppDispatch, useAppSelector } from 'store';
import { useEffect } from 'react';
import { BroadcastTopics, SocketRoutes } from 'services/wsService';
import { isAgencyDoctor } from 'utils/userTypeHelper';
import logger from 'services/logger';

import type { SortState } from 'infrastructure/types';
import type {
  IAsignSocketData,
  ISocketCallEndedData,
  ISocketOnCallData,
  IStopSlaTimerSocketData,
  IUpdateReadingAction,
} from 'infrastructure/interfaces/i-rpm-patient-activity';

export const useActivityDashboard = () => {
  const { userType } = useUser();
  const { filter, updateQueryParams } = useActivityFilter();

  const { activity, isLoading, isFetching, queryKey } = useRpmActivity(filter);
  const {
    stopSlaTimerAction,
    reservedReadingAction,
    setOnCallPatientAction,
    onActionChange,
  } = useSocketActions(queryKey);
  const {
    activityActions: { resetActions },
  } = useAppDispatch();

  const socket = useAppSelector((state) => state.socket);

  const loading = isLoading || isFetching;

  const onSort = (field: string, sort: SortState) => {
    if (field === 'createdAt')
      updateQueryParams({ reverse: sort ? !filter.reverse : undefined });
    if (field === 'name' || field === 'readingType')
      updateQueryParams({ name: sort ? sort.toUpperCase() : undefined });
    if (field === '' && sort === '')
      updateQueryParams({ reverse: undefined, name: undefined });
  };

  useEffect(() => {
    if (socket.client && socket.isConnected) {
      socket.client.on(
        BroadcastTopics.SlaTimerStop,
        (data: IStopSlaTimerSocketData) => stopSlaTimerAction(data),
      );
      if (isAgencyDoctor(userType)) {
        socket.client.on(
          BroadcastTopics.ReservedReadingStateChange,
          (data: IAsignSocketData) => reservedReadingAction(data),
        );
      }
      socket.client.on(BroadcastTopics.CallInit, (data: any) => {
        if (data.isRpmCall) {
          logger.info('CALL INITIALIZED', data);
        }
      });
      socket.client.on(BroadcastTopics.Call, (data: ISocketOnCallData) => {
        if (data.isRpmCall) {
          setOnCallPatientAction(data.patientGuid, true);
        }
      });
      socket.client.on(
        BroadcastTopics.CallEnded,
        (data: ISocketCallEndedData) => {
          if (data.isRpmCall) {
            setOnCallPatientAction(data.patientGuid, false);
          }
        },
      );
      socket.client.on(
        BroadcastTopics.NewReadingAction,
        (data: IUpdateReadingAction) => {
          onActionChange({ ...data, newAction: true });
        },
      );
    }

    return () => {
      if (socket.client && socket.isConnected === true) {
        socket.client.off(BroadcastTopics.SlaTimerStop);
        socket.client.off(BroadcastTopics.ReservedReadingStateChange);
        socket.client.off(BroadcastTopics.CallInit);
        socket.client.off(BroadcastTopics.Call);
        socket.client.off(BroadcastTopics.CallEnded);
        socket.client.off(BroadcastTopics.NewReadingAction);
      }
    };
  }, [socket]);

  useEffect(() => {
    if (socket.client && socket.isConnected && filter.slaReadings) {
      socket.client.send(SocketRoutes.JoinToRoom, { room: 'rpmDashboardSla' });
    }
  }, [socket.isConnected, filter.slaReadings]);

  useEffect(() => {
    return () => {
      if (socket.client && socket.isConnected === true && filter.slaReadings) {
        socket.client.send(SocketRoutes.LeaveRoom, { room: 'rpmDashboardSla' });
      }
    };
  }, [filter.slaReadings]);

  return {
    activity,
    resetActions,
    loading,
    filter,
    queryKey,
    updateQueryParams,
    onSort,
  };
};
