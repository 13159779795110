import { useMutation } from '@tanstack/react-query';
import { ccmPageAvailableStatuses } from 'constants/patient_statuses';
import dataService from 'services/dataService';
import { showResult } from 'infrastructure/hooks/utils';
import { apiUrlGenerator } from 'utils/useTable';
import { isCcmModule, isRpmModule } from 'infrastructure/functions';
import forms from 'constants/forms';

import type { ModuleType } from 'infrastructure/enums';

interface Props {
  searchKey: string;
  searchValue: string;
}
interface SearchPatientProps extends Props {
  module: ModuleType;
}
interface IItem {
  guid: string;
  firstName: string;
  lastName: string;
  birthDate: string;
}
interface IResponse {
  count: number;
  items: IItem[];
}

export const options = forms.patientFilterOptions.map((opt) => ({
  value: opt.id,
  label: opt.label,
  placeholder: opt.placeholder,
}));

const MAX_LENGTH_FOR_FETCH_DOB = 10;

const generateFilterLabel = (item: IItem) =>
  `${item.firstName} ${item.lastName} - ${item.birthDate}`;

const searchPatients = async ({
  searchKey,
  searchValue,
  module,
}: SearchPatientProps) => {
  if (
    searchKey === options[3].value &&
    searchValue.length !== MAX_LENGTH_FOR_FETCH_DOB
  )
    return;

  const ccmStatus = ccmPageAvailableStatuses.join(',');
  const url = 'core/patients';
  const items = 100;
  const page = 1;
  const filters = {
    [searchKey]: searchValue.trim(),
    patientRequestType: 'search',
    status: isRpmModule(module) ? 'active_onboarding_inactive' : 'all',
    ccmStatus: isCcmModule(module) ? ccmStatus : undefined,
  };

  const { data, error } = await dataService.getList<IResponse>(
    apiUrlGenerator(url, items, page, filters),
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return (
    data?.items.map((item) => ({
      value: item.guid,
      label: generateFilterLabel(item),
    })) ?? []
  );
};

export const useSearchPatient = ({ module }: { module: ModuleType }) => {
  const { mutate, data, isLoading } = useMutation({
    mutationFn: ({ searchKey, searchValue }: Props) =>
      searchPatients({ searchKey, searchValue, module }),
  });
  return { search: mutate, dataOptions: data, isLoading };
};
