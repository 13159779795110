import React, { useEffect, useState } from 'react';
import BaseSelectBox from 'components/atoms/base-select-box';
import { useTranslation } from 'react-i18next';
import { weekDaysDataSource } from 'infrastructure/data-sources/week-days';
import classNames from 'classnames';
import BaseButton from 'components/atoms/baseButton';

import s from './styles.module.scss';

import type { IBaseSelectBoxOption } from 'components/atoms/base-select-box/types';
import type { ICommonInputProps } from 'infrastructure/interfaces';

interface IEmrReportPicker extends ICommonInputProps<string> {}

type RepeatOn = 'Weekly' | 'Monthly' | 'None';

const EmrReportPicker: React.FC<IEmrReportPicker> = (props) => {
  const { value, readonly, onChange } = props;

  const { t } = useTranslation();

  const onOptions: Array<IBaseSelectBoxOption<RepeatOn>> = [
    {
      label: t('labels.none'),
      value: 'None',
    },
    {
      label: t('dates.weekly'),
      value: 'Weekly',
    },
    {
      label: t('dates.monthly'),
      value: 'Monthly',
    },
  ];

  const weekDays = weekDaysDataSource();

  const monthDays: Array<IBaseSelectBoxOption<string>> = Array.from(
    { length: 30 },
    (_, i) => {
      const iteration = i + 1;

      const result = {
        label: iteration,
        value: i < 9 ? `0${iteration}` : `${iteration}`,
      };

      return result;
    },
  );

  const [repeatOn, setRepeatOn] = useState<RepeatOn>(onOptions[0].value);

  const [repeatEvery, setRepeatEvery] = useState<string>(weekDays[1].id);

  const onChangeRepeatOn = (val: RepeatOn) => {
    let repeat: string | null = weekDays[1].id as string;
    setRepeatOn(val);
    if (val === 'Monthly') repeat = monthDays[0].value as string;
    setRepeatEvery(repeat);
    if (onChange) {
      const payload = val === 'None' ? null : `${val}-${repeat}`;
      onChange(payload);
    }
  };

  const onChangeRepeatEvery = (val: string) => {
    setRepeatEvery(val);
    if (onChange) onChange(`${repeatOn}-${val}`);
  };

  const wrapperClassNames = classNames(s['input-wrapper'], {
    [s.readonly]: readonly,
  });

  useEffect(() => {
    if (value) {
      const [repeatOnValue, repeatEveryValue] = value?.split('-');
      setRepeatOn(repeatOnValue as RepeatOn);
      setRepeatEvery(repeatEveryValue);
    }
  }, [value]);

  return (
    <div className={wrapperClassNames}>
      <div className={s.input}>
        <span data-cy="repeatEvery-label" className={s.label}>
          {t('labels.repeat')}
        </span>
        <BaseSelectBox
          readonly={readonly}
          plaintext={readonly}
          value={repeatOn}
          options={onOptions}
          onChange={onChangeRepeatOn}
        />
      </div>
      {repeatOn === 'Weekly' && (
        <div className={s.days} data-cy="reports-select-date">
          {weekDays.map((day) => (
            <BaseButton
              rounded
              type={repeatEvery === day.id ? 'primary' : 'secondary'}
              key={day.id}
              onClick={() => {
                onChangeRepeatEvery(day.id);
              }}
              label={day.name[0]}
            />
          ))}
        </div>
      )}
      {repeatOn === 'Monthly' && (
        <div className={s.input}>
          <span data-cy="repeatOn-label" className={s.label}>
            {t('labels.repeatOn')}
          </span>
          <BaseSelectBox
            value={repeatEvery}
            options={monthDays}
            onChange={onChangeRepeatEvery}
          />
        </div>
      )}
    </div>
  );
};

export default EmrReportPicker;
