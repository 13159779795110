import i18next from 'i18next';
import { Link } from 'react-router-dom';
import { Routes } from 'infrastructure/consts/routes';
import { changeFormat } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';

import type { AgencyInfo } from 'infrastructure/classes/agency/agency-info';
import type { TableProps } from 'antd';

export const clinicColumns = ({
  limit = 100,
  page = 1,
}): TableProps<AgencyInfo>['columns'] => {
  return [
    {
      title: '№',
      key: 'guid',
      align: 'center',
      width: '50px',
      render(value, record, index) {
        let order = index + 1;
        if (page > 1) {
          return <b data-cy="agencyListTable-№">{(order += limit * page)}</b>;
        }
        return <b data-cy="agencyListTable-№">{order}</b>;
      },
    },
    {
      title: i18next.t('labels.hhaName'),
      key: 'legalname',
      render(_, record) {
        return (
          <Link
            data-cy="agencyListTable-agency-link"
            to={Routes.adminAgencyDetail(record.guid)}
          >
            <b>{record.legalname}</b>
          </Link>
        );
      },
    },
    {
      title: i18next.t('labels.hhaId'),
      key: 'agencyId',
      render: (_, record) => record.agencyId,
    },
    {
      title: i18next.t('labels.email'),
      key: 'email',
      render: (_, record) => record.email,
    },
    {
      title: i18next.t('labels.address'),
      key: 'address',
      render: (_, record) => record.addresses,
    },
    {
      title: i18next.t('labels.entryTime'),
      key: 'createdAt',
      render: (_, record) =>
        changeFormat(
          record.createdAt,
          DateFormats['MMM dd yyyy HH:mm:ss'],
          DateFormats['MMM dd yyyy | hh:mm a'],
        ),
    },
  ];
};
