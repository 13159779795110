import BaseModal from 'components/atoms/base-modal';
import { useTranslation } from 'react-i18next';
import SyntaxHighlight from 'components/atoms/jsonHighlighter';
import { copyTextWithResult } from 'utils/helpers/copy-text-with-result';

import s from './style.module.scss';
import ModalFooter from './footer';

import type { BillingClaimsAthenaResult } from 'infrastructure/hooks/billingClaimsAthena/types';
import type { FC } from 'react';

interface Props {
  result: BillingClaimsAthenaResult;
  filename: string;
  isOpen: boolean;
  onCancel: () => void;
}

const ModalBody: FC<Props> = ({ result, filename, isOpen, onCancel }) => {
  const { t } = useTranslation();

  return (
    <BaseModal
      title={t('labels.result')}
      open={isOpen}
      onCancel={onCancel}
      padding={0}
      width={1000}
      withFooter={
        <ModalFooter
          copyText={() => copyTextWithResult(JSON.stringify(result, null, 2))}
        />
      }
    >
      <div>
        <div className={s['body-header']}>{filename}</div>
        <div className={s['result-code-wrapper']}>
          <div>
            {t('labels.total')}: {result.total}
          </div>
          <div>
            {t('labels.success')}: {result.success}
          </div>
          <SyntaxHighlight jsonString={JSON.stringify(result, null, 2)} />
        </div>
      </div>
    </BaseModal>
  );
};

export default ModalBody;
