import { buildTableCardItems } from 'components/atoms/base-table-card';
import { useTranslation } from 'react-i18next';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';

import type { AgencyInfo } from 'infrastructure/classes/agency/agency-info';

interface IProps {
  info: AgencyInfo;
}

export const useAgencyViewForm = ({ info }: IProps) => {
  const { t } = useTranslation();

  const contactInformation = buildTableCardItems([
    {
      label: t('labels.legalName'),
      value: info.legalname,
    },
    {
      label: t('labels.businessEmail'),
      value: info.email,
      originView: true,
    },
    {
      label: t('labels.businessPhone'),
      value: info.phone,
    },
    {
      label: t('labels.businessFax'),
      value: info.fax,
    },
  ]);

  const agencyAddress = buildTableCardItems([
    {
      label: `${t('labels.addressLine')} 1`,
      value: info.addressLine1,
    },
    {
      label: `${t('labels.addressLine')} 2`,
      value: info.addressLine2,
    },
    {
      label: t('labels.city'),
      value: info.city,
    },
    {
      label: t('labels.state'),
      value: info.state,
    },
    {
      label: t('labels.zipCode'),
      value: info.zip,
    },
  ]);

  const agencyDetails = buildTableCardItems([
    {
      label: t('labels.npi'),
      value: info.npiNumber,
    },
    {
      label: t('labels.taxIdNumber'),
      value: info.taxIdNumber,
      originView: true,
    },
    {
      label: t('labels.medicalIdTpiNumber'),
      value: info.medicalIdTpiNumber,
      originView: true,
    },
    {
      label: t('labels.stateIdNumber'),
      value: info.stateIdNumber,
      originView: true,
    },
    {
      label: t('labels.taxonomyNumber'),
      value: info.taxonomyNumber,
    },
    {
      label: t('labels.medicareNumber'),
      value: info.medicareNumber,
    },
  ]);

  const agencyRegistration = buildTableCardItems([
    {
      label: t('labels.id'),
      value: info.agencyId,
      originView: true,
    },
    {
      label: t('labels.registeredAt'),
      value: DateFormatter({
        date: info.createdAt,
        format: DateFormats['MMM d yyyy, hh:mm a'],
      }),
    },
  ]);

  return {
    contactInformation,
    agencyAddress,
    agencyDetails,
    agencyRegistration,
  };
};
