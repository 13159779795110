import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import BaseForm from 'components/atoms/base-form';
import BaseFormGroup from 'components/atoms/base-form/group';
import BaseFormItem from 'components/atoms/base-form/item';
import BaseGrid from 'components/atoms/base-grid';
import BaseInput from 'components/atoms/base-input';
import StateSelectBox from 'components/molecules/states-types-select-box';
import { mergeFieldsValue } from 'infrastructure/functions';

import { agencyAddressValidationSchema } from './validation-schema';

import type { AgencyInfo } from 'infrastructure/classes/agency/agency-info';
import type { IAgencyAddress } from 'infrastructure/interfaces';
import type {
  IIndependentFormProps,
  IIndependentFormRef,
} from 'infrastructure/interfaces/common/i-independent-form';

const FormDisplayName = 'AgencyAddressForm';

const prepareValue = (data?: AgencyInfo): IAgencyAddress => {
  let defaultValue: IAgencyAddress = {
    addressLine1: '',
    addressLine2: '',
    city: null,
    state: null,
    zip: null,
  };
  if (data) {
    defaultValue = mergeFieldsValue(defaultValue, data);
  }

  return defaultValue;
};

interface IProps extends IIndependentFormProps {
  data?: AgencyInfo;
}

const AgencyAddressForm = forwardRef<
  IIndependentFormRef<IAgencyAddress>,
  IProps
>((props, ref) => {
  const { data } = props;

  const { t } = useTranslation();

  const formik = useFormik<IAgencyAddress>({
    initialValues: prepareValue(data),
    onSubmit: () => {},
    validateOnChange: false,
    validationSchema: agencyAddressValidationSchema(),
  });

  const submitForm = async () => {
    await formik.submitForm();
    const isValid = await formik
      .validateForm()
      .then((res) => !Object.keys(res).length);

    return {
      values: formik.values,
      formName: FormDisplayName,
      isValid,
    };
  };

  useImperativeHandle(ref, () => ({
    submit: submitForm,
    reset: formik.resetForm,
  }));

  useEffect(() => {
    if (data) formik.resetForm({ values: prepareValue(data) });
  }, [data]);

  return (
    <BaseForm formik={formik}>
      <BaseFormGroup label={t('labels.address')}>
        <BaseGrid columns={2}>
          <BaseFormItem
            name="addressLine1"
            label={t('labels.addressLine1')}
            isBlackLabel
          >
            <BaseInput />
          </BaseFormItem>
          <BaseFormItem name="city" label={t('labels.city')} isBlackLabel>
            <BaseInput />
          </BaseFormItem>
          <BaseFormItem
            name="addressLine2"
            label={t('labels.addressLine2')}
            isBlackLabel
          >
            <BaseInput />
          </BaseFormItem>
          <BaseFormItem name="state" label={t('labels.state')} isBlackLabel>
            <StateSelectBox withNone />
          </BaseFormItem>
          <BaseFormItem name="zip" label={t('labels.zipCode')} isBlackLabel>
            <BaseInput />
          </BaseFormItem>
        </BaseGrid>
      </BaseFormGroup>
    </BaseForm>
  );
});

AgencyAddressForm.displayName = FormDisplayName;

export default AgencyAddressForm;
