import GeneralCommentIcon from 'components/atoms/icons/generalCommentIcon';
import { useMemo, useState } from 'react';
import { makeid } from 'utils/helpers/makeid';
import CallControlIcon from 'components/atoms/icons/callControlIcon';
import SmsControlIcon from 'components/atoms/icons/smsControlIcon';
import { getPatientInfoKey } from 'infrastructure/hooks/patient/use-patient-info';
import { useQueryClient } from '@tanstack/react-query';
import useUser from 'utils/useUser';
import { useDoctorInfo } from 'infrastructure/hooks/doctor/use-doctor-info';
import { useAppSelector } from 'store';
import { isMobile } from 'utils/deviceHelper';
import {
  hasAdminPanelAccess,
  hasClinicalRole,
  hasPatientAcceptanceRole,
} from 'utils/accessManager/helpers';
import { isAdmin } from 'utils/userTypeHelper';
import { useComment } from 'infrastructure/hooks/use-comment';
import {
  useCallButtons,
  useSmsButtons,
  useSendSms,
} from 'infrastructure/hooks/control-panel';

import type { ICreateComment, IPatient } from 'infrastructure/interfaces';

interface Props {
  patientGuid: string;
}

export const useInteractionActionButtons = ({ patientGuid }: Props) => {
  const [commentsPopupOpen, setCommentsPopupOpen] = useState(false);
  const [smsPopupOpen, setSmsPopupOpen] = useState(false);
  const [isPrimaryNumber, setIsPrimaryNumber] = useState(false);
  const [isRpmSms, setIsRpmSms] = useState(true);
  const device = useAppSelector((state) => state.user.deviceType);
  const { roleNames, userType, timezone } = useUser();
  const queryClient = useQueryClient();
  const { sendSms, smsLoading } = useSendSms();
  const { loading: commentLoading, sendComment } = useComment({
    patientGuid,
  });

  const patientInfoKey = getPatientInfoKey(patientGuid);
  const cachedPatientInfo = queryClient.getQueryData<IPatient>(patientInfoKey);
  const { doctorInfo, doctorInfoLoading } = useDoctorInfo({
    doctorGuid: cachedPatientInfo?.doctorGuid,
  });
  const { callButtons: rpmCallButtons, callButtonsAlert: rpmCallButtonsAlert } =
    useCallButtons({
      isCcmPanel: false,
      isRpmPanel: true,
      patientGuid,
      userType,
      doNotCall: cachedPatientInfo?.doNotCall,
      emergencyContactNumber: cachedPatientInfo?.emergencyContactNumber ?? '',
      patientHomeNumber: cachedPatientInfo?.homeNumber ?? '',
      patientPhone: cachedPatientInfo?.loginInfo?.phone ?? '',
      doctorPhone: doctorInfo?.phone,
      roleNames,
      patientTimeZone: timezone,
    });
  const { callButtons: ccmCallButtons, callButtonsAlert: ccmCallButtonsAlert } =
    useCallButtons({
      isCcmPanel: true,
      isRpmPanel: false,
      patientGuid,
      userType,
      doNotCall: cachedPatientInfo?.doNotCall,
      emergencyContactNumber: cachedPatientInfo?.emergencyContactNumber ?? '',
      patientHomeNumber: cachedPatientInfo?.homeNumber ?? '',
      patientPhone: cachedPatientInfo?.loginInfo?.phone ?? '',
      doctorPhone: doctorInfo?.phone,
      roleNames,
      patientTimeZone: timezone,
    });
  const prmSmsButtons = useSmsButtons({
    isAdminPanel: true,
    patientHasSecondaryNumber: Boolean(cachedPatientInfo?.homeNumber),
    setIsPrimaryNumber,
    setSmsPopupOpen: () => {
      setIsRpmSms(true);
      setSmsPopupOpen(true);
    },
    userType,
  });
  const ccmSmsButtons = useSmsButtons({
    isAdminPanel: true,
    patientHasSecondaryNumber: Boolean(cachedPatientInfo?.homeNumber),
    setIsPrimaryNumber,
    setSmsPopupOpen: () => {
      setIsRpmSms(false);
      setSmsPopupOpen(true);
    },
    userType,
  });
  const showCommentButton = useMemo(
    () =>
      hasPatientAcceptanceRole(roleNames) ||
      hasAdminPanelAccess(roleNames) ||
      hasClinicalRole(roleNames) ||
      isAdmin(userType),
    [],
  );

  const interactionButtons = [
    {
      key: makeid(5),
      label: 'General Comments',
      Icon: GeneralCommentIcon,
      loading: commentLoading,
      handleClick: () => {
        setCommentsPopupOpen(true);
      },
      render: showCommentButton,
    },
    {
      key: makeid(5),
      label: 'RPM Call',
      Icon: CallControlIcon,
      loading: false,
      options: rpmCallButtons,
      render: !isMobile(device),
    },
    {
      key: makeid(5),
      label: 'CCM Call',
      Icon: CallControlIcon,
      loading: smsLoading,
      options: ccmCallButtons,
      render: !isMobile(device),
    },
    {
      key: makeid(5),
      label: 'RPM SMS',
      Icon: SmsControlIcon,
      loading: smsLoading,
      options: prmSmsButtons,
      render: !isMobile(device),
    },
    {
      key: makeid(5),
      label: 'CCM SMS',
      Icon: SmsControlIcon,
      loading: false,
      options: ccmSmsButtons,
      render: !isMobile(device),
    },
  ].filter(({ render }) => render);

  const sendCommentHandler = async (data: ICreateComment) => {
    const response = await sendComment(data);
    setCommentsPopupOpen(false);
    return response;
  };

  return {
    interactionButtons,
    commentsPopupOpen,
    doctorInfoLoading,
    smsPopupOpen,
    isPrimaryNumber,
    isRpmSms,
    smsLoading,
    commentLoading,
    setCommentsPopupOpen,
    sendComment: sendCommentHandler,
    setSmsPopupOpen,
    sendSms,
    rpmCallButtonsAlert,
    ccmCallButtonsAlert,
  };
};
