import React from 'react';
import { Input } from 'antd';

import type { ICommonInputProps } from 'infrastructure/interfaces';

interface IBaseInputProps extends ICommonInputProps<string> {
  maxLength?: number;
  allowClear?: boolean;
  addonAfter?: React.ReactNode;
}

const BaseInput: React.FC<IBaseInputProps> = (props) => {
  const {
    value,
    defaultValue,
    placeholder,
    disabled,
    plaintext,
    readonly,
    invalid,
    onChange,
    className,
    style,
    maxLength,
    allowClear,
    addonAfter,
  } = props;

  return (
    <Input
      value={value}
      defaultValue={defaultValue}
      placeholder={placeholder}
      readOnly={readonly}
      disabled={disabled}
      className={className}
      style={style}
      allowClear={allowClear}
      addonAfter={addonAfter}
      onChange={(e) => {
        if (onChange) onChange(e.target.value);
      }}
      variant={plaintext ? 'borderless' : 'outlined'}
      status={invalid ? 'error' : undefined}
      maxLength={maxLength}
    />
  );
};

export const BaseInputDisplayName = 'BaseInput';
BaseInput.displayName = BaseInputDisplayName;

export default BaseInput;
