import { useTranslation } from 'react-i18next';
import useUser from 'utils/useUser';

import s from './style.module.scss';
import EligibilityUpload from './upload';
import EligibilityUpdateCredentials from './updateCredentials';

import type { FC } from 'react';

interface Props {
  onUpload: () => any;
}

const EligibilityHeader: FC<Props> = ({ onUpload }) => {
  const { t } = useTranslation();
  const { isRootAdmin } = useUser();

  return (
    <div className={s['title-wrapper']}>
      <p className={s.title}>{t('labels.eligibilityTool')}</p>
      <div className={s['buttons-wrapper']}>
        {isRootAdmin && <EligibilityUpdateCredentials />}
        <EligibilityUpload onUpload={onUpload} />
      </div>
    </div>
  );
};

export default EligibilityHeader;
