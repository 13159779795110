import { type FC } from 'react';

import ReactQuill from './reactQuill';

import type { Props } from './reactQuill';

const BaseRichTextEditor: FC<Props> = ({ value = '', setValue, disabled }) => {
  return <ReactQuill setValue={setValue} value={value} disabled={disabled} />;
};

export default BaseRichTextEditor;
