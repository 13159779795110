import useApi from '../use-api';

import type {
  IAgencyInfo,
  ICreateAgencySchema,
} from 'infrastructure/interfaces';

const apiUrl = 'admins/admin-panel/agencies';

type UseCreateAgency = {
  loading: boolean;
  createAgency: (data: ICreateAgencySchema) => Promise<IAgencyInfo>;
};

export const useCreateAgency = (): UseCreateAgency => {
  const { loading, createData } = useApi();

  const createAgency = async (agency: ICreateAgencySchema) => {
    const data = await createData<IAgencyInfo>(apiUrl, agency);

    return data;
  };

  return { loading, createAgency };
};
