const telemedUrl = process.env.REACT_APP_TELEMED_URL;
const filesDotComUrl = process.env.REACT_APP_FILES_URL;
const awsConnectUrl = process.env.REACT_APP_AWS_CONNECT_URL;

export const publicPaths = {
  emailSubscription: '/email-subscription',
  emailUnsubscribed: '/email-unsubscribed',
  ssoLogin: '/sso-login',
};

export default {
  notFound: '/404',
  activityReport: '/activity-report',
  adherenceReport: '/adherence-report',
  adherenceReportLast30Days: '/adherence-report-30-days',
  clinicalMetrics: '/clinical-metrics',
  complianceReport: '/compliance-report',
  dashboard: '/',
  interactiveReport: '/interactive-time',
  interactiveTime: '/interactive-time',
  login: '/login',
  medicareBilling: '/medicare-billing',
  onboardingStatus: '/onboarding-status',
  patientDetails: '/patient/details',
  patientGuid: '/patient/:patientGuid',
  practiceOverview: '/practice-overview',
  profile: '/profile',
  servicePlans: '/service-plans',
  adminAgencies: '/admin/agencies',
  adminAgency: '/admin/agency/:agencyGuid',
  adminAgencyEdit: '/admin/agency/:agencyGuid/edit',
  adminAgencyAdd: '/admin/agencies/add',
  adminClinics: '/admin/clinics',
  adminClinic: '/admin/clinic/:clinicGuid',
  adminClinicEdit: '/admin/clinic/:clinicGuid/edit',
  adminClinicAdd: '/admin/clinics/add',
  adminUsers: '/admin/users',
  adminUserAdd: '/admin/users/add',
  adminUser: '/admin/user/:userGuid',
  adminUserEdit: '/admin/user/:userGuid/edit',
  adminAgencyDoctorList: '/admin/agents',
  adminAgencyDoctor: '/admin/agent/:agentGuid',
  adminAgencyDoctorAdd: '/admin/agents/add',
  adminAgencyDoctorEdit: '/admin/agent/:agentGuid/edit',
  adminPatients: '/admin/patients',
  adminPatientAdd: '/admin/patients/add',
  adminPatient: '/admin/patient/:patientGuid',
  adminPatientEdit: '/admin/patient/:patientGuid/edit',
  adminBulkUpload: '/admin/bulk-upload',
  adminTurkConfigurations: '/admin/turk-configurations',
  adminTurkUsersAdd: '/admin/turk-users/add',
  adminTurkUsersEdit: '/admin/turk-users/:agentGuid/edit',
  adminTurkUserList: '/admin/turk-users',
  adminTurkUser: '/admin/turk-users/:agentGuid',
  telmed: telemedUrl,
  bucketReport: filesDotComUrl,
  awsConnectURL: awsConnectUrl,
  patientReferral: 'https://www.accuhealth.tech/patientsubmission',
  BAA: 'https://myaccu.net/BAAform',
  W9: 'https://myaccu.net/w9form',
  ccmPatient: '/ccm/patient/:patientGuid',
  ccm: '/ccm',
  ccmConsent: '/ccm/consent/:patientGuid',
  ccmBillingReport: '/ccm/billing-report',
  ccmBillingReportV2: '/v2/ccm/billing-report',
  patientsStatusReport: '/patients-status',
  statisticsReport: '/statistics-report',
  performanceReview: '/performance-review',
  smsDashboard: '/sms-dashboard',
  turkDashboard: '/turk-dashboard',
  ccmInteractiveTimeReport: '/ccm/interactive-time',
  interactionStatus: '/interaction-status',
  ccmOnboardingStatus: '/ccm/onboarding-status',
  ccmAgentDashboard: '/ccm/agent-dashboard',
  ccmStatusReport: '/ccm/status-report',
  resetPassword: '/reset-password',
  interactiveTimeDashboard: '/interactive-time-dashboard',
  sso: '/sso',
  eligibilityTool: '/eligibility-tool',
  eligibilityToolItems: '/eligibility-tool/:processGuid',
  billingClaimsAthena: '/billing-tools-claims-athena',
  billingClaimsEpic: '/billing-tools-claims-epic',
};
