import React, { useState } from 'react';
import DragAndDropIcon from 'components/atoms/icons/dragAndDropIcon';
import LockIcon from 'components/atoms/icons/lockIcon';
import BaseButton from 'components/atoms/baseButton';
import DocumentIcon from 'components/atoms/icons/documentIcon';
import TrashDeleteIcon from 'components/atoms/icons/trashDeleteIcon';

import type { ChangeEventHandler, DragEventHandler, FC } from 'react';

interface Props {
  uploadFile: (file: File) => Promise<{ error?: any }>;
  deleteFile: () => void;
  fileType: 'csv' | 'json';
  sampleFileUrl?: string;
}

const UploadFile: FC<Props> = ({
  uploadFile,
  deleteFile,
  fileType,
  sampleFileUrl,
}) => {
  const [dropping, setDropping] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [uploadError, setUploadError] = useState<null | string>(null);
  const [localFile, setLocalFile] = useState<File>(new File([], ''));

  const clearFile = () => {
    deleteFile();
    setLocalFile(new File([], ''));
    setUploaded(false);
  };

  const validateFile = (file: File): string | undefined => {
    if (!file.type.includes(fileType)) {
      return `File type of [${file.type}] is not allowed!`;
    }

    if (file.size > 10485760) {
      const size = file.size / (1024 * 1024);
      return `File size of ${size.toFixed(2)} MB is larger than 10MB!`;
    }
    return undefined;
  };

  const handleFile = async (file: File) => {
    setUploaded(true);
    setLocalFile(file);
    const { error } = await uploadFile(file);
    setDropping(false);
    if (error) {
      setUploadError(error);
      setLocalFile(new File([], ''));
      setUploaded(false);
    }
  };

  const handleDrag: DragEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDropping(true);
    } else if (e.type === 'dragleave') {
      setDropping(false);
    }
  };

  const handleDrop: DragEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      const error = validateFile(file);
      if (error) {
        setUploadError(error);
        return;
      }
      setUploadError(null);
      handleFile(file);
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const error = validateFile(file);
      if (error) {
        setUploadError(error);
        return;
      }

      setUploadError(null);
      handleFile(file);
    }
  };

  return (
    <div className="admin-csv-upload">
      <form
        id="form-file-upload"
        encType="multipart/form-data"
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          name="file"
          type="file"
          id="input-file-upload"
          accept={`.${fileType}`}
          onChange={handleChange}
          disabled={uploaded}
        />
        <label
          id="label-file-upload"
          htmlFor="input-file-upload"
          className={dropping ? 'drag-active' : ''}
        >
          {uploaded ? <LockIcon /> : <DragAndDropIcon />}
          <p className="title">
            Drag & Drop or{' '}
            <span className={uploaded ? '' : 'choose-active'}>Choose file</span>{' '}
            to upload
          </p>
          <p className="text">
            File should be in {fileType.toUpperCase()} format. Maximum 1 File up
            to 10 Mb.
            <br />
            {sampleFileUrl && (
              <span>
                File template must be the same as in downloaded sample.
              </span>
            )}
          </p>
          {uploadError && <p className="error-text">{uploadError}</p>}
        </label>
        {dropping && (
          <div
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
      </form>
      {localFile.size > 0 && (
        <div className="uploaded-files-list">
          <div className="uploaded-file">
            <div className="uploaded-file-name">
              <DocumentIcon />
              <p>{localFile.name}</p>
            </div>
            <BaseButton
              icon={<TrashDeleteIcon />}
              type="secondary"
              onClick={clearFile}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadFile;
