import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useClinicList } from 'infrastructure/hooks/clinic/use-clinic-list';
import { Clinic } from 'infrastructure/classes/clinic';
import BaseInput from 'components/atoms/base-input';
import SortOrderSelectBox from 'components/molecules/sort-order-select-box';
import BaseTabs from 'components/atoms/base-tabs';
import { useTranslation } from 'react-i18next';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import List from 'rc-virtual-list';
import { SortOrder } from 'infrastructure/enums';
import SearchIcon from 'components/atoms/icons/searchIcon';
import EmptyTable from 'components/atoms/base-table/report/empty';
import BaseButton from 'components/atoms/baseButton';

import s from './styles.module.scss';
import ClinicFilterFormListItem from './list-item';

import type { IBaseTab } from 'components/atoms/base-tabs';

type tabs = 'all' | 'included' | 'excluded';

type SetString = Set<string>;

export interface ISelectedClinics {
  all?: string[];
  allExcluded?: boolean;
  excluded?: string[];
  included?: string[];
}

interface IProps {
  excluded?: string[];
  included?: string[];
  allExcluded: boolean;
  onChange: (props: ISelectedClinics) => void;
  showOnInteractiveTimeDashboard?: boolean;
  useAdminApi?: boolean;
}

const ClinicFilterForm: React.FC<IProps> = ({
  excluded: excludedParam = [],
  included: includedParam = [],
  showOnInteractiveTimeDashboard,
  allExcluded = false,
  useAdminApi,
  onChange,
}) => {
  const { t } = useTranslation();
  const { clinics, loadClinics, loading, meta } = useClinicList({
    meta: {
      items: 9999999,
      showOnInteractiveTimeDashboard,
      useAdminApi,
    },
  });

  const allClinicGuids = useMemo<SetString>(
    () => new Set(clinics.map((el) => el.guid)),
    [clinics.length],
  );

  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.ASC);
  const [activeTab, setActiveTab] = useState<tabs>('all');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [excludedSet, setExcludedSet] = useState<SetString>(new Set());
  const [includedSet, setIncludedSet] = useState<SetString>(new Set());

  const allClinicSelected = (tab: tabs) => {
    if (tab === 'excluded') return excludedSet.size === clinics.length;
    return includedSet.size === clinics.length;
  };

  const isAllTab = activeTab === 'all';

  const isChecked = (clinic: Clinic) =>
    activeTab === 'included' || activeTab === 'all'
      ? includedSet.has(clinic.guid)
      : excludedSet.has(clinic.guid);

  const findParentClinics = (from: Clinic[]) => {
    return from.flatMap((el) =>
      el.parentClinicGuid
        ? clinics.filter((parent) => parent.guid === el.parentClinicGuid)
        : [],
    );
  };

  const filterClinics = (list: SetString) =>
    clinics.filter((clinic) => list.has(clinic.guid));

  const searchInList = (list: Clinic[], searchTerm?: string) => {
    if (!searchTerm) return list;
    const terms = searchTerm.trim().toLowerCase();
    return list.filter((clinic) =>
      clinic.legalname.toLowerCase().includes(terms),
    );
  };

  const groupedClinics = useMemo(() => {
    let clinicsToSort: Clinic[];

    if (activeTab === 'included') {
      clinicsToSort = filterClinics(includedSet);
    } else if (activeTab === 'excluded') {
      clinicsToSort = filterClinics(excludedSet);
    } else {
      clinicsToSort = clinics;
    }

    clinicsToSort = searchInList(clinicsToSort, searchQuery);
    const parentClinics = findParentClinics(clinicsToSort);

    return Clinic.sortByParent([...parentClinics, ...clinicsToSort], sortOrder);
  }, [
    activeTab,
    sortOrder,
    searchQuery,
    clinics.length,
    includedSet.size,
    excludedSet.size,
  ]);

  const showEmptyState = !loading && groupedClinics.length === 0;

  const tabs = useMemo<IBaseTab<tabs>[]>(
    () => [
      {
        label: `${t('labels.all')} (${clinics.length})`,
        key: 'all',
      },
      {
        label: `${t('labels.included')} (${includedSet.size})`,
        key: 'included',
      },
      {
        label: `${t('labels.excluded')} (${excludedSet.size})`,
        key: 'excluded',
      },
    ],
    [includedSet.size, excludedSet.size, clinics.length],
  );

  const setBothLists = (inc: SetString, exc: SetString) => {
    setIncludedSet(new Set(inc));
    setExcludedSet(new Set(exc));
    onChange({
      included: Array.from(inc),
      excluded: Array.from(exc),
      allExcluded: exc.size === clinics.length,
    });
  };

  const updateClinics = useCallback(
    (list: tabs | 'all', clinic: Clinic, add: boolean) => {
      const updatedLists = {
        included: includedSet,
        excluded: excludedSet,
      };

      const clinicGuidWithChild = [
        clinic.guid,
        ...clinic.childClinicsGuid.map(({ guid }) => guid),
      ];

      const allowClinicGuids = clinics.map((clinic) => clinic.guid);

      if (list === 'excluded' || (list === 'all' && add)) {
        clinicGuidWithChild.forEach((guid) => {
          if (allowClinicGuids.includes(guid)) {
            updatedLists.included.add(guid);
            updatedLists.excluded.delete(guid);
          }
        });
      } else if (list === 'included' || (list === 'all' && !add)) {
        clinicGuidWithChild.forEach((guid) => {
          if (allowClinicGuids.includes(guid)) {
            updatedLists.excluded.add(guid);
            updatedLists.included.delete(guid);
          }
        });
      }

      setBothLists(updatedLists.included, updatedLists.excluded);
    },
    [includedSet.size, excludedSet.size, clinics.length, onChange],
  );

  const onSelect = useCallback(
    (checked: boolean, clinic: Clinic) => {
      updateClinics(activeTab, clinic, checked);
    },
    [activeTab, updateClinics],
  );

  const selectAll = useCallback(
    (select: boolean, tab: tabs) => {
      const updateState = (inc: SetString, exc: SetString) => {
        setBothLists(inc, exc);
      };
      const emptySet = new Set([]);

      const tabActions = {
        excluded: () => updateState(allClinicGuids, emptySet),
        included: () => updateState(emptySet, allClinicGuids),
        all: () =>
          select
            ? updateState(allClinicGuids, emptySet)
            : updateState(emptySet, allClinicGuids),
      };
      if (tabActions[tab]) tabActions[tab]();
    },
    [clinics, onChange, setBothLists],
  );

  useEffect(() => {
    loadClinics(meta);
  }, []);

  useEffect(() => {
    const allGuids = Array.from(allClinicGuids);

    let included: SetString;
    let excluded: SetString;

    if (allExcluded) {
      included = new Set();
      excluded = allClinicGuids;
    } else if (excludedParam.length) {
      included = new Set(
        allGuids.filter((guid) => !excludedParam.includes(guid)),
      );
      excluded = new Set(excludedParam);
    } else if (includedParam.length) {
      included = new Set(includedParam);
      excluded = new Set(
        allGuids.filter((guid) => !includedParam.includes(guid)),
      );
    } else {
      const isAdminDashboard = showOnInteractiveTimeDashboard === false;

      included = isAdminDashboard ? new Set() : allClinicGuids;
      excluded = isAdminDashboard ? allClinicGuids : new Set();
    }

    setBothLists(included, excluded);
  }, [
    allClinicGuids.size,
    excludedParam.length,
    includedParam.length,
    allExcluded,
  ]);

  return (
    <BaseSpinWrapper spinning={loading}>
      <div className={s.form}>
        <div className={s.header}>
          <div className={s.block} data-cy="searchClinic-input">
            <BaseInput
              placeholder={t('labels.searchClinic')}
              onChange={(e) => setSearchQuery(e)}
              allowClear
              addonAfter={<SearchIcon />}
            />
          </div>
          <div className={s.block} data-cy="searchClinic-order">
            <SortOrderSelectBox value={sortOrder} onChange={setSortOrder} />
          </div>
          <div className={s.block} data-cy="searchClinic-tabs">
            <BaseTabs
              tabs={tabs}
              type="rounded"
              size="small"
              onChange={(tab) => setActiveTab(tab.key)}
            />
          </div>
        </div>

        <div>
          <div className={s['list-header']}>
            <p>{t('labels.clinicsList')}</p>
            <BaseButton
              type="secondary"
              dataCy="clinicList-select-deselect-all-btn"
              onClick={() =>
                selectAll(!allClinicSelected(activeTab), activeTab)
              }
              label={
                allClinicSelected(activeTab) || !isAllTab
                  ? t('controls.deselectAll')
                  : t('controls.selectAll')
              }
            />
          </div>
        </div>
        <ul className={s.list}>
          {showEmptyState && <EmptyTable />}
          <List
            data={groupedClinics}
            height={showEmptyState ? 127 : 390}
            itemHeight={51}
            itemKey="guid"
          >
            {(clinic: Clinic) => (
              <>
                <ClinicFilterFormListItem
                  name={clinic.legalname}
                  showCheckBox={isAllTab || isChecked(clinic)}
                  checked={isChecked(clinic)}
                  isParent={!clinic.parentClinicGuid}
                  isLast={!clinic.childClinicsGuid.length}
                  withoutParent={clinic.withoutParent}
                  onChange={(checked) => onSelect(checked, clinic)}
                />
              </>
            )}
          </List>
        </ul>
      </div>
    </BaseSpinWrapper>
  );
};

export default ClinicFilterForm;
