export const API_ELIGIBILITY_PROCESS_CREATE =
  'reports/eligibility-process/create';
export const API_ELIGIBILITY_PROCESS_LIST =
  'reports/eligibility-process/process-list';
export const API_ELIGIBILITY_PROCESS_ITEMS_LIST =
  'reports/eligibility-process/process-item-list';
export const API_ELIGIBILITY_PROCESS_DOWNLOAD_CSV =
  'reports/eligibility-process/download-csv';
export const API_BILLING_CLAIM_EPIC_PROCESS_ITEMS_LIST =
  'reports/billing-tools-epic/list';
export const API_BILLING_CLAIM_EPIC_PROCESS_CREATE =
  'reports/billing-tools-epic/create';
export const EPIC_EMR_NAMES_LIST = 'reports/epic-emr-name/list';
export const API_ELIGIBILITY_PROCESS_UPDATE_CREDENTIALS =
  'reports/eligibility-process/update-credentials';
