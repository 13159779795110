import BaseRangeDatepicker from 'components/atoms/base-range-datepicker';
import BaseSelectBox from 'components/atoms/base-select-box';
import BaseSearchSelect from 'components/atoms/base-search-select';
import forms from 'constants/forms';
import { usStatesSelectWithAll } from 'constants/states';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import useUser from 'utils/useUser';

import s from './styles.module.scss';
import ItemWrapper from '../item-wrapper';

import type { TDashboardFilter } from '../../hooks/use-dashboard-filter';
import type { FC } from 'react';

interface BodyProps
  extends Omit<
    TDashboardFilter,
    'onResetFilter' | 'countOfChangedFilterField' | 'refetchInsuranceType'
  > {}

const DEFAULT_INSURANCE_TYPE = forms.clinicInsuranceTypeSelect[0];

const Body: FC<BodyProps> = (props) => {
  const {
    searchClinicsLoading,
    searchDoctorsLoading,
    searchPatientsLoading,
    debounceSearchClinics,
    dateValues,
    clinics,
    doctors,
    patients,
    debounceSearchPatients,
    values,
    insuranceTypes,
    onDateChange,
    handleChangeValue,
    handleClearValue,
    onSelectProvider,
    onDeselectProvider,
    onChangeProvider,
  } = props;
  const { t } = useTranslation();
  const { isPractice, isAtRiskRole } = useUser();

  return (
    <div>
      <div className={s.row}>
        <ItemWrapper label={t('labels.patientFilter')}>
          <BaseSearchSelect
            isLoading={searchPatientsLoading}
            placeholder={t('labels.searchPatient')}
            onChange={debounceSearchPatients}
            options={patients?.items}
            onSelect={(v) => handleChangeValue('patientGuid', v)}
            value={values.patientGuid}
            onClear={() => handleClearValue('patientGuid')}
          />
        </ItemWrapper>
        <div className={s['combine-cell']}>
          <ItemWrapper label={t('labels.readingType')}>
            <BaseSelectBox
              options={forms.readingTypeSelect}
              defaultValue={null}
              value={values.readingType}
              onChange={(v) => handleChangeValue('readingType', v)}
              allowClear
            />
          </ItemWrapper>
          {isAtRiskRole && (
            <ItemWrapper label={t('labels.time')}>
              <BaseSelectBox
                options={forms.actionTimesQuotaSelect}
                defaultValue={null}
                value={values.actionTimesQuota}
                onChange={(v) => handleChangeValue('actionTimesQuota', v)}
                allowClear
              />
            </ItemWrapper>
          )}
        </div>
        <div className={s['combine-cell']}>
          {!isPractice && (
            <ItemWrapper label={t('labels.assignee')}>
              <BaseSelectBox
                options={forms.assigneeSelect}
                defaultValue={null}
                value={values.assignee}
                onChange={(v) => handleChangeValue('assignee', v)}
                allowClear
              />
            </ItemWrapper>
          )}
          <ItemWrapper label={t('labels.activitiesType')}>
            <BaseSelectBox
              options={forms.activitiesType}
              value={values.reviewed}
              onChange={(v) => handleChangeValue('reviewed', v)}
              allowClear
            />
          </ItemWrapper>
        </div>
        <ItemWrapper label={t('labels.clinicState')}>
          <BaseSelectBox
            options={usStatesSelectWithAll}
            defaultValue={null}
            value={values.clinicState}
            onChange={(v) => handleChangeValue('clinicState', v)}
            allowClear
          />
        </ItemWrapper>
      </div>
      <div className={s.row}>
        <ItemWrapper label={t('labels.dateRange')}>
          <BaseRangeDatepicker
            onCalendarChange={onDateChange}
            value={dateValues}
            withPresets
            disabledDate={(current) => {
              return current && current > new Date();
            }}
            isMaxDayToday
          />
        </ItemWrapper>
        <ItemWrapper label={t('labels.provider')}>
          <BaseSearchSelect
            isLoading={searchDoctorsLoading}
            placeholder="Search"
            mode="multiple"
            onChange={onChangeProvider}
            options={doctors}
            onSelect={onSelectProvider}
            value={values.doctorGuid}
            onClear={() => handleClearValue('doctorGuid')}
            onDeselect={onDeselectProvider}
          />
        </ItemWrapper>
        <ItemWrapper label={t('labels.clinic')}>
          <BaseSearchSelect
            isLoading={searchClinicsLoading}
            placeholder="Search"
            options={clinics}
            onChange={debounceSearchClinics}
            onSelect={(v) => handleChangeValue('clinicGuid', v)}
            value={values.clinicGuid}
            onClear={() => handleClearValue('clinicGuid')}
          />
        </ItemWrapper>
        <ItemWrapper label={t('labels.insuranceProvider')}>
          <Tooltip
            placement="top"
            trigger="hover"
            title={
              !values.clinicGuid && t('messages.disableInsuranceProviderFilter')
            }
          >
            <div>
              <BaseSelectBox
                options={insuranceTypes}
                defaultValue={DEFAULT_INSURANCE_TYPE}
                value={values.insuranceType}
                onChange={(v) => handleChangeValue('insuranceType', v)}
                disabled={!values.clinicGuid}
                allowClear
              />
            </div>
          </Tooltip>
        </ItemWrapper>
      </div>
    </div>
  );
};
export default Body;
