import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';
import { phoneNumberRegExp } from 'infrastructure/regexp';

export const agencyContactInformationValidationSchema = () =>
  createValidationSchema({
    legalname: commonValidationSchemas.string({ required: true }),
    email: commonValidationSchemas.email({ nullable: true }),
    fax: commonValidationSchemas.string({ nullable: true }),
    phone: commonValidationSchemas.string({
      nullable: true,
      matches: {
        regexp: phoneNumberRegExp,
      },
    }),
  });
