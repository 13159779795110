import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BaseTableReport from 'components/atoms/base-table/report/report';
import { useAdminAgenciesList } from 'infrastructure/hooks/agency';

import { clinicColumns } from './cols';

import type { IClinicListFilters } from 'infrastructure/interfaces';

interface IProps {
  filters: IClinicListFilters;
  updateFilter: (newFilter: Partial<IClinicListFilters>) => void;
}

const AgenciesTable: React.FC<IProps> = ({ filters, updateFilter }) => {
  const { t } = useTranslation();
  const { loading, agencies, meta } = useAdminAgenciesList({
    meta: filters,
  });

  const columns = useMemo(
    () => clinicColumns({ page: filters.page }),
    [filters.page],
  );

  const onChangePage = (page: number) => {
    updateFilter({ page });
  };

  return (
    <BaseTableReport
      rowKey="guid"
      headerTitle={t('labels.agencies')}
      dataCy="agencies-table"
      columns={columns}
      dataSource={agencies}
      isLoading={loading}
      pagination={{
        current: filters.page,
        pageSize: meta.items,
        total: meta.totalCount,
        onChange: onChangePage,
      }}
      showCount
      anchor
    />
  );
};

export default AgenciesTable;
