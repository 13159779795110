import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import AntThemeProvider from 'infrastructure/antd/theme-provider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { router } from './router';
import reportWebVitals from './reportWebVitals';
import store from './store';
import './infrastructure/i18n/index';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'quill/dist/quill.snow.css';
import 'react-vis/dist/style.css';
import './styles/index.scss';
import './assets/styles/global.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <AntThemeProvider>
        <RouterProvider
          router={router}
          future={{
            v7_startTransition: true,
          }}
        />
      </AntThemeProvider>
    </QueryClientProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
