import { isFirstDayOfMonth, isThisMonth, isToday } from 'date-fns';

import type { TActivityReportFilter } from '../use-get-activity-report';

export const calculateChangedFields = (
  values: TActivityReportFilter,
): number => {
  const exclude = [
    'applySpecialFilter',
    'page',
    'endTime',
    'startTime',
    'reverse',
    'slaReadings',
    'name',
  ];
  const countOfChangedFilterField = Object.entries(values).filter(
    ([k, v]) => !exclude.includes(k) && Boolean(v),
  ).length;

  const startChanged =
    typeof values.startTime === 'number' &&
    !(isThisMonth(values.startTime) && isFirstDayOfMonth(values.startTime));

  const endChanged =
    typeof values.endTime === 'number' && !isToday(values.endTime);

  const dateChanged = startChanged || endChanged ? 1 : 0;

  const totalCount = countOfChangedFilterField + dateChanged;

  return totalCount;
};
