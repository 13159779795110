import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PatientBlock from 'components/molecules/patientBlock';
import { usePatientThresholds } from 'infrastructure/hooks/patient/use-patient-thresholds';
import { ReadingType } from 'infrastructure/enums';
import { usePatientDevices } from 'infrastructure/hooks/device/use-patient-devices';
import { PatientDevice } from 'infrastructure/classes/patient/patient-device';
import ThresholdsViewForm from 'components/molecules/thresholds/view-form';
import BaseModal from 'components/atoms/base-modal';
import ThresholdsForm from 'components/molecules/thresholds/update-form';
import { PatientThresholds } from 'infrastructure/classes/patient/patient-thresholds';
import { isAdminModule } from 'infrastructure/functions';
import useUser from 'utils/useUser';

import s from './styles.module.scss';

import type { ModuleType } from 'infrastructure/enums';
import type { Thresholds } from 'infrastructure/classes/thresholds';

interface IAdminPanelPatientThresholdsFormProps {
  patientGuid: string;
  module: ModuleType;
}

const AdminPanelPatientThresholdsForm: React.FC<
  IAdminPanelPatientThresholdsFormProps
> = (props) => {
  const { patientGuid, module } = props;

  const isAdmin = isAdminModule(module);

  const { t } = useTranslation();

  const [isOpened, setIsOpened] = useState(false);
  const { loading: devicesLoading, devices } = usePatientDevices({
    patientGuid,
  });
  const { thresholds, loading, updatePatientThresholds } = usePatientThresholds(
    {
      patientGuid,
    },
  );
  const { iamPatientAcceptance } = useUser();

  const hasBloodGlucoseType =
    PatientDevice.checkReadingType(devices, [ReadingType.BloodGlucose]) ||
    isAdmin;
  const hasWeightType =
    PatientDevice.checkReadingType(devices, [ReadingType.Weight]) || isAdmin;
  const hasSPO2Type =
    PatientDevice.checkReadingType(devices, [ReadingType.SpO2]) || isAdmin;
  const hasBloodPressureType =
    PatientDevice.checkReadingType(devices, [ReadingType.BloodPressure]) ||
    isAdmin;
  const hasPeakFlowType =
    PatientDevice.checkReadingType(devices, [ReadingType.PeakFlow]) || isAdmin;
  const hasSleepMatType =
    PatientDevice.checkReadingType(devices, [ReadingType.SleepingMat]) ||
    isAdmin;

  const onEdit = () => {
    setIsOpened(true);
  };

  const onCancel = () => {
    setIsOpened(false);
  };

  const onSubmit = async (data: Thresholds) => {
    setIsOpened(false);
    await updatePatientThresholds(
      new PatientThresholds({
        patientGuid,
        ...data,
      }),
    );
  };

  const showThresholds =
    hasBloodGlucoseType ||
    hasWeightType ||
    hasSPO2Type ||
    hasBloodPressureType ||
    hasPeakFlowType ||
    hasSleepMatType;

  return (
    <div>
      {showThresholds && (
        <>
          <PatientBlock
            title={t('labels.patientThresholds')}
            onEdit={onEdit}
            loading={loading || devicesLoading}
            disabled={iamPatientAcceptance}
          >
            <div className={s.wrapper}>
              <ThresholdsViewForm
                data={thresholds}
                hasBloodGlucoseType={hasBloodGlucoseType}
                hasWeightType={hasWeightType}
                hasSPO2Type={hasSPO2Type}
                hasBloodPressureType={hasBloodPressureType}
                hasPeakFlowType={hasPeakFlowType}
                hasSleepMatType={hasSleepMatType}
              />
            </div>
          </PatientBlock>
          <BaseModal
            title={t('labels.patientThresholds')}
            open={isOpened}
            onCancel={onCancel}
            width="1000px"
          >
            <ThresholdsForm
              data={thresholds}
              onSubmit={onSubmit}
              onCancel={onCancel}
              hasBloodGlucoseType={hasBloodGlucoseType}
              hasWeightType={hasWeightType}
              hasSPO2Type={hasSPO2Type}
              hasBloodPressureType={hasBloodPressureType}
              hasPeakFlowType={hasPeakFlowType}
              hasSleepMatType={hasSleepMatType}
            />
          </BaseModal>
        </>
      )}
    </div>
  );
};

export default AdminPanelPatientThresholdsForm;
