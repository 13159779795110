import { useQuery } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';
import { arrUniqEl } from 'infrastructure/functions';
import { AgencyInfo } from 'infrastructure/classes/agency/agency-info';
import { HttpStatus } from 'infrastructure/enums';

import useApi from '../use-api';

import type {
  IPaginationMeta,
  IPartialPaginationMeta,
  IAdminPanelSearchAgencyResponse,
  IAgencyListFilters,
} from 'infrastructure/interfaces';

const getAgenciesApi = 'admins/admin-panel/agencies';

const prepareFilters = (filters?: IAgencyListFilters) => {
  if (!filters) return {};
  const copiedFilters = structuredClone(filters);
  return copiedFilters;
};

type MergedMeta = IPaginationMeta<IAgencyListFilters>;

type UseAdminAgencies = {
  loading: boolean;
  agencies: AgencyInfo[];
  meta: MergedMeta;
};

interface IUseAdminAgenciesListProps {
  meta?: IAgencyListFilters & IPartialPaginationMeta;
}

export const useAdminAgenciesList = (
  props?: IUseAdminAgenciesListProps,
): UseAdminAgencies => {
  const { items = 100, page = 1 } = props?.meta ?? {};
  const { loadData } = useApi({ showError: false });

  const preparedFilter = prepareFilters(props?.meta);
  const queryKey = [getAgenciesApi, preparedFilter];

  const { data, isLoading } = useQuery({
    queryKey,
    staleTime: STALE_TIME,
    queryFn: () =>
      loadData<
        IAdminPanelSearchAgencyResponse,
        Omit<IPaginationMeta, 'sortField' | 'totalCount'>
      >(getAgenciesApi, {
        sortField: JSON.stringify(props?.meta?.sortField),
        items,
        page,
        ...preparedFilter,
      })
        .then((res) => {
          if (res) {
            return {
              count: res.count,
              items: arrUniqEl(res.items, 'guid').map(
                (el) => new AgencyInfo(el),
              ),
            };
          }
        })
        .catch((err) => {
          if (err.code === HttpStatus.NOT_FOUND) {
            return {
              count: 0,
              items: [],
            };
          }
          return err;
        }),
  });

  const meta = {
    totalCount: data?.count ?? 0,
    page,
    items,
    ...props?.meta,
  };

  return {
    meta,
    agencies: data ? data.items : [],
    loading: isLoading,
  };
};
