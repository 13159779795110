/* eslint-disable no-console */

let loggingInstance = null;

class Logger {
  constructor() {
    if (loggingInstance) {
      throw new Error('Logging instance is already created!');
    }
    loggingInstance = this;
  }

  _parseMessage(message, ...options) {
    return typeof message === 'string'
      ? `ERROR: ${message} ${JSON.stringify(options)}`
      : message;
  }

  info(message, ...options) {
    if (options && options.length) {
      console.info('INFO:', message, options);
    } else {
      console.info('INFO:', message);
    }
  }

  warn(message, ...options) {
    if (options && options.length) {
      console.warn('WARN:', message, options);
    } else {
      console.warn('WARN:', message);
    }
  }

  error(message, ...options) {
    if (options && options.length) {
      console.error('ERROR:', message, options);
    } else {
      console.error('ERROR:', message);
    }
  }
}

const logger = Object.freeze(new Logger());
export default logger;
