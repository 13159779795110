import classNames from 'classnames';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';
import { useTranslation } from 'react-i18next';
import { type FC } from 'react';
import { generateFullName } from 'utils/badgeHelpers';

import s from './styles.module.scss';

import type { IActivityComments } from 'infrastructure/interfaces/i-rpm-patient-activity';

interface MessageItemProps {
  isMockComment: boolean;
  comment: IActivityComments;
}

const MessageItem: FC<MessageItemProps> = ({ isMockComment, comment }) => {
  const { t } = useTranslation();

  return (
    <li
      data-cy="comment"
      className={classNames(s['comment-item'], {
        [s.mock]: isMockComment,
      })}
      key={comment.guid}
      data-new-action={comment?.newAction}
    >
      <p data-cy="comment-text" className={s['comment-message']}>
        {comment.message}
      </p>
      <div data-cy="signed-by" className={s['comment-signed-by']}>
        <p>
          <span>{t('labels.electronicallySignedBy')}: </span>
          <span>
            {generateFullName(
              comment.createdByTitle,
              comment.createdByFirstName,
              '',
              comment.createdByLastName,
              comment.createdByProfessionType,
            )}
          </span>
        </p>
        <span>
          {DateFormatter({
            date: comment.createdAt,
            format: DateFormats['MM-dd-yyyy | h:mm a'],
          })}
        </span>
      </div>
    </li>
  );
};
export default MessageItem;
