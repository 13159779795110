import {
  isTurkUserRole,
  isSmsDashboardOrUnregisteredRole,
  isRoleNameAdminTools,
  isRoleNameBillingTools,
} from 'utils/userTypeHelper';
import { accessToMainPageRoles } from 'constants/roleTypes';

import paths from '../../constants/paths';
import {
  ADMIN_PANEL_AGENCIES_ROUTES,
  ADMIN_PANEL_AGENCY_DOCTORS_ROUTES,
  ADMIN_PANEL_CLINICS_ROUTES,
  ADMIN_PANEL_USERS_ROUTES,
  ADMIN_PANEL_PATIENTS_ROUTES,
  PATIENT_ACCEPTANCE_ROUTES,
  CCM_ROUTES,
  RPM_ROUTES,
  RPM_ADD_ROUTES,
  RPM_PRACTICE_REPORT_ROUTES,
  TURK_ADMIN_ROUTES,
  TURK_USER_ROUTES,
  SMS_DASHBOARD_ROUTES,
  CCM_ROUTES_ADMIN_HHA,
  ADMIN_TOOLS_ROUTES,
  BILLING_TOOLS_ROUTES,
} from './routes';
import {
  ADMINISTRATOR_ROLE_NAME,
  ADMIN_USER_TYPE,
  PRACTICE_USER_TYPE,
  PATIENT_ACCEPTANCE_ROLE,
  HHA_USER_TYPE,
  PATIENT_USER_TYPE,
  CCM_PREFIX,
  PLATFORM_MODE_CCM,
  PLATFORM_MODE_RPM,
  PLATFORM_MODE_LOCALSTORAGE_KEY,
  PLATFORM_MODE_PATIENT_ACCEPTANCE,
  PLATFORM_MODE_ADMIN_PANEL_ACCESS,
  CRITICAL_ALERTING,
  AT_RISK_ALERTING,
  CLINICAL_ROLE_NAME,
  BILLING_TOOLS_NAME,
} from './constants';

export const hasClinicalRole = (roleNames) =>
  roleNames &&
  roleNames.some((roleName) => roleName.includes(CLINICAL_ROLE_NAME));

const hasCCMRole = (roleNames) =>
  roleNames && roleNames.some((roleName) => roleName.includes(CCM_PREFIX));
export const hasAccessToMainPage = (roleNames) =>
  roleNames.some((role) => accessToMainPageRoles.includes(role));

export const hasPatientAcceptanceRole = (roleNames) =>
  roleNames &&
  roleNames.some((roleName) => roleName.includes(PATIENT_ACCEPTANCE_ROLE));

const hasRPMRole = (roleNames) =>
  roleNames &&
  roleNames.some(
    (roleName) =>
      !roleName.includes(CCM_PREFIX) &&
      !roleName.includes(ADMINISTRATOR_ROLE_NAME) &&
      !roleName.includes(CRITICAL_ALERTING) &&
      !roleName.includes(AT_RISK_ALERTING),
  );

export const isCriticalOrAtRiskAlertingRole = (roleNames) =>
  roleNames &&
  roleNames.every(
    (roleName) =>
      roleName === CRITICAL_ALERTING || roleName === AT_RISK_ALERTING,
  );

export const isCriticalRole = (roleNames) =>
  roleNames && roleNames.some((roleName) => roleName === CRITICAL_ALERTING);

export const isAtRiskRole = (roleNames) =>
  roleNames && roleNames.some((roleName) => roleName === AT_RISK_ALERTING);

export const hasAdminPanelAccess = (roleNames) =>
  roleNames.includes(ADMINISTRATOR_ROLE_NAME);

export const hasBillingToolsAccess = (roleNames) =>
  roleNames.includes(BILLING_TOOLS_NAME);

export const getUserRoutes = (user) => {
  const { userType, roleNames, platformMode } = user;
  const isCCM = platformMode === PLATFORM_MODE_CCM;
  const isRPM = platformMode === PLATFORM_MODE_RPM;
  const isPracticeUser = userType === PRACTICE_USER_TYPE;
  const isAdminUser = userType === ADMIN_USER_TYPE;
  const isHhaUser = userType === HHA_USER_TYPE;
  const routes = [paths.sso, paths.dashboard];

  // ADMIN USER
  if (isAdminUser) {
    routes.push(...ADMIN_PANEL_PATIENTS_ROUTES);
    routes.push(...ADMIN_PANEL_CLINICS_ROUTES);
    routes.push(...ADMIN_PANEL_USERS_ROUTES);
    routes.push(...ADMIN_PANEL_AGENCIES_ROUTES);
    routes.push(...ADMIN_PANEL_AGENCY_DOCTORS_ROUTES);
    routes.push(...RPM_PRACTICE_REPORT_ROUTES);
    routes.push(...RPM_ROUTES);
    routes.push(...RPM_ADD_ROUTES);
    routes.push(...CCM_ROUTES);
    routes.push(...TURK_ADMIN_ROUTES);
    routes.push(...SMS_DASHBOARD_ROUTES);
    routes.push(...ADMIN_TOOLS_ROUTES);
    routes.push(...BILLING_TOOLS_ROUTES);

    if (isCCM) {
      routes.push(...CCM_ROUTES_ADMIN_HHA);
    }

    const adminRoutes = [...new Set(routes)];

    return adminRoutes;
  }

  if (hasAdminPanelAccess(roleNames)) {
    routes.push(...ADMIN_PANEL_PATIENTS_ROUTES);
    routes.push(...ADMIN_PANEL_CLINICS_ROUTES);
    routes.push(...ADMIN_PANEL_USERS_ROUTES);
  }

  if (isRoleNameAdminTools(roleNames)) {
    routes.push(...ADMIN_TOOLS_ROUTES);
  }

  if (isRoleNameBillingTools(roleNames)) {
    routes.push(...BILLING_TOOLS_ROUTES);
  }

  if (isSmsDashboardOrUnregisteredRole(roleNames)) {
    routes.push(...SMS_DASHBOARD_ROUTES);
  }

  if (roleNames.length === 1 && hasPatientAcceptanceRole(roleNames)) {
    routes.push(...PATIENT_ACCEPTANCE_ROUTES);
    return routes;
  }

  // PRACTICE USER
  if (isPracticeUser) {
    if (isRPM) routes.push(...RPM_ROUTES);
    else if (isCCM) routes.push(...CCM_ROUTES);

    // HHA USER
  } else if (isHhaUser) {
    if (hasCCMRole(roleNames) && isCCM) {
      routes.push(...CCM_ROUTES);
    }

    if (hasRPMRole(roleNames) && isRPM) {
      routes.push(...RPM_ROUTES);
      routes.push(...RPM_ADD_ROUTES);
    }

    if (hasAdminPanelAccess(roleNames)) {
      routes.push(...ADMIN_PANEL_AGENCIES_ROUTES);
      routes.push(...ADMIN_PANEL_AGENCY_DOCTORS_ROUTES);
      routes.push(...RPM_PRACTICE_REPORT_ROUTES);
    }
    if (isTurkUserRole(roleNames)) {
      routes.push(...TURK_USER_ROUTES);
    }
    if (isCCM) {
      routes.push(...CCM_ROUTES_ADMIN_HHA);
    }

    // PATIENT USER
  } else if (userType === PATIENT_USER_TYPE) {
    routes.push(paths.profile);
    routes.push(`/patient/${user?.guid}`);
  }

  if (hasPatientAcceptanceRole(roleNames)) {
    routes.push(...PATIENT_ACCEPTANCE_ROUTES);
  }

  return routes;
};

export const getUserPlatformMode = (user) => {
  const { userType, roleNames } = user;

  if (hasPatientAcceptanceRole(roleNames) && !hasAccessToMainPage(roleNames)) {
    return PLATFORM_MODE_PATIENT_ACCEPTANCE;
  }

  if (userType === HHA_USER_TYPE) {
    if (hasCCMRole(roleNames) && !hasRPMRole(roleNames)) {
      return PLATFORM_MODE_CCM;
    }
    if (hasAdminPanelAccess(roleNames) && !hasAccessToMainPage(roleNames)) {
      return PLATFORM_MODE_ADMIN_PANEL_ACCESS;
    }
  }

  if (
    userType === PRACTICE_USER_TYPE &&
    hasAdminPanelAccess(roleNames) &&
    !hasAccessToMainPage(roleNames)
  ) {
    return PLATFORM_MODE_ADMIN_PANEL_ACCESS;
  }

  if (
    userType === PRACTICE_USER_TYPE &&
    roleNames.length === 1 &&
    hasAdminPanelAccess(roleNames)
  )
    return PLATFORM_MODE_ADMIN_PANEL_ACCESS;

  const userPlatformMode = localStorage.getItem(PLATFORM_MODE_LOCALSTORAGE_KEY);
  if (userPlatformMode) return userPlatformMode;
  localStorage.setItem(PLATFORM_MODE_LOCALSTORAGE_KEY, PLATFORM_MODE_RPM);
  return PLATFORM_MODE_RPM;
};

export const toggleUserPlatformMode = (user) => {
  const { platformMode } = user;

  let newMode = PLATFORM_MODE_RPM;
  if (platformMode === PLATFORM_MODE_RPM) {
    newMode = PLATFORM_MODE_CCM;
  }
  localStorage.setItem(PLATFORM_MODE_LOCALSTORAGE_KEY, newMode);

  return newMode;
};
