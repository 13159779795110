import s from './style.module.scss';
import Title from './title';
import EpicClaimUpload from './upload';

import type { FC } from 'react';
import type { EpicEmrNames } from 'infrastructure/hooks/billingTools/epic/types';

interface Props {
  onUpload: () => any;
  emrNameList: EpicEmrNames[] | undefined;
  isEmrNamesLoading: boolean;
}

const EpicClaimsHeader: FC<Props> = ({
  onUpload,
  isEmrNamesLoading,
  emrNameList,
}) => {
  return (
    <div className={s.wrapper}>
      <Title />
      <EpicClaimUpload
        onUpload={onUpload}
        isEmrNamesLoading={isEmrNamesLoading}
        emrNameList={emrNameList}
      />
    </div>
  );
};

export default EpicClaimsHeader;
