import i18n from 'infrastructure/i18n';

import s from './styles.module.scss';
import Sla from './sla';
import PatientInfo from './patient-info';
import CcmStatus from './ccm-status';
import RpmStatus from './rpm-status';

import type { TableProps } from 'antd';
import type { IAdminPanelPatient } from 'infrastructure/interfaces';

export const adminPanelPatientsColumns: TableProps<IAdminPanelPatient>['columns'] =
  [
    {
      title: '№',
      key: 'guid',
      width: '50px',
      align: 'center',
      render: (_, record, idx) => (
        <span data-cy="patientTable-number" className={s.number}>
          {idx + 1}
        </span>
      ),
    },
    {
      title: i18n.t('labels.sla'),
      key: 'clinicServiceDeliveryThresholds',
      width: '20px',
      align: 'center',
      render: (_, record) => (
        <Sla value={record.clinicServiceDeliveryThresholds as any} />
      ),
    },
    {
      title: i18n.t('labels.patientName'),
      key: 'firstName',
      render: (_, record) => <PatientInfo record={record} />,
    },
    {
      title: i18n.t('labels.rpmStatus'),
      key: 'status',
      width: '270px',
      render: (_, record) => <RpmStatus record={record} />,
    },
    {
      title: i18n.t('labels.ccmStatus'),
      key: 'ccmStatus',
      width: '430px',
      render: (_, record) => <CcmStatus record={record} />,
    },
    {
      title: i18n.t('labels.clinic'),
      key: 'clinic',
      width: '185px',
      render: (_, record) => (
        <p data-cy="patientTable-clinic" className={s.clinic}>
          {record.clinic}
        </p>
      ),
    },
  ];
