import React from 'react';

import type { IBaseComponentProps } from 'infrastructure/interfaces';

const LockIcon: React.FC<IBaseComponentProps> = ({ className }) => (
  <svg
    className={className}
    width="48"
    height="49"
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 5.5C27.3137 5.5 30 7.73858 30 10.5V18H18V10.5C18 7.73858 20.6863 5.5 24 5.5ZM33 18V10.5C33 6.35786 28.9706 3 24 3C19.0294 3 15 6.35786 15 10.5V18C11.6863 18 9 20.2386 9 23V38C9 40.7614 11.6863 43 15 43H33C36.3137 43 39 40.7614 39 38V23C39 20.2386 36.3137 18 33 18ZM15 20.5H33C34.6569 20.5 36 21.6193 36 23V38C36 39.3807 34.6569 40.5 33 40.5H15C13.3431 40.5 12 39.3807 12 38V23C12 21.6193 13.3431 20.5 15 20.5Z"
      fill="#CCCAD7"
      stroke="#CCCAD7"
      strokeWidth="2"
    />
  </svg>
);

export default LockIcon;
