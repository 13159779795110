import { useState } from 'react';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import PlusIcon from 'components/atoms/icons/plusIcon';
import BillingFileUploaderModal from 'components/organisms/adminHeader/billingFileUploaderModal';

import BatchReportModal from './batchReportModal';
import SopReportModal from './sopReportModal';
import ResendEmrModal from './resendEmrModal';
import ResetInteractionStatus from './resetInteractionStatus';
import CsvButtonIcon from '../../atoms/icons/csvButtonIcon';
import { useAdminDropdownButtons } from '../admin-panel/navigation-header/hook';
import HidePatientDataModal from './hidePatientDataModal';

const CsvDropdown = () => {
  const {
    vitalCsvUploadOpen,
    setVitalCsvUploadOpen,
    sopCsvUploadOpen,
    setSopCsvUploadOpen,
    resendEmrOpen,
    setResendEmrOpen,
    resetInteractionStatusOpen,
    setResetInteractionStatusOpen,
    isShowCsv,
    csvButtons,
    hidePatientDataOpen,
    setHidePatientDataOpen,
    billingFileUploaderOpen,
    setBillingFileUploaderOpen,
  } = useAdminDropdownButtons();
  useState(false);

  if (!isShowCsv) return null;

  return (
    <>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle
          nav
          data-cy="add__toggle"
          className="navbar-dropdown-profile"
        >
          <CsvButtonIcon />
        </DropdownToggle>
        <DropdownMenu right className="navbar-dropdown profile-menu">
          {csvButtons.map((button) => {
            return (
              <DropdownItem
                key={button.key}
                data-cy={button.dataCy}
                className="navbar-dropdown-item"
                onClick={button.handleClick}
              >
                {button.label} <PlusIcon />
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </UncontrolledDropdown>
      <BatchReportModal
        isOpen={vitalCsvUploadOpen}
        toggle={setVitalCsvUploadOpen}
      />
      <SopReportModal isOpen={sopCsvUploadOpen} toggle={setSopCsvUploadOpen} />
      <ResendEmrModal isOpen={resendEmrOpen} toggle={setResendEmrOpen} />
      <ResetInteractionStatus
        isOpen={resetInteractionStatusOpen}
        toggle={setResetInteractionStatusOpen}
      />
      <HidePatientDataModal
        isOpen={hidePatientDataOpen}
        toggle={() => setHidePatientDataOpen((prev) => !prev)}
      />
      <BillingFileUploaderModal
        isOpen={billingFileUploaderOpen}
        toggle={() => setBillingFileUploaderOpen((prev) => !prev)}
      />
    </>
  );
};

export default CsvDropdown;
