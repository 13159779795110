import { useTranslation } from 'react-i18next';

import s from './style.module.scss';

const Title = () => {
  const { t } = useTranslation();

  return <p className={s.title}>{t('labels.billingTools')}</p>;
};

export default Title;
