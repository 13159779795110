import BaseButton from 'components/atoms/baseButton';
import StickiesIcon from 'components/atoms/icons/stickies';
import { useTranslation } from 'react-i18next';

import s from './style.module.scss';

import type { FC } from 'react';

interface Props {
  copyText: () => unknown;
}

const ModalFooter: FC<Props> = ({ copyText }) => {
  const { t } = useTranslation();

  return (
    <div>
      <BaseButton
        className={s['copy-button']}
        label={t('controls.copyJSON')}
        onClick={copyText}
        width="100%"
        height={32}
        icon={<StickiesIcon />}
      />
    </div>
  );
};

export default ModalFooter;
