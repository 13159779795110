import Title from './title';
import BillingClaimsAthenaUpload from './upload';
import s from './style.module.scss';

import type { FC } from 'react';

interface Props {
  onUpload: () => any;
}

const BillingClaimsAthenaHeader: FC<Props> = ({ onUpload }) => {
  return (
    <div className={s.wrapper}>
      <Title />
      <BillingClaimsAthenaUpload onUpload={onUpload} />
    </div>
  );
};

export default BillingClaimsAthenaHeader;
