import i18next from 'i18next';
import BaseTableOptionsDropdown from 'components/atoms/base-table-option-dropdown';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';

import s from './styles.module.scss';

import type { IAllergyLog, IUpdateAllergyLog } from 'infrastructure/interfaces';
import type { IBaseTableColumns } from 'components/atoms/base-table/types';

interface Props {
  setLogForUpdate: (args: IUpdateAllergyLog) => void;
  loading: boolean;
  disableActions: boolean;
  updatedItemGuid?: string;
}

export const columns = ({
  setLogForUpdate,
  loading,
  updatedItemGuid,
  disableActions,
}: Props): IBaseTableColumns<IAllergyLog> => {
  return [
    {
      label: i18next.t('labels.lastUpdatedDate'),
      key: 'updatedAt',
      width: '10%',
      render(date: string) {
        return (
          <span data-cy="allergy-log-updatedAt">
            {DateFormatter({
              date,
              format: DateFormats['MM-dd-yyyy h:mm a'],
            })}
          </span>
        );
      },
    },
    {
      label: i18next.t('labels.allergy'),
      key: 'allergy',
      width: '20%',
      render(value: string) {
        return <span data-cy="allergy-log-allergy">{value}</span>;
      },
    },
    {
      label: i18next.t('labels.allergicResponse'),
      key: 'allergicResponse',
      width: '50%',
      render(value: string) {
        return (
          <span
            className={s['allergic-response']}
            data-cy="allergy-log-allergicResponse"
          >
            {value}
          </span>
        );
      },
    },
    {
      label: i18next.t('labels.epipenRequired'),
      key: 'epipenRequired',
      width: '15%',
      render(value: boolean) {
        return (
          <span data-cy="allergy-log-epipenRequired">
            {value ? i18next.t('controls.yes') : i18next.t('controls.no')}
          </span>
        );
      },
      align: 'right',
    },
    {
      label: i18next.t('labels.option'),
      key: 'guid',
      width: '5%',
      render(guid, item) {
        return (
          <BaseTableOptionsDropdown
            loading={loading && guid === updatedItemGuid}
            onEdit={() => setLogForUpdate(item)}
            disabled={disableActions}
          />
        );
      },
    },
  ];
};
