import { useState } from 'react';

import type { Filters } from './types';

export const useBillingClaimsAthenaFilters = () => {
  const DEFAULT_FILTERS = {
    page: 1,
    items: 100,
  };

  const [filters, setFilters] = useState<Filters>(DEFAULT_FILTERS);

  const changePage = (page: number) => {
    setFilters((prev) => ({
      ...prev,
      page,
    }));
  };

  return {
    filters,
    changePage,
  };
};
