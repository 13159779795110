import React from 'react';
import PropTypes from 'prop-types';
import { Auth0Provider } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import { useNavigate } from 'react-router-dom';

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
const AUTH0_CONNECTION = process.env.REACT_APP_AUTH0_CONNECTION;

const datadogSiteUrl = process.env.REACT_APP_DATADOG_SITE_URL;
const datadogApplicationId = process.env.REACT_APP_DATADOG_APPLICATION_ID;
const datadogClientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
const rogerEnv = process.env.REACT_APP_ENV || 'local';
const rogerVersion = process.env.REACT_APP_VERSION || '1.0.0';

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  if (
    datadogSiteUrl &&
    datadogApplicationId &&
    datadogClientToken &&
    rogerEnv !== 'local'
  ) {
    const datadogServiceName = 'evelyn-ui';
    const sessionSampleRate = 100;

    datadogRum.init({
      applicationId: datadogApplicationId,
      clientToken: datadogClientToken,
      site: datadogSiteUrl,
      service: datadogServiceName,
      env: rogerEnv,
      version: rogerVersion,
      sessionSampleRate,
      sessionReplaySampleRate: sessionSampleRate,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      startSessionReplayRecordingManually: true,
    });
  }

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={AUTH0_AUDIENCE}
      cacheLocation="localstorage"
      connection={AUTH0_CONNECTION}
      useRefreshTokens="true"
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
AuthProvider.propTypes = {
  children: PropTypes.object,
};

export default AuthProvider;
