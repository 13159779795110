import { useQuery } from '@tanstack/react-query';
import QueryParams from 'utils/queryParams';
import { EPIC_EMR_NAMES_LIST } from 'services/dataService/resources';
import dataService from 'services/dataService';

import type { EpicEmrNamesData } from './types';

export const useEpicEmrNames = () => {
  const queryKey = ['epicEmrNames'];

  const { data, error, isLoading, isPreviousData, refetch } = useQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: () => getEpicEmrNames(),
  });

  return {
    data: {
      data: data?.items,
    },
    refetch,
    isLoading: isLoading || isPreviousData,
    error,
  };
};

const getEpicEmrNames = async () => {
  const url = QueryParams.stringifyUrl(EPIC_EMR_NAMES_LIST);
  const { error, data } = await dataService.getList<EpicEmrNamesData>(url);
  if (error) {
    throw new Error(error);
  }
  return data;
};
