import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import useUser from 'utils/useUser';
import ProfileMenuIcon from 'components/atoms/icons/profileMenuIconNew';
import ProfileIcon from 'components/atoms/icons/profileIconNew';
import { PLATFORM_CONFIG } from 'utils/accessManager/constants';
import {
  isPractice,
  isAdmin,
  isPatient,
  isAgencyDoctor,
  agencyDoctorWithRPMAndCCMRoles,
  isTurkUserRole,
  isRoleNameAdministrator,
} from 'utils/userTypeHelper';
import path from 'constants/paths';
import { isCriticalOrAtRiskAlertingRole } from 'utils/accessManager/helpers';
import useLogout from 'utils/hooks/auth/useLogout';
import Button from 'components/atoms/button';
import LogoutIcon from 'components/atoms/icons/logoutIconNew';

import Paths from '../../../constants/paths';

const NavbarProfileMenu = ({
  firstName,
  lastName,
  title,
  dataCy,
  limitedMenu,
}) => {
  const navigate = useNavigate();
  const { userType, roleNames, platformMode } = useUser();
  const {
    user: { switchPlatformMode },
  } = useDispatch();
  const { logout } = useLogout();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const togglePlatformMode = () => {
    const newMode = switchPlatformMode({});
    navigate(PLATFORM_CONFIG[newMode].dashboard);
  };

  let nameString = '';
  if (title !== '' && firstName !== '' && lastName !== '') {
    nameString = `${title} ${firstName} ${lastName}`;
  }
  if (title === '' && firstName !== '' && lastName !== '') {
    nameString = `${firstName} ${lastName}`;
  }

  const handleSwitchPlatform = (mode) => {
    if (platformMode === mode) return;

    togglePlatformMode();
  };

  const isTurkUser = isTurkUserRole(roleNames);

  const isOnlyAdminPanelAccess =
    roleNames.length === 1 && isRoleNameAdministrator(roleNames);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} nav inNavbar>
      <DropdownToggle nav className="navbar-dropdown-profile" data-cy={dataCy}>
        <ProfileMenuIcon animate={dropdownOpen} />
      </DropdownToggle>
      <DropdownMenu right className="navbar-dropdown profile-menu">
        <DropdownItem
          tag={Link}
          className="navbar-dropdown-item profile-item profile-name"
          to={!isCriticalOrAtRiskAlertingRole(roleNames) ? path.profile : ''}
          data-cy="nav-profile__edit"
        >
          <ProfileIcon className="profile-name-icon" />
          <p>{nameString}</p>
        </DropdownItem>
        {!isTurkUser && (
          <>
            {PLATFORM_CONFIG[platformMode].switch &&
              !limitedMenu &&
              ((isPractice(userType) && !isOnlyAdminPanelAccess) ||
                isAdmin(userType) ||
                agencyDoctorWithRPMAndCCMRoles(userType, roleNames)) && (
                <div className="navbar-dropdown-item profile-item disable-hover profile-item__switch-wrapper">
                  <Button
                    text={PLATFORM_CONFIG.CCM.switch}
                    className="profile-item__switch-btn"
                    data-active-platform-mode={platformMode === 'RPM'}
                    onClick={() => handleSwitchPlatform('RPM')}
                    data-cy="nav-profile__platform-mode-rpm-btn"
                    tabIndex={0}
                    role="menuitem"
                  />
                  <div className="profile-item__vertical-divider" />
                  <Button
                    text={PLATFORM_CONFIG.RPM.switch}
                    className="profile-item__switch-btn"
                    data-active-platform-mode={platformMode === 'CCM'}
                    onClick={() => handleSwitchPlatform('CCM')}
                    data-cy="nav-profile__platform-mode-ccm-btn"
                    tabIndex={0}
                    role="menuitem"
                  />
                </div>
              )}
            {!isPatient(userType) && (
              <>
                <DropdownItem
                  tag="a"
                  href={Paths.telmed}
                  target="_blank"
                  data-cy="nav-profile__telemed"
                  className="navbar-dropdown-item profile-item"
                >
                  Telemed
                </DropdownItem>
                <DropdownItem
                  tag="a"
                  href={Paths.bucketReport}
                  target="_blank"
                  data-cy="nav-profile__bucket-report"
                  className="navbar-dropdown-item profile-item"
                >
                  Files.com
                </DropdownItem>
              </>
            )}
            {(isAdmin(userType) || isAgencyDoctor(userType)) &&
              !limitedMenu && (
                <DropdownItem
                  tag="a"
                  href={Paths.awsConnectURL}
                  target="_blank"
                  data-cy="nav-profile__aws-connect"
                  className="navbar-dropdown-item profile-item disable-border"
                >
                  Amazon connect
                </DropdownItem>
              )}
            <div className="navbar-dropdown-item profile-item__filler disable-hover" />
          </>
        )}
        <DropdownItem
          tag="div"
          className="navbar-dropdown-item profile-item profile-item__logout"
          data-cy="nav-profile__logout"
          onClick={() => logout()}
        >
          <p>Log Out</p>
          <LogoutIcon />
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

NavbarProfileMenu.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  title: PropTypes.string,
  dataCy: PropTypes.string,
  limitedMenu: PropTypes.bool,
};

export default NavbarProfileMenu;
