import React from 'react';

import type { IBaseComponentProps } from 'infrastructure/interfaces';

const DocumentIcon: React.FC<IBaseComponentProps> = ({ className }) => (
  <svg
    className={className}
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3327 2.64774V7.46671C16.3327 8.1201 16.3327 8.4468 16.4598 8.69636C16.5717 8.91589 16.7502 9.09436 16.9697 9.20621C17.2193 9.33337 17.546 9.33337 18.1994 9.33337H23.0183M23.3327 11.653V20.0667C23.3327 22.0269 23.3327 23.007 22.9512 23.7557C22.6156 24.4142 22.0802 24.9497 21.4217 25.2852C20.673 25.6667 19.6929 25.6667 17.7327 25.6667H10.266C8.30583 25.6667 7.32574 25.6667 6.57705 25.2852C5.91848 24.9497 5.38305 24.4142 5.04749 23.7557C4.66602 23.007 4.66602 22.0269 4.66602 20.0667V7.93337C4.66602 5.97319 4.66602 4.9931 5.04749 4.24441C5.38305 3.58584 5.91848 3.05041 6.57705 2.71485C7.32574 2.33337 8.30583 2.33337 10.266 2.33337H14.0131C14.8692 2.33337 15.2972 2.33337 15.7 2.43008C16.0571 2.51582 16.3985 2.65723 16.7117 2.84913C17.0649 3.06558 17.3676 3.36825 17.9729 3.97358L21.6925 7.69317C22.2978 8.2985 22.6005 8.60117 22.8169 8.95438C23.0088 9.26753 23.1502 9.60894 23.236 9.96606C23.3327 10.3689 23.3327 10.7969 23.3327 11.653Z"
      stroke="#667180"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DocumentIcon;
