import { useState, type FC, type ReactNode } from 'react';
import PatientsAutocomplete from 'components/molecules/autocompleteFilter/patients';
import AgenciesAutocomplete from 'components/molecules/autocompleteFilter/agencies';
import ClinicsAutocomplete from 'components/molecules/autocompleteFilter/clinics';
import useUser from 'utils/useUser';
import { isAdmin, isRoleNameAdministrator } from 'utils/userTypeHelper';
import DoctorsAutocomplete from 'components/molecules/autocompleteFilter/doctors';
import MonthPicker from 'components/molecules/datepicker/monthPicker';
import { useTranslation } from 'react-i18next';
import forms from 'constants/forms';
import BaseSelect from 'components/atoms/baseSelect';

import s from './styles.module.scss';

import type { DefaultOptionType } from 'antd/es/select';
import type { Option } from 'components/molecules/autocompleteFilter/base';
import type {
  Filters,
  AppendFilters,
} from 'infrastructure/hooks/interactiveTimeDashboard/types';

interface Props {
  filters: Filters;
  appendFilters: AppendFilters;
}

const Wrapper: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className={s['filter-wrapper']}>{children}</div>;
};

const Body: FC<Props> = ({ appendFilters, filters }) => {
  const { userType, roleNames } = useUser();
  const [isShowSwitcher, setIsShowSwitcher] = useState<boolean>(false);
  const DEFAULT_CHILD_CLINIC = forms.childClinicSelect[0];

  const isAdminRole = isRoleNameAdministrator(roleNames);
  const isUserAdmin = isAdmin(userType);
  const showAgenciesFilter =
    isUserAdmin || (userType !== 'practice' && isAdminRole);

  const { t } = useTranslation();

  const onClinicChange = (values: Option[], options: DefaultOptionType[]) => {
    const isAnyParentClinic = options.some((record) => record.parentClinic);

    appendFilters({ clinicGuid: values, childClinic: isAnyParentClinic || '' });
    setIsShowSwitcher(isAnyParentClinic);
  };

  return (
    <div className={s.wrapper}>
      <Wrapper>
        <MonthPicker
          value={{ year: filters.year, month: filters.month }}
          onChange={(value) => {
            if (!value) {
              appendFilters({
                year: new Date().getFullYear(),
                month: new Date().getMonth() + 1,
              });
            } else {
              appendFilters({ year: value.year, month: value.month });
            }
          }}
        />
      </Wrapper>
      <Wrapper>
        <PatientsAutocomplete
          value={filters.patientGuid}
          onChange={(e) => appendFilters({ patientGuid: e })}
        />
        <DoctorsAutocomplete
          value={filters.doctorGuid}
          onChange={(e) => appendFilters({ doctorGuid: e })}
        />
      </Wrapper>
      <Wrapper>
        {showAgenciesFilter && (
          <AgenciesAutocomplete
            value={filters.agencyGuid}
            onChange={(e) => appendFilters({ agencyGuid: e })}
          />
        )}
        <ClinicsAutocomplete
          value={filters.clinicGuid}
          onChange={onClinicChange}
        />
      </Wrapper>

      <Wrapper>
        {isShowSwitcher && (
          <div style={{ width: '100%' }}>
            <label style={{ fontSize: '14px' }}>
              {t('labels.childClinics')}
            </label>
            <BaseSelect
              options={forms.childClinicSelect}
              defaultValue={DEFAULT_CHILD_CLINIC}
              classNamePrefix="select-type"
              onChange={(e) => appendFilters({ childClinic: e?.value })}
            />
          </div>
        )}
      </Wrapper>
    </div>
  );
};

export default Body;
