import BaseGrid from 'components/atoms/base-grid';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { combineFormResults } from 'infrastructure/functions/combine-form-results';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import { scrollToErrorIfExist } from 'utils';

import AgencyContactInformationForm from './contact-information';
import AgencyAddressForm from './address';
import AgencyDetailForm from './detail';
import AgencyRegistrationForm from './registration';

import type { AgencyInfo } from 'infrastructure/classes/agency/agency-info';
import type { IAgencyInfo } from 'infrastructure/interfaces';
import type {
  IIndependentFormProps,
  IIndependentFormRef,
} from 'infrastructure/interfaces/common/i-independent-form';

const FormDisplayName = 'AgencyForm';

export interface IAgencyFormRef extends IIndependentFormRef<IAgencyInfo> {}

interface IProps extends IIndependentFormProps {
  info?: AgencyInfo;
  loading?: boolean;
}

const AgencyForm = forwardRef<IAgencyFormRef, IProps>((props, ref) => {
  const { info, loading = false } = props;

  const hasInfo = Boolean(info);

  const contactInfoRef = useRef<IIndependentFormRef>(null);
  const contactAddressRef = useRef<IIndependentFormRef>(null);
  const registrationFormRef = useRef<IIndependentFormRef>(null);
  const detailRef = useRef<IIndependentFormRef>(null);

  const submitForm = async () => {
    const res = await combineFormResults<IAgencyInfo>(
      [contactInfoRef, contactAddressRef, detailRef, registrationFormRef],
      hasInfo,
    )
      .then((values) => {
        return {
          values,
          formName: FormDisplayName,
          isValid: true,
        };
      })
      .catch((err) => {
        scrollToErrorIfExist();
        return err;
      });

    return res;
  };

  const resetForm = () => {
    contactInfoRef.current?.reset();
    contactAddressRef.current?.reset();
    detailRef.current?.reset();
    registrationFormRef.current?.reset();
  };

  useImperativeHandle(ref, () => ({
    submit: submitForm,
    reset: resetForm,
  }));

  return (
    <BaseSpinWrapper spinning={loading}>
      <BaseGrid columns={1} rowGap={40}>
        <BaseGrid columns={2} columnGap={20}>
          <AgencyContactInformationForm ref={contactInfoRef} data={info} />
          <AgencyAddressForm ref={contactAddressRef} data={info} />
        </BaseGrid>
        <BaseGrid columns={2} columnGap={20}>
          <AgencyRegistrationForm ref={registrationFormRef} data={info} />
          <AgencyDetailForm ref={detailRef} data={info} />
        </BaseGrid>
      </BaseGrid>
    </BaseSpinWrapper>
  );
});

AgencyForm.displayName = FormDisplayName;

export default AgencyForm;
