import CsvUploadModal from 'components/molecules/csvUploadModal';
import { BILLING_STITCH_FILE } from 'services/dataService/resources';
import dataService from 'services/dataService';
import { showResult } from 'infrastructure/hooks/utils';
import { useTranslation } from 'react-i18next';

import type { FC } from 'react';

interface Props {
  isOpen: boolean;
  toggle: () => any;
}

const BillingFileUploaderModal: FC<Props> = ({ isOpen, toggle }) => {
  const { t } = useTranslation();
  const uploadCsvFile = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    const { error, data } = await dataService.uploadFile(
      BILLING_STITCH_FILE,
      formData,
      'POST',
    );

    if (error) {
      return { error };
    }

    if (data) {
      showResult();
      toggle();
    }
    return {};
  };

  return (
    <CsvUploadModal
      isOpen={isOpen}
      toggle={toggle}
      title={t('adminTools.uploadBillingFile')}
      sampleFileUrl="/csvTemplates/billingFile.csv"
      uploadCsvFile={uploadCsvFile}
    />
  );
};

export default BillingFileUploaderModal;
