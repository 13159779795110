import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import BaseForm from 'components/atoms/base-form';
import BaseFormGroup from 'components/atoms/base-form/group';
import BaseFormItem from 'components/atoms/base-form/item';
import BaseGrid from 'components/atoms/base-grid';
import BaseInput from 'components/atoms/base-input';
import { mergeFieldsValue } from 'infrastructure/functions';

import { agencyDetailValidationSchema } from './validation-schema';

import type { AgencyInfo } from 'infrastructure/classes/agency/agency-info';
import type { IAgencyDetail } from 'infrastructure/interfaces';
import type {
  IIndependentFormProps,
  IIndependentFormRef,
} from 'infrastructure/interfaces/common/i-independent-form';

const prepareValue = (data?: AgencyInfo): IAgencyDetail => {
  let defaultValue: IAgencyDetail = {
    npiNumber: null,
    taxIdNumber: null,
    medicalIdTpiNumber: null,
    stateIdNumber: null,
    taxonomyNumber: null,
    medicareNumber: null,
  };
  if (data) {
    defaultValue = mergeFieldsValue(defaultValue, data);
  }

  return defaultValue;
};

const FormDisplayName = 'AgencyDetailForm';

interface IProps extends IIndependentFormProps {
  data?: AgencyInfo;
}

const AgencyDetailForm = forwardRef<IIndependentFormRef<IAgencyDetail>, IProps>(
  (props, ref) => {
    const { data } = props;

    const { t } = useTranslation();

    const formik = useFormik<IAgencyDetail>({
      initialValues: prepareValue(data),
      onSubmit: () => {},
      validateOnChange: false,
      validationSchema: agencyDetailValidationSchema(),
    });

    const submitForm = async () => {
      await formik.submitForm();
      const isValid = await formik
        .validateForm()
        .then((res) => !Object.keys(res).length);

      return {
        values: formik.values,
        formName: FormDisplayName,
        isValid,
      };
    };

    useImperativeHandle(ref, () => ({
      submit: submitForm,
      reset: formik.resetForm,
    }));

    useEffect(() => {
      if (data) formik.resetForm({ values: prepareValue(data) });
    }, [data]);

    return (
      <BaseForm formik={formik}>
        <BaseFormGroup label={t('labels.details')}>
          <BaseGrid columns={3}>
            <BaseFormItem name="npiNumber" label={t('labels.npi')} isBlackLabel>
              <BaseInput />
            </BaseFormItem>
            <BaseFormItem
              name="taxIdNumber"
              label={t('labels.taxIdNumber')}
              isBlackLabel
            >
              <BaseInput />
            </BaseFormItem>
            <BaseFormItem
              name="medicalIdTpiNumber"
              label={t('labels.medicalIdTpiNumber')}
              isBlackLabel
            >
              <BaseInput />
            </BaseFormItem>
            <BaseFormItem
              name="stateIdNumber"
              label={t('labels.stateIdNumber')}
              isBlackLabel
            >
              <BaseInput />
            </BaseFormItem>
            <BaseFormItem
              name="taxonomyNumber"
              label={t('labels.taxonomyNumber')}
              isBlackLabel
            >
              <BaseInput />
            </BaseFormItem>
            <BaseFormItem
              name="medicareNumber"
              label={t('labels.medicareNumber')}
              isBlackLabel
            >
              <BaseInput />
            </BaseFormItem>
          </BaseGrid>
        </BaseFormGroup>
      </BaseForm>
    );
  },
);

AgencyDetailForm.displayName = FormDisplayName;

export default AgencyDetailForm;
