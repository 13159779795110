import { useTranslation } from 'react-i18next';
import { DASHBOARD_ACTIVITY_PER_PAGE } from 'infrastructure/hooks/rpm';
import ActivityTable from 'components/molecules/activity-table';
import { memo, type FC } from 'react';
import BasePagination from 'components/atoms/base-pagination';

import s from './styles.module.scss';
import { useActivityDashboard } from './hooks/use-activity-dashboard';

interface PatientActivityProps {}
const RpmActivity: FC<PatientActivityProps> = () => {
  const { t } = useTranslation();
  const {
    activity,
    loading,
    resetActions,
    filter,
    queryKey,
    updateQueryParams,
    onSort,
  } = useActivityDashboard();

  return (
    <div className={s.wrapper} id="activity">
      <div className={s.header}>
        <p className={s.readings}>
          {t('labels.readings')}: <span>{activity?.count ?? ''}</span>
        </p>
        <BasePagination
          page={filter?.page}
          total={activity?.count ?? 0}
          align="end"
          onChange={async (newPage) => {
            resetActions();
            updateQueryParams({ page: newPage });
          }}
          pageSize={DASHBOARD_ACTIVITY_PER_PAGE}
          pageSizeOptions={[]}
          hide={false}
          showSizeChanger={false}
        />
      </div>
      <div className={s.divider} />
      <div className={s.body}>
        <ActivityTable
          activity={activity}
          loading={loading}
          page={filter?.page}
          setPage={(newPage) => {
            updateQueryParams({ page: Number(newPage) });
          }}
          itemsQueryKey={queryKey}
          showReviewCol={!filter?.reviewed}
          view="dashboard"
          onSort={onSort}
          pageSize={DASHBOARD_ACTIVITY_PER_PAGE}
        />
      </div>
    </div>
  );
};
export default memo(RpmActivity);
