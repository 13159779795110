import { useLocation, useNavigate } from 'react-router-dom';

export const useQueryParams = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);

  const getQueryByName = <T = any>(fieldName: string): T | undefined => {
    const val = urlParams.get(fieldName);
    return val ? (JSON.parse(val) as T) : (val as T);
  };

  const getQuery = <
    T extends Record<string, any> = Record<string, any>,
  >(): T => {
    const params: Partial<Record<keyof T, string | null>> = {};

    for (const key of urlParams.keys()) {
      params[key as keyof T] = getQueryByName(key);
    }

    return params as T;
  };

  const resetQuery = () => {
    navigate(
      {
        search: '',
      },
      {
        replace: true,
      },
    );
  };

  const setQuery = (query: Record<string, any>) => {
    let search = '';

    for (const key of Object.keys(query)) {
      if (search.length > 0) {
        search += '&';
      }
      search += `${encodeURIComponent(key)}=${JSON.stringify(query[key])}`;
    }

    navigate(
      {
        search,
      },
      {
        replace: true,
      },
    );
  };

  const mergeQuery = (query: Record<string, any>) => {
    const currentQuery = getQuery();
    let mergedQuery = query;

    if (currentQuery) {
      mergedQuery = Object.assign(currentQuery, mergedQuery);
    }
    setQuery(mergedQuery);
  };

  return {
    resetQuery,
    getQuery,
    getQueryByName,
    setQuery,
    mergeQuery,
  };
};
