import React, { useRef } from 'react';
import BaseContainer from 'components/atoms/base-container';
import AdminNavigationHeader from 'components/organisms/admin-panel/navigation-header';
import { useTranslation } from 'react-i18next';
import { Routes } from 'infrastructure/consts/routes';
import { useNavigate } from 'react-router-dom';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import SaveButton from 'components/atoms/controls/save-button';
import { useCreateAgency } from 'infrastructure/hooks/agency';
import AgencyForm from 'components/molecules/agency/form';

import type { IAgencyFormRef } from 'components/molecules/agency/form';

const AdminAgencyCreatePage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loading, createAgency } = useCreateAgency();

  const ref = useRef<IAgencyFormRef>(null);

  const onSave = async () => {
    if (ref.current) {
      const res = await ref.current.submit();

      if (res.isValid) {
        const agency = await createAgency(res.values);
        if (agency) {
          navigate(Routes.adminAgencyDetail(agency.guid), { replace: true });
        }
      }
    }
  };

  const controls = (
    <SaveButton
      width="160px"
      label={t('controls.saveChanges')}
      onClick={onSave}
    />
  );

  return (
    <BaseSpinWrapper spinning={loading}>
      <BaseContainer>
        <AdminNavigationHeader
          goBackPath={Routes.adminAgencies}
          title={t('labels.addAgency')}
          controls={controls}
        />
        <AgencyForm ref={ref} />
      </BaseContainer>
    </BaseSpinWrapper>
  );
};

export default AdminAgencyCreatePage;
