import React, { useRef } from 'react';
import BaseContainer from 'components/atoms/base-container';
import AdminNavigationHeader from 'components/organisms/admin-panel/navigation-header';
import { useTranslation } from 'react-i18next';
import { Routes } from 'infrastructure/consts/routes';
import { useNavigate, useParams } from 'react-router-dom';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import SaveButton from 'components/atoms/controls/save-button';
import { useAgencyInfo, useEditAgency } from 'infrastructure/hooks/agency';
import AgencyForm from 'components/molecules/agency/form';

import type { IAgencyFormRef } from 'components/molecules/agency/form';

const AdminAgencyEditPage: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const agencyGuid = useParams<{
    agencyGuid: string;
  }>().agencyGuid!;

  const { agencyInfoLoading, agencyInfo } = useAgencyInfo({ agencyGuid });
  const { loading: editLoading, editAgency } = useEditAgency();

  const loading = agencyInfoLoading || editLoading;

  const ref = useRef<IAgencyFormRef>(null);

  const onSave = async () => {
    if (ref.current) {
      const res = await ref.current.submit();

      if (res.isValid) {
        await Promise.all([
          editAgency({
            ...res.values,
            guid: agencyGuid,
          }),
        ]).then(() => {
          navigate(Routes.adminAgencyDetail(agencyGuid), { replace: true });
        });
      }
    }
  };

  const controls = (
    <SaveButton
      width="160px"
      label={t('controls.saveChanges')}
      onClick={onSave}
    />
  );

  return (
    <BaseSpinWrapper spinning={loading}>
      <BaseContainer>
        <AdminNavigationHeader
          goBackPath={Routes.adminAgencies}
          title={t('labels.editAgency')}
          controls={controls}
        />
        <AgencyForm ref={ref} info={agencyInfo} />
      </BaseContainer>
    </BaseSpinWrapper>
  );
};

export default AdminAgencyEditPage;
