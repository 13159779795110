const ACCUHEALTH_AGENCY_GUIDS = {
  dev: '2af4707f-3ee8-4bfb-9f8f-da5d618c712c',
  qa: 'b1318c5f-3cd8-401a-9c2c-4a6bfa8ac6c7',
  prod: '415f1aa5-4c9b-471b-8580-d156e29f213f',
} as const;

type Environment = 'local' | 'dev' | 'qa' | 'prod';

export const rogerEnv = (process.env.REACT_APP_ENV as Environment) || 'local';

const envToGuidMapping: Record<Environment, string> = {
  local: ACCUHEALTH_AGENCY_GUIDS.dev,
  dev: ACCUHEALTH_AGENCY_GUIDS.dev,
  qa: ACCUHEALTH_AGENCY_GUIDS.qa,
  prod: ACCUHEALTH_AGENCY_GUIDS.prod,
};

export const ACCUHEALTH_AGENCY_GUID_BY_ENV = envToGuidMapping[rogerEnv];
