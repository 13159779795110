import BaseButton from 'components/atoms/baseButton';
import CsvUploadModal from 'components/molecules/csvUploadModal';
import { showResult } from 'infrastructure/hooks/utils';
import { useMemo, useState } from 'react';
import dataService from 'services/dataService';
import { API_BILLING_CLAIM_EPIC_PROCESS_CREATE } from 'services/dataService/resources';
import { useTranslation } from 'react-i18next';
import { SampleFilesUrl } from 'infrastructure/consts/sampleFiles';
import BaseSelect from 'components/atoms/baseSelect';
import QueryParams from 'utils/queryParams';
import PlusLgIcon from 'components/atoms/icons/plusLg';

import s from './style.module.scss';

import type { EpicEmrNames } from 'infrastructure/hooks/billingTools/epic/types';
import type { FC } from 'react';

interface Props {
  onUpload: () => any;
  emrNameList: EpicEmrNames[] | undefined;
  isEmrNamesLoading: boolean;
}

const EpicClaimUpload: FC<Props> = ({
  onUpload,
  emrNameList,
  isEmrNamesLoading,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [emrName, setEmrName] = useState<string | null | undefined>(null);
  const toggle = () => setIsOpen((prev) => !prev);

  const emrNamesOptions = useMemo(() => {
    const formatted = emrNameList?.map((item) => ({
      value: item.name,
      label: item.name,
    }));
    return formatted;
  }, [emrNameList]);

  const uploadCsvFile = async (file: File) => {
    if (!emrName) {
      showResult('Select Emr Name');
      return { error: 'Select Emr Name' };
    }
    const formData = new FormData();
    formData.append('file', file);

    const queryParam = QueryParams.stringifyUrl(emrName);
    const { error, data } = await dataService.uploadFile(
      `${API_BILLING_CLAIM_EPIC_PROCESS_CREATE}/${queryParam}`,
      formData,
      'POST',
    );

    if (error) {
      return { error };
    }

    if (data) {
      setIsOpen(false);
      showResult();
      onUpload();
    }
    return {};
  };

  return (
    <div className={s['upload-button-wrapper']}>
      <div className={s['button-dropdown-wrapper']}>
        <div className={s['emrName-dropdown']}>
          <span>{t('labels.emrName')}</span>
          <BaseSelect
            isDisabled={isEmrNamesLoading}
            classNamePrefix="select-type"
            className={s.select}
            options={emrNamesOptions}
            onChange={(e) => {
              setEmrName(e?.value);
            }}
          />
        </div>
        <BaseButton
          disabled={!emrNameList?.length || !emrName}
          onClick={toggle}
          type="primary"
          label={t('labels.createNewProcess')}
          icon={<PlusLgIcon />}
        />
      </div>
      <CsvUploadModal
        isOpen={isOpen}
        toggle={toggle}
        title={t('labels.billingToolsEpic')}
        sampleFileUrl={SampleFilesUrl.epicClaimTemplate}
        uploadCsvFile={uploadCsvFile}
      />
    </div>
  );
};

export default EpicClaimUpload;
