import React from 'react';
import Icon from '@ant-design/icons';

import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const RedirectIconSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.47727 2.625C6.47727 2.41789 6.30938 2.25 6.10227 2.25H1.125C0.503679 2.25 0 2.75368 0 3.375V10.875C0 11.4963 0.503679 12 1.125 12H8.625C9.24632 12 9.75 11.4963 9.75 10.875V5.89773C9.75 5.69062 9.58211 5.52273 9.375 5.52273C9.16789 5.52273 9 5.69062 9 5.89773V10.875C9 11.0821 8.83211 11.25 8.625 11.25H1.125C0.917892 11.25 0.75 11.0821 0.75 10.875V3.375C0.75 3.16789 0.917892 3 1.125 3H6.10227C6.30938 3 6.47727 2.83211 6.47727 2.625Z"
        fill="#2C2543"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0.375C12 0.167893 11.8321 0 11.625 0H7.875C7.66789 0 7.5 0.167893 7.5 0.375C7.5 0.582107 7.66789 0.75 7.875 0.75H10.7197L4.60983 6.85983C4.46339 7.00628 4.46339 7.24372 4.60983 7.39017C4.75628 7.53661 4.99372 7.53661 5.14017 7.39017L11.25 1.28033V4.125C11.25 4.33211 11.4179 4.5 11.625 4.5C11.8321 4.5 12 4.33211 12 4.125V0.375Z"
        fill="#2C2543"
      />
    </svg>
  );
};

const RedirectIcon: React.FC<Partial<CustomIconComponentProps>> = (props) => {
  return <Icon component={RedirectIconSVG} {...props} />;
};

export default RedirectIcon;
