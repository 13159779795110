import UploadFile from 'components/organisms/csvUpload';
import BaseModal from 'components/atoms/base-modal';
import BaseButton from 'components/atoms/baseButton';
import { useTranslation } from 'react-i18next';
import DownloadOutlinedIcon from 'components/atoms/icons/downloadOutlinedIcon';
import { useState, type FC } from 'react';

interface Props {
  isOpen: boolean;
  toggle: () => any;
  uploadCsvFile: (file: File) => Promise<{ error?: any }>;
  sampleFileUrl?: string;
  title: string;
  fileType?: 'csv' | 'json';
}

const FileUploadModal: FC<Props> = ({
  isOpen,
  toggle,
  uploadCsvFile,
  sampleFileUrl,
  title,
  fileType = 'csv',
}) => {
  const { t } = useTranslation();
  const [localFile, setLocalFile] = useState<File>(new File([], ''));
  const [loading, setLoading] = useState<boolean>(false);

  const clearFile = () => {
    setLocalFile(new File([], ''));
  };

  const uploadFile = (file: File): Promise<{ error?: any }> => {
    try {
      setLocalFile(file);
      return Promise.resolve({});
    } catch (error) {
      return Promise.resolve({ error });
    }
  };
  return (
    <BaseModal
      open={isOpen}
      onCancel={() => {
        setLoading(false);
        clearFile();
        toggle();
      }}
      title={title}
      width="1000px"
      smallBodyPaddingWithFooter
      withFooter={[
        sampleFileUrl ? (
          <BaseButton
            dataCy="download-button"
            key="download"
            type="secondary"
            icon={<DownloadOutlinedIcon />}
            label={t('controls.downloadSample')}
            onClick={() => {
              window.open(sampleFileUrl, '_blank');
            }}
            width={184}
            className="upload-csv-button"
          />
        ) : (
          <div />
        ),
        <div style={{ width: '100%' }} />,
        <BaseButton
          dataCy="cancel-button"
          key="cancel"
          type="secondary"
          label={t('controls.cancel')}
          onClick={() => {
            setLoading(false);
            clearFile();
            toggle();
          }}
          className="upload-csv-button"
        />,
        <BaseButton
          dataCy="save-button"
          key="save"
          type="primary"
          label={t('controls.sendFile')}
          loading={loading}
          onClick={async () => {
            setLoading(true);
            await uploadCsvFile(localFile);
            clearFile();
            setLoading(false);
          }}
          disabled={localFile.size === 0}
          className="upload-csv-button"
        />,
      ]}
    >
      <div className="upload-csv-modal-body">
        <UploadFile
          uploadFile={(file) => uploadFile(file)}
          fileType={fileType}
          deleteFile={clearFile}
          sampleFileUrl={sampleFileUrl}
        />
      </div>
    </BaseModal>
  );
};

export default FileUploadModal;
