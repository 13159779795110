import DownloadRoundedIcon from 'components/atoms/icons/downloadRoundedIcon';
import withLoader from 'components/organisms/withLoader';
import { useGetActivity } from 'infrastructure/hooks/rpm/activityReport/use-get-activity-report';
import { useActivityFilter } from 'infrastructure/hooks/rpm';
import { useActivityReportFilter } from 'infrastructure/hooks/rpm/activityReport/use-filters';
import { useActivitySendReport } from 'infrastructure/hooks/rpm/activityReport/use-send-report';

import Filter from './filter';
import ActivityReportTable from './table';

const Activity = () => {
  const { filter } = useActivityFilter();
  const { activity, isLoading: activitiesLoading } = useGetActivity(filter);
  const { handleChangeValue } = useActivityReportFilter();
  const { isLoading: sendReportIsLoading, sendReportToEmail } =
    useActivitySendReport(filter);

  return (
    <>
      <div className="performance-review__header">
        <div className="activity-header">
          <h3 className="title">Report: Activity</h3>
        </div>
        <div className="performance-review__header-icon">
          {withLoader(
            sendReportIsLoading,
            <button onClick={sendReportToEmail} disabled={sendReportIsLoading}>
              <DownloadRoundedIcon className="performance-review__header-icon-svg" />
              <br />
              <span>Download</span>
            </button>,
          )}
        </div>
      </div>
      <div className="activity-body">
        <div className="sort-row">
          <Filter />
        </div>
        <div className="table-sticky-header activity-report-table">
          <ActivityReportTable
            activity={activity}
            filters={filter}
            isLoading={activitiesLoading}
            changePage={handleChangeValue}
          />
        </div>
      </div>
    </>
  );
};

export default Activity;
