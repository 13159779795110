import { useQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { showResult } from 'infrastructure/hooks/utils';

import { apiUrlGenerator } from '../../use-api';

import type { IPartialPaginationMeta } from 'infrastructure/interfaces';
import type {
  IRpmActivityCountResponse,
  IRpmPatientActivity,
} from 'infrastructure/interfaces/i-rpm-patient-activity';

export type TActivityReportFilter = {
  startTime?: number;
  endTime?: number;
  clinicGuid?: string;
  patientGuid?: string;
  doctorGuid?: string[];
  status?: string;
  reverse?: boolean;
  childClinic?: boolean;
  insuranceType?: string;
} & IPartialPaginationMeta;

export const ACTIVITY_REPORT_PER_PAGE = 100;

type TActivityReportFilterWithDoctorString = Omit<
  TActivityReportFilter,
  'doctorGuid'
> & {
  doctorGuid?: string;
};

export type TActivityReportQueryKey = [
  string,
  TActivityReportFilterWithDoctorString,
];

const getRpmActivity = async (
  filter: TActivityReportFilterWithDoctorString,
) => {
  const url = `core/patients/readings`;
  const { data, error } = await dataService.getList<IRpmPatientActivity>(
    apiUrlGenerator(url, filter),
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

const getActivityCountFn = async (
  filter: TActivityReportFilterWithDoctorString,
) => {
  const url = 'core/patients/readings/count';
  const { data, error } = await dataService.getList<IRpmActivityCountResponse>(
    apiUrlGenerator(url, filter),
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const getActivityReportKey = (
  filter: TActivityReportFilterWithDoctorString,
): TActivityReportQueryKey => ['getActivityReport', filter];
export const getActivityReportCountKey = (
  filter: TActivityReportFilterWithDoctorString,
) => ['getActivityReportCount', filter];

export const useGetActivity = (filter: TActivityReportFilter) => {
  const filterWithItems: TActivityReportFilterWithDoctorString = {
    ...filter,
    doctorGuid: filter.doctorGuid?.join(','),
    items: ACTIVITY_REPORT_PER_PAGE,
  };

  const queryKey = getActivityReportKey(filterWithItems);

  const {
    data: activity,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: async () => getRpmActivity(filterWithItems),
    enabled: Boolean(filter.startTime || filter.endTime),
    keepPreviousData: true,
  });

  const { data: activityCount, isLoading: countLoading } = useQuery({
    queryKey: getActivityReportCountKey(filterWithItems),
    queryFn: async () => getActivityCountFn(filterWithItems),
    enabled: Boolean(filter.startTime || filter.endTime),
  });

  return {
    activity,
    queryKey,
    isLoading,
    isFetching,
    activityCount,
    countLoading,
  };
};
