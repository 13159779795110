import useUser from 'utils/useUser';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ActivityStatus from 'components/atoms/activityStatus';
import { format } from 'date-fns';
import common from 'constants/common';
import { isMobile } from 'utils/deviceHelper';
import { camelCaseToWords } from 'utils/formatReportMessage';
import ReadingItem from 'components/atoms/readingItem';
import ActivityComments from 'components/molecules/comments';

import s from './styles.module.scss';

import type { IActivityData } from 'infrastructure/interfaces/i-rpm-patient-activity';
import type { TableProps } from 'antd';

export const useColumns = (): TableProps<IActivityData>['columns'] => {
  const { deviceType } = useUser();

  const { t } = useTranslation();

  return [
    {
      title: t('labels.status'),
      key: 'globalStatus',
      render: (_, record) => <ActivityStatus status={record.globalStatus} />,
    },
    {
      title: t('labels.time'),
      key: 'createdAt',
      render: (_, record) => {
        return (
          <>
            <div>
              R:{' '}
              {format(
                new Date(parseInt(record.createdAt)),
                common.dateFormats.dayOfWeek,
              )}
            </div>
            <div>
              {format(
                new Date(parseInt(record.createdAt)),
                common.dateFormats.timeAPM,
              )}
            </div>
          </>
        );
      },
    },
    {
      title: t('labels.patientName'),
      key: 'patientFullName',
      render: (_, record) => (
        <div>
          <Link to={`/patient/${record.patient.guid}`}>
            <strong>
              {record.patient.firstName} {record.patient.lastName}
            </strong>
          </Link>
        </div>
      ),
    },
    {
      title: t('labels.mrn'),
      key: 'mrn',
      render: (_, record) => (
        <>
          <span>{record.patient.mrn}</span>
          {isMobile(deviceType) && !record.patient.mrn && '-'}
        </>
      ),
    },
    {
      title: t('labels.phoneNumber'),
      key: 'phoneNumber',
      render: (_, record) => (
        <div>
          <strong>{record.patient.phone}</strong>
        </div>
      ),
    },
    {
      title: t('labels.provider'),
      key: 'provider',
      render: (_, record) => (
        <div>
          {record.doctor.title} {record.doctor.firstName}{' '}
          {record.doctor.lastName}
          {record.doctor.professionType ? ' - ' : ' '}
          {record.doctor.professionType}
        </div>
      ),
    },
    {
      title: t('labels.category'),
      key: 'category',
      render: (_, record) => (
        <div>
          <span className={`reading ${record.globalStatus}`}>
            {record.readingType !== 'SpO2'
              ? camelCaseToWords(record.readingType)
              : record.readingType}
          </span>
        </div>
      ),
    },
    {
      title: t('labels.reading'),
      key: 'reading',
      className: s.readingRow,
      render: (_, record) => <ReadingItem activity={record} />,
    },
    {
      title: t('labels.commentsReviews'),
      key: 'commentsReviews',
      width: '38%',
      render: (_, record) => <ActivityComments comments={record.comments} />,
    },
  ];
};
