import { useMutation } from '@tanstack/react-query';
import { differenceInSeconds, endOfMonth } from 'date-fns';
import { showResult } from 'infrastructure/hooks/utils';
import dataService from 'services/dataService';
import { API_READING_SUGGEST_COMMENT } from 'services/dataService/resources';
import { useAppDispatch, useAppSelector } from 'store';

import type { UseMutateFunction } from '@tanstack/react-query';

interface IDataProps {
  readingId: string;
  deviceId: string;
  patientGuid: string;
}
interface IMutationDataProps {
  readingId: string;
  deviceId: string;
}

export interface IAiCommentResponse {
  result: {
    comment: string;
    model: string;
  };
}

const TIMEOUT_SECONDS = 25;
const MAX_PARALLEL_COMMENT_GENERATIONS = 5;

const genAiCommentFn = async ({ patientGuid, ...postData }: IDataProps) => {
  const initialDate = endOfMonth(new Date());
  const { data, error } = await dataService.getOnly<IAiCommentResponse>(
    API_READING_SUGGEST_COMMENT(patientGuid),
    {
      ...postData,
      endTime: initialDate.getTime(),
      timezoneOffset: initialDate.getTimezoneOffset(),
    },
  );

  if (error) {
    const diffInSeconds = differenceInSeconds(new Date(), initialDate);
    if (diffInSeconds > TIMEOUT_SECONDS) {
      showResult('Timeout error. Please try again.');
    } else {
      showResult(error);
    }
  }

  return data;
};

export type TGenAiCommentFn = UseMutateFunction<
  IAiCommentResponse | undefined,
  unknown,
  IMutationDataProps,
  void
>;

interface IProps {
  tableRowIdx: number;
  patientGuid: string;
}
export const useGenAiComment = ({ patientGuid, tableRowIdx }: IProps) => {
  const aiCommentGenCount = useAppSelector(
    (state) => state.activityActions.aiCommentGenCount,
  );
  const {
    activityActions: { increaseAiCommentCount, decreaseAiCommentCount },
  } = useAppDispatch();

  const {
    activityActions: { updateAction },
  } = useAppDispatch();

  const {
    mutate,
    data: aiData,
    isLoading,
  } = useMutation({
    mutationFn: async (postData: IMutationDataProps) =>
      genAiCommentFn({
        ...postData,
        patientGuid,
      }),
    onMutate() {
      increaseAiCommentCount();
      updateAction({
        idx: tableRowIdx,
        action: { sending: true },
      });
    },
    onSuccess(data) {
      updateAction({
        idx: tableRowIdx,
        action: { message: data?.result.comment, sending: undefined },
      });
      decreaseAiCommentCount();
    },
  });

  const isCommentGenerationLimitReached =
    aiCommentGenCount >= MAX_PARALLEL_COMMENT_GENERATIONS;

  return {
    isCommentGenerationLimitReached,
    genAiComment: mutate,
    data: aiData,
    isLoading,
  };
};
