import { makeid } from 'utils/helpers/makeid';

import { ChangeLog } from './change-log';

import type {
  IThresholdChangeLog,
  IThresholdLog,
} from 'infrastructure/interfaces';
import type { DateString } from 'infrastructure/types';

export class ThresholdLog {
  key: string;
  changes: IThresholdChangeLog[];
  patientGuid: string;
  date: DateString;
  author: string;
  professionType: string;
  constructor(data: IThresholdLog) {
    this.key = makeid(5);
    this.changes = data.changes.map((el) => new ChangeLog(el));
    this.patientGuid = data.patientGuid;
    this.date = data.createdAt;
    this.author = `${data.createdByLogin.title} ${data.createdByLogin.firstName} ${data.createdByLogin.lastName}`;
    this.professionType = data.createdByLogin.professionType;
  }
}
