import classNames from 'classnames';
import { getActivityStatus } from 'utils';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import s from './styles.module.scss';

import type { ReadingStatus } from 'infrastructure/enums/reading-statuses';
import type { FC, ReactNode } from 'react';

interface DateColumnMobileProps {
  globalStatus: ReadingStatus;
  idx: number;
  createdAt: string;
  children: ReactNode;
}

const DateColumnMobile: FC<DateColumnMobileProps> = ({
  globalStatus,
  idx,
  createdAt,
  children,
}) => {
  const location = useLocation();
  const { slaReadings } = queryString.parse(location.search);

  const status = slaReadings === 'true' ? 'slaReading' : globalStatus;

  return (
    <div className={s['date-cell']}>
      <div className={classNames(s['date-wrapper-mob'])}>
        <p className={classNames(s[`status-${status}`], s.status)}>
          {getActivityStatus(status)}
        </p>
        <p className={s['item-number']}>#{idx}</p>
        <p className={s.date}>
          {DateFormatter({
            date: new Date(parseInt(createdAt)),
            format: DateFormats['MM-dd-yyyy hh:mm:ss a'],
          })}
        </p>
        {children}
      </div>
    </div>
  );
};
export default DateColumnMobile;
