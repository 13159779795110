import React, { useMemo, useRef } from 'react';
import { Table } from 'antd';
import { createSortOrder } from 'infrastructure/antd/functions/create-sort-order';
import classNames from 'classnames';
import { isMobile } from 'utils/deviceHelper';
import useUser from 'utils/useUser';

import s from './styles.module.scss';
import ScrollToTopButton from '../scroll-to-top-button';

import type { ReactNode } from 'react';
import type { TablePaginationConfig } from 'antd';
import type { SortState } from 'infrastructure/types';
import type {
  ColumnType,
  ColumnsType,
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from 'antd/es/table/interface';
import type { IBaseTableColumnProps, IPaginationProps } from './types';

export interface IBaseTableProps<T = any> {
  columns: Array<IBaseTableColumnProps<T>>;
  items: Array<any>;
  rowKey?: string;
  bordered?: boolean;
  loading?: boolean;
  scrollToStart?: boolean;
  anchor?: boolean;
  height?: string;
  className?: string;
  pagination?: IPaginationProps;
  onChangePage?: (page: number) => void;
  onSort?: (field: string, sort: SortState) => void;
  dataCy?: string;
  expandedRowRender?: (
    record: T,
    index: number,
    indent: number,
    expanded: boolean,
  ) => ReactNode;
  rowExpandable?: (record: T) => boolean;
  emptyText?: React.ReactNode;
  onExpand?: (expanded: boolean, record: T) => void;
  changeOddRowColor?: boolean;
  rowClassName?: string;
  rowHoverable?: boolean;
}

type TColumn<T> = ColumnType<T> & {
  dataIndex: ColumnType<any>['dataIndex'];
  key: ColumnType<any>['key'];
};

const BaseTableNew = <T,>(props: IBaseTableProps<T>) => {
  const {
    columns,
    items,
    className,
    pagination,
    onSort,
    onChangePage,
    dataCy = 'base-table',
    bordered = false,
    loading = false,
    scrollToStart = false,
    height,
    rowKey = 'guid',
    expandedRowRender,
    rowExpandable,
    emptyText,
    onExpand,
    changeOddRowColor = true,
    rowClassName,
    rowHoverable,
    anchor = false,
  } = props;

  const tableClassNames = classNames(
    s.table,
    { [s['odd-row-bg']]: changeOddRowColor },
    className,
  );
  const tableRef = useRef<HTMLDivElement>(null);
  const { deviceType } = useUser();

  const hasPages = useMemo(() => {
    if (!pagination) return false;
    return pagination?.total > pagination?.pageSize;
  }, [pagination]);

  const handleTableChange = async (
    paginationProps: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    extra: TableCurrentDataSource<T>,
  ) => {
    if (extra.action === 'paginate') {
      if (onChangePage && paginationProps.current) {
        onChangePage(paginationProps.current);
      }
      if (tableRef.current && scrollToStart) {
        setTimeout(() => {
          tableRef.current?.scrollIntoView({
            block: 'nearest',
            behavior: 'smooth',
          });
        }, 500);
      }
    }
    if (extra.action === 'sort') {
      const sort = createSortOrder(sorter as any);
      if (onSort) onSort(...sort);
    }
  };

  const hasPagination = pagination && pagination.total > pagination.pageSize;

  const mappedColumns: ColumnsType<T> = columns.map((column): ColumnType<T> => {
    return {
      title: column.label,
      dataIndex: column.key,
      key: column.key,
      render: column.render,
      width: column.width,
      align: column.align,
      sorter: column.sortable,
      sortKey: column.sortKey,
      onCell: (data: T, index?: number) => {
        const cy = column.dataCy ?? `${dataCy}-${column.key.toString()}`;
        const attributes = column?.onCell?.(data, index) ?? {};

        return { ...attributes, 'data-cy': cy };
      },
      showSorterTooltip: false,
    } as TColumn<T>;
  });

  return (
    <div ref={tableRef} data-cy={dataCy}>
      <Table
        rowKey={rowKey}
        rootClassName={tableClassNames}
        loading={loading}
        columns={mappedColumns}
        dataSource={items}
        pagination={
          hasPagination
            ? {
                ...pagination,
                position: pagination?.position,
                showSizeChanger: false,
              }
            : false
        }
        onChange={handleTableChange}
        bordered={bordered}
        scroll={{ x: '100%', y: isMobile(deviceType) ? '100%' : height }}
        onRow={(record, rowIndex) => ({
          'data-cy': dataCy ? `${dataCy}-row-${rowIndex}` : undefined,
          id: undefined,
        })}
        expandable={{
          expandedRowRender,
          rowExpandable,
          columnTitle: 'Expand',
          expandRowByClick: true,
          showExpandColumn: false,
          onExpand,
        }}
        locale={{
          emptyText,
        }}
        rowClassName={rowClassName}
        rowHoverable={rowHoverable}
      />

      {anchor && hasPages && (
        <div className={s.anchor}>
          <ScrollToTopButton />
        </div>
      )}
    </div>
  );
};

export default BaseTableNew;
