import BaseRangeDatepicker from 'components/atoms/base-range-datepicker';
import BaseSearchSelect from 'components/atoms/base-search-select';
import { useTranslation } from 'react-i18next';
import BaseSelectBox from 'components/atoms/base-select-box';
import forms from 'constants/forms';
import { Tooltip } from 'antd';

import s from './styles.module.scss';
import ItemWrapper from '../item-wrapper';

import type { TActivityReportFilter } from 'infrastructure/hooks/rpm/activityReport/use-filters';
import type { FC } from 'react';

interface BodyProps
  extends Omit<
    TActivityReportFilter,
    'onResetFilter' | 'countOfChangedFilterField'
  > {}

const DEFAULT_STATUS = forms.statusSelect[0];
const DEFAULT_SORT = forms.sortSelect[0];
const DEFAULT_INSURANCE_TYPE = forms.clinicInsuranceTypeSelect[0];
const DEFAULT_CHILD_CLINIC = forms.childClinicSelect[0];

const Body: FC<BodyProps> = (props) => {
  const {
    searchClinicsLoading,
    searchDoctorsLoading,
    searchPatientsLoading,
    debounceSearchClinics,
    defaultDateValues,
    dateValues,
    clinics,
    doctors,
    patients,
    debounceSearchPatients,
    values,
    insuranceTypes,
    onDateChange,
    handleChangeValue,
    handleClearValue,
    onSelectProvider,
    onDeselectProvider,
    onChangeProvider,
  } = props;
  const { t } = useTranslation();

  const isAnyParentClinic = clinics
    ? clinics.some((record) => record.isParentClinic)
    : false;

  return (
    <div>
      <div className={s.row}>
        <ItemWrapper label={t('labels.dateRange')}>
          <BaseRangeDatepicker
            onCalendarChange={onDateChange}
            defaultValue={dateValues || defaultDateValues}
            withPresets
            disabledDate={(current) => {
              return current && current > new Date();
            }}
            isMaxDayToday
          />
        </ItemWrapper>
        <ItemWrapper label={t('labels.patientFilter')}>
          <BaseSearchSelect
            isLoading={searchPatientsLoading}
            placeholder={t('labels.searchPatient')}
            onChange={debounceSearchPatients}
            options={patients?.items}
            onSelect={(v) => handleChangeValue('patientGuid', v)}
            value={values.patientGuid}
            onClear={() => handleClearValue('patientGuid')}
          />
        </ItemWrapper>
        <ItemWrapper label={t('labels.showStatus')}>
          <BaseSelectBox
            options={forms.statusSelect}
            defaultValue={DEFAULT_STATUS}
            value={values.status}
            onChange={(v) => handleChangeValue('status', v)}
            allowClear
          />
        </ItemWrapper>
        <ItemWrapper label={t('labels.sort')}>
          <BaseSelectBox
            options={forms.sortSelect}
            defaultValue={DEFAULT_SORT}
            value={values.status}
            onChange={(v) => handleChangeValue('reverse', v)}
            allowClear
          />
        </ItemWrapper>
      </div>
      <div className={s.row}>
        <ItemWrapper label={t('labels.provider')}>
          <BaseSearchSelect
            isLoading={searchDoctorsLoading}
            placeholder="Search"
            mode="multiple"
            onChange={onChangeProvider}
            options={doctors}
            onSelect={onSelectProvider}
            value={values.doctorGuid}
            onClear={() => handleClearValue('doctorGuid')}
            onDeselect={onDeselectProvider}
          />
        </ItemWrapper>
        <ItemWrapper label={t('labels.clinic')}>
          <BaseSearchSelect
            isLoading={searchClinicsLoading}
            placeholder="Search"
            options={clinics}
            onChange={debounceSearchClinics}
            onSelect={(v) => handleChangeValue('clinicGuid', v)}
            value={values.clinicGuid}
            onClear={() => handleClearValue('clinicGuid')}
          />
        </ItemWrapper>
        <ItemWrapper label={t('labels.insuranceProvider')}>
          <Tooltip
            placement="top"
            trigger="hover"
            title={
              !values.clinicGuid && t('messages.disableInsuranceProviderFilter')
            }
          >
            <div>
              <BaseSelectBox
                options={insuranceTypes}
                defaultValue={DEFAULT_INSURANCE_TYPE}
                value={values.insuranceType}
                onChange={(v) => handleChangeValue('insuranceType', v)}
                disabled={!values.clinicGuid}
                allowClear
              />
            </div>
          </Tooltip>
        </ItemWrapper>

        <ItemWrapper label={t('labels.childClinics')}>
          <Tooltip
            placement="top"
            trigger="hover"
            title={
              (!values.clinicGuid && t('messages.disableChildClinicFilter')) ||
              (!isAnyParentClinic && t('messages.clinicNotHaveChildClinics'))
            }
          >
            <div>
              <BaseSelectBox
                options={forms.childClinicSelect}
                defaultValue={
                  values.childClinic ? undefined : DEFAULT_CHILD_CLINIC
                }
                value={values.childClinic}
                onChange={(v) => handleChangeValue('childClinic', v)}
                disabled={!(values.clinicGuid && isAnyParentClinic)}
                allowClear
              />
            </div>
          </Tooltip>
        </ItemWrapper>
      </div>
    </div>
  );
};
export default Body;
