// FIXME: Can be deleted. Unused component

import React, { useRef, useEffect } from 'react';
import dataService from 'services/dataService';
import { API_READING_SUGGEST_COMMENT } from 'services/dataService/resources';
import { isAbortError } from 'utils';
import { showResult } from 'infrastructure/hooks/utils';
import GenerateTextIcon from 'components/atoms/icons/generateTextIcon';
import { differenceInSeconds, endOfMonth } from 'date-fns';

import type { FunctionComponent } from 'react';

const TIMEOUT_SECONDS = 25;

type Props = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  setText: (text: string, model: string) => void;
  activity: any;
  dates: Array<string>;
  disabled?: boolean;
  isCommentGenerationLimitReached: boolean;
  onCommentGenerationStatusChange: (action: 'start' | 'end') => void;
};

const GenerateCommentButton: FunctionComponent<Props> = ({
  loading,
  setLoading,
  setText,
  activity,
  dates,
  disabled,
  isCommentGenerationLimitReached,
  onCommentGenerationStatusChange,
}) => {
  const abortControllerRef = useRef<AbortController | undefined>(undefined);
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const generateComment = async () => {
    if (loading || isCommentGenerationLimitReached || disabled) return;
    const patientGuid = activity.patient.guid;
    setLoading(true);
    onCommentGenerationStatusChange('start');
    abortControllerRef.current = new AbortController();
    const initialDate = endOfMonth(new Date());
    const timezoneOffset = initialDate.getTimezoneOffset();
    const [, endTime] = dates;
    const params = {
      readingId: activity.id,
      deviceId: activity.deviceId,
      timezoneOffset,
      endTime,
    };
    const { data, error, errorName } = await dataService.getOnly(
      API_READING_SUGGEST_COMMENT(patientGuid),
      params,
      { signal: abortControllerRef.current.signal },
    );

    onCommentGenerationStatusChange('end');
    if (isAbortError(errorName)) return;
    setLoading(false);

    if (error) {
      const diffInSeconds = differenceInSeconds(new Date(), initialDate);
      if (diffInSeconds > TIMEOUT_SECONDS) {
        showResult('Timeout error. Please try again.');
      } else {
        showResult(error);
      }
    }
    if (data?.result?.comment) {
      setText(data.result.comment, data.result.model);
    }
  };

  let title = 'Generate comment using AI (this may take a few seconds)';
  if (isCommentGenerationLimitReached) {
    title = 'Please wait for the current comment generation to finish.';
  }
  if (loading) {
    title = 'Generating comment using AI... (this may take a few seconds)';
  }
  return (
    <button
      type="button"
      onClick={generateComment}
      disabled={isCommentGenerationLimitReached || disabled || loading}
      title={title}
    >
      <GenerateTextIcon />
    </button>
  );
};

export default GenerateCommentButton;
