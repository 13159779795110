import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandableFilterPanel, {
  ExpandableFilterPanelItem,
} from 'components/molecules/expandable-filter-panel';
import AgencySelectBox from 'components/molecules/agency-select-box';
import ServiceDeliveryThresholdSelectBox from 'components/molecules/service-delivery-threshold-select-box';
import BaseInput from 'components/atoms/base-input';
import ParentClinicSelectBox from 'components/molecules/parent-clinic-select-box';
import { debounce } from 'lodash';
import useKey from 'infrastructure/hooks/utils/use-key';

import s from './styles.module.scss';
import ClinicStatusSelectBox from '../status-select-box';

import type { IClinicListFilters } from 'infrastructure/interfaces';

interface IProps {
  data: IClinicListFilters;
  updateFilter: (data: IClinicListFilters) => void;
  resetFilter: () => void;
  count: number;
}

const ExpandableClinicsFilters: React.FC<IProps> = (props) => {
  const { data, count, updateFilter, resetFilter } = props;

  const { t } = useTranslation();
  const { key, changeKey } = useKey();

  const debouncedUpdateFilter = useMemo(
    () =>
      debounce((filters: IClinicListFilters) => {
        updateFilter(filters);
      }, 500),
    [updateFilter],
  );

  const handleReset = () => {
    if (resetFilter) resetFilter();
    setTimeout(changeKey, 200);
  };

  return (
    <ExpandableFilterPanel
      filtersCount={count}
      onReset={handleReset}
      openWithValue
      isExpanded
    >
      <div className={s.form}>
        <ExpandableFilterPanelItem label={t('labels.clinic')}>
          <BaseInput
            key={key}
            placeholder={t('labels.legalName')}
            allowClear
            defaultValue={data?.legalname}
            onChange={(legalname) => {
              debouncedUpdateFilter({ legalname, page: undefined });
            }}
          />
        </ExpandableFilterPanelItem>
        <ExpandableFilterPanelItem label={t('labels.parentClinicFilter')}>
          <ParentClinicSelectBox
            allowClear
            value={data?.parentClinicGuid}
            onChange={(parentClinicGuid) => {
              debouncedUpdateFilter({ parentClinicGuid, page: undefined });
            }}
          />
        </ExpandableFilterPanelItem>
        <ExpandableFilterPanelItem label={t('labels.agency')}>
          <AgencySelectBox
            placeholder={t('labels.legalName')}
            allowClear
            value={data?.agencyGuid}
            onChange={(agencyGuid) => {
              debouncedUpdateFilter({ agencyGuid, page: undefined });
            }}
          />
        </ExpandableFilterPanelItem>
        <ExpandableFilterPanelItem label={t('labels.clinicStatus')}>
          <ClinicStatusSelectBox
            withAll
            value={data?.status}
            onChange={(status) => {
              debouncedUpdateFilter({ status });
            }}
          />
        </ExpandableFilterPanelItem>
        <ExpandableFilterPanelItem label={t('labels.slaStatus')}>
          <ServiceDeliveryThresholdSelectBox
            withAll
            value={data?.serviceDeliveryThresholds ?? 'All'}
            onChange={(serviceDeliveryThresholds) => {
              debouncedUpdateFilter({
                serviceDeliveryThresholds,
                page: undefined,
              });
            }}
          />
        </ExpandableFilterPanelItem>
      </div>
    </ExpandableFilterPanel>
  );
};

export default ExpandableClinicsFilters;
