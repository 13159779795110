import React from 'react';
import PropTypes from 'prop-types';

const TrashDeleteIcon = ({ stroke }) => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector (Stroke)"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.77778 1.6C8.20733 1.6 8.55556 1.95817 8.55556 2.4V3.2H5.44444V2.4C5.44444 1.95816 5.79265 1.6 6.22222 1.6H7.77778ZM3.88889 3.2V2.4C3.88889 1.07452 4.93353 0 6.22222 0H7.77778C9.06644 0 10.1111 1.07452 10.1111 2.4V3.2H13.2222C13.6518 3.2 14 3.55817 14 4C14 4.44183 13.6518 4.8 13.2222 4.8H12.4444V12.8C12.4444 14.5674 11.0515 16 9.33333 16H4.66667C2.94841 16 1.55556 14.5673 1.55556 12.8V4.8H0.777778C0.348223 4.8 0 4.44183 0 4C0 3.55817 0.348223 3.2 0.777778 3.2H3.88889ZM3.11111 4.8H10.8889V12.8C10.8889 13.6837 10.1924 14.4 9.33333 14.4H4.66667C3.80752 14.4 3.11111 13.6837 3.11111 12.8V4.8ZM8.55556 6.4C8.126 6.4 7.77778 6.75817 7.77778 7.2V12C7.77778 12.4418 8.126 12.8 8.55556 12.8C8.98511 12.8 9.33333 12.4418 9.33333 12V7.2C9.33333 6.75817 8.98511 6.4 8.55556 6.4ZM5.44444 6.4C5.01489 6.4 4.66667 6.75817 4.66667 7.2V12C4.66667 12.4418 5.01489 12.8 5.44444 12.8C5.874 12.8 6.22222 12.4418 6.22222 12V7.2C6.22222 6.75817 5.874 6.4 5.44444 6.4Z"
      fill={stroke ? '#FB9318' : '#CCCAD7'}
    />
  </svg>
);

TrashDeleteIcon.propTypes = {
  stroke: PropTypes.bool,
};

export default TrashDeleteIcon;
