import { useQuery } from '@tanstack/react-query';
import QueryParams from 'utils/queryParams';
import { API_BILLING_CLAIM_EPIC_PROCESS_ITEMS_LIST } from 'services/dataService/resources';
import dataService from 'services/dataService';

import type { EpicProcessesData, Filters } from './types';

export const useEpicData = (filters: Filters) => {
  const queryKey = ['epicProcessesDashboard', filters] as const;

  const { data, error, isLoading, isPreviousData, refetch } = useQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: () => getItems(filters),
  });

  return {
    data: {
      data: data?.items,
      count: data?.count,
    },
    refetch,
    isLoading: isLoading || isPreviousData,
    error,
  };
};

const getItems = async (filters: Filters) => {
  const url = QueryParams.stringifyUrl(
    API_BILLING_CLAIM_EPIC_PROCESS_ITEMS_LIST,
    filters,
  );
  const { error, data } = await dataService.getList<EpicProcessesData>(url);
  if (error) {
    throw new Error(error);
  }
  return data;
};
