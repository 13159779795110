const BOM_SIGNATURES = [
  {
    signature: [0xef, 0xbb, 0xbf],
    encoding: 'utf-8',
    length: 3,
  },
  {
    signature: [0xff, 0xfe],
    encoding: 'utf-16le',
    length: 2,
  },
  {
    signature: [0xfe, 0xff],
    encoding: 'utf-16be',
    length: 2,
  },
  {
    signature: [0xff, 0xfe, 0x00, 0x00],
    encoding: 'utf-32le',
    length: 4,
  },
  {
    signature: [0x00, 0x00, 0xfe, 0xff],
    encoding: 'utf-32be',
    length: 4,
  },
];

const detectBOMEncoding = (uint8Array: Uint8Array) => {
  for (const bomInfo of BOM_SIGNATURES) {
    const match = bomInfo.signature.every(
      (byte, index) => uint8Array[index] === byte,
    );

    if (match) {
      return {
        encoding: bomInfo.encoding,
        bomLength: bomInfo.length,
      };
    }
  }

  return {
    encoding: 'utf-8',
    bomLength: 0,
  };
};

export const decodeAndStripBOM = async (file: File): Promise<string> => {
  const arrayBuffer = await file.arrayBuffer();
  const uint8Array = new Uint8Array(arrayBuffer);

  const { encoding, bomLength } = detectBOMEncoding(uint8Array);

  // Create a new view without the BOM bytes
  const fileContentBytes = uint8Array.slice(bomLength);
  const decoder = new TextDecoder(encoding);
  return decoder.decode(fileContentBytes);
};
