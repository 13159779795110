import BaseCheckbox from 'components/atoms/base-checkbox';
import ButtonLoadingIcon from 'components/atoms/icons/buttonLoadingIcon';
import { useState, type FC } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { type TAddCommentFn } from 'infrastructure/hooks/rpm';

import s from './styles.module.scss';

interface ReviewedCheckboxProps {
  addComment: TAddCommentFn;
  idx: number;
  deviceId: string;
  readingId: string;
  currentInteractionStatus?: boolean;
  isReviewed: boolean;
  submitCommentLockByAi: boolean;
}

const ReviewedCheckbox: FC<ReviewedCheckboxProps> = (props) => {
  const {
    addComment,
    idx,
    deviceId,
    readingId,
    currentInteractionStatus,
    isReviewed,
    submitCommentLockByAi,
  } = props;
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const actions = useAppSelector((state) => state.activityActions.actions[idx]);
  const {
    activityActions: { updateAction, resetCurrentActions },
  } = useAppDispatch();

  const disabled =
    loading ||
    actions?.sending ||
    checked ||
    isReviewed ||
    submitCommentLockByAi;

  const handleChange = async (reviewed: boolean) => {
    setChecked(reviewed);
    setLoading(true);
    updateAction({ idx, action: { sending: true } });

    if (actions?.message) {
      await addComment(
        {
          deviceId,
          readingId,
          message: actions.message,
          currentInteractionStatus,
        },
        {
          async onSuccess() {
            resetCurrentActions(idx);
            await addComment(
              {
                deviceId,
                readingId,
                reviewed,
                currentInteractionStatus,
              },
              {
                onSettled() {
                  setLoading(false);
                },
              },
            );
          },
          onError() {
            updateAction({ idx, action: { sending: false } });
            setLoading(false);
            setChecked(false);
          },
        },
      );
    } else {
      await addComment(
        {
          deviceId,
          readingId,
          reviewed,
          currentInteractionStatus,
        },
        {
          onSettled() {
            setLoading(false);
            resetCurrentActions(idx);
          },
        },
      );
    }
  };

  return loading ? (
    <ButtonLoadingIcon className={s['spin-loading-icon']} />
  ) : (
    <BaseCheckbox
      dataCy="review-reading-checkbox"
      onChange={handleChange}
      disabled={disabled}
      value={isReviewed || checked}
      style={{ transform: 'scale(1.4)' }}
      defaultChecked={checked}
    />
  );
};
export default ReviewedCheckbox;
