import React from 'react';

import type { IBaseComponentProps } from 'infrastructure/interfaces';

const DragAndDropIcon: React.FC<IBaseComponentProps> = ({ className }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.491 20.9197L41.4816 20.8822L36.2222 7.51816C35.9879 6.76348 35.2894 6.24316 34.4972 6.24316H13.1785C12.3816 6.24316 11.6738 6.77285 11.4488 7.53691L6.5316 20.765L6.51754 20.7979L6.50816 20.8354C6.44723 21.065 6.42848 21.2994 6.46129 21.5291C6.4566 21.6041 6.45191 21.6791 6.45191 21.7541V38.9057C6.45315 39.6611 6.75382 40.3853 7.28803 40.9195C7.82224 41.4538 8.54643 41.7544 9.30191 41.7557H38.7019C40.2722 41.7557 41.5519 40.476 41.5566 38.9057V21.7541C41.5566 21.6932 41.5566 21.6322 41.5519 21.5807C41.5707 21.351 41.5519 21.1307 41.491 20.9197ZM27.6254 18.9041L27.6113 19.64C27.5738 21.7447 26.1207 23.1604 23.9972 23.1604C22.9613 23.1604 22.0707 22.8275 21.4285 22.1947C20.7863 21.5619 20.4347 20.6807 20.416 19.64L20.4019 18.9041H10.755L14.4816 9.84316H33.1941L37.0238 18.9041H27.6254ZM10.0472 22.5041H17.4207C18.5597 25.1807 20.9832 26.7604 24.0019 26.7604C25.5816 26.7604 27.0488 26.3197 28.2347 25.4853C29.2754 24.7541 30.0863 23.7322 30.6113 22.5041H37.9472V38.1557H10.0472V22.5041Z"
      fill="#FB9318"
    />
  </svg>
);

export default DragAndDropIcon;
