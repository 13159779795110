import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import CloseIcon from 'components/atoms/icons/closeIcon';
import BaseButton from 'components/atoms/baseButton';
import common from 'constants/common';

interface IModalPopupProps {
  title: string;
  children: React.ReactNode;
  isOpen?: boolean;
  loading?: boolean;
  titleCentered?: boolean;
  className?: string;
  toggle: (value: boolean) => void;
}

const ModalPopup: React.FC<IModalPopupProps> = (props) => {
  const {
    title,
    toggle,
    children,
    titleCentered,
    loading,
    isOpen = false,
    ...rest
  } = props;

  const modalToggle = () => {
    if (loading) return;
    toggle(!isOpen);
  };

  const closeButton = (
    <BaseButton
      className="close"
      disabled={loading}
      onClick={modalToggle}
      icon={
        <CloseIcon fill={loading ? common.modalCloseIconColor : undefined} />
      }
      type="secondary"
    />
  );

  const centered = (
    <div className="modal-header modal-header-centered">
      <h5 className="modal-title">{title}</h5>
      <div className="close-button__wrapper">{closeButton}</div>
    </div>
  );

  return (
    <Modal isOpen={isOpen} toggle={modalToggle} {...rest}>
      {titleCentered ? (
        centered
      ) : (
        <ModalHeader toggle={modalToggle} close={closeButton}>
          {title}
        </ModalHeader>
      )}
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

export default ModalPopup;
