import React, { useMemo } from 'react';
import classNames from 'classnames';

import s from './styles.module.scss';
import BaseTitledCard from '../base-titled-card';

import type { ITableCardItem } from './types';

interface IColRow {
  col: number;
  row: number;
}

const isArray = (val: any) => Array.isArray(val);

const isLastInColumn = (
  index: number,
  totalElements: number,
  { col, row }: IColRow,
): boolean => {
  const columnIndex = Math.floor(index / row);
  const elementsInColumn =
    columnIndex < col - 1 ? row : totalElements % row || row;
  const lastInColumnIndex = columnIndex * row + (elementsInColumn - 1);
  return index === lastInColumnIndex;
};

export const buildTableCardItems = (
  items: ITableCardItem[] | ITableCardItem[][],
): ITableCardItem[] | ITableCardItem[][] => items;

interface IProps {
  title: string;
  items: ITableCardItem[] | ITableCardItem[][];
  columns?: number | number[];
  rows?: number | number[];
  controls?: React.ReactNode;
}

const BaseTableCard: React.FC<IProps> = ({
  title,
  items,
  controls,
  columns = 1,
  rows,
}) => {
  const isSingleType = useMemo(
    () => items.length > 0 && !Array.isArray(items[0]),
    [items],
  );

  const preparedItems = useMemo(
    () =>
      isSingleType
        ? [items as ITableCardItem[]]
        : (items as ITableCardItem[][]),
    [items, isSingleType],
  );

  const preparedColumns = useMemo(
    () => (isArray(columns) ? columns : [columns]),
    [columns, isSingleType],
  );

  const preparedRows = useMemo(
    () => (isArray(rows) ? rows : [rows]),
    [rows, isSingleType],
  );

  const getListColRow = (listLength: number, listIndex: number): IColRow => {
    const col = preparedColumns[listIndex] ?? preparedColumns[0];
    const row =
      preparedRows[listIndex] ??
      (preparedRows[0] || Math.ceil(listLength / col));

    return {
      col,
      row,
    };
  };

  const gridStyles = useMemo(
    () => (listLength: number, listIndex: number) => {
      const { col, row } = getListColRow(listLength, listIndex);
      return {
        gridTemplateColumns: `repeat(${col}, 1fr)`,
        gridTemplateRows: `repeat(${row}, auto)`,
        gridAutoFlow: 'column',
        gridAutoRows: 'auto',
      };
    },
    [preparedColumns, preparedRows],
  );

  const renderValue = (value: any) => {
    if (typeof value === 'boolean') return String(value);
    return value || ' - ';
  };

  return (
    <BaseTitledCard title={title} controls={controls}>
      <div>
        {preparedItems.map((list, listIndex) => (
          <ul
            key={listIndex}
            style={gridStyles(list.length, listIndex)}
            className={s.list}
          >
            {list.map((item, index) => (
              <li
                key={index}
                className={classNames(s.item, {
                  [s.multiply]: item.multiply,
                  [s.origin]: item.originView,
                  [s.last]: isLastInColumn(
                    index,
                    list.length,
                    getListColRow(list.length, listIndex),
                  ),
                })}
              >
                <span className={s.name}>{item.label}</span>
                <span className={s.value} data-cy={`clinicInfo-${item.label}`}>
                  {item.originView && item.value ? (
                    <span
                      className="ql-editor ql-index-1"
                      dangerouslySetInnerHTML={{ __html: item.value }}
                    />
                  ) : (
                    renderValue(item.value)
                  )}
                  {item.errorMessage && (
                    <span className={s.error}>*{item.errorMessage}</span>
                  )}
                </span>
              </li>
            ))}
          </ul>
        ))}
      </div>
    </BaseTitledCard>
  );
};

export default BaseTableCard;
