import { useQueryClient } from '@tanstack/react-query';

import useApi from '../use-api';
import { getAgencyInfoKey } from './use-agency-info';

import type { IAgencyInfo, IEditAgencySchema } from 'infrastructure/interfaces';

const apiUrl = 'admins/admin-panel/agencies';

type UseEditAgency = {
  loading: boolean;
  editAgency: (data: IEditAgencySchema) => Promise<IAgencyInfo>;
};

export const useEditAgency = (): UseEditAgency => {
  const { loading, updateData } = useApi();

  const queryClient = useQueryClient();

  const editAgency = async (agency: IEditAgencySchema) => {
    const data = await updateData<IAgencyInfo>(apiUrl, agency).then((res) => {
      queryClient.removeQueries({
        queryKey: getAgencyInfoKey(agency.guid),
      });

      return res;
    });

    return data;
  };

  return { loading, editAgency };
};
