import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandableFilterPanel, {
  ExpandableFilterPanelItem,
} from 'components/molecules/expandable-filter-panel';
import BaseInput from 'components/atoms/base-input';
import { debounce } from 'lodash';
import useKey from 'infrastructure/hooks/utils/use-key';

import type { IAgencyListFilters } from 'infrastructure/interfaces';

interface IProps {
  data: IAgencyListFilters;
  updateFilter: (data: IAgencyListFilters) => void;
  resetFilter: () => void;
  count: number;
}

const ExpandableAgencyFilters: React.FC<IProps> = (props) => {
  const { data, count, updateFilter, resetFilter } = props;

  const { t } = useTranslation();
  const { key, changeKey } = useKey();

  const debouncedUpdateFilter = useMemo(
    () =>
      debounce((filters: IAgencyListFilters) => {
        updateFilter(filters);
      }, 500),
    [updateFilter],
  );

  const handleReset = () => {
    if (resetFilter) resetFilter();
    setTimeout(changeKey, 200);
  };

  return (
    <ExpandableFilterPanel
      filtersCount={count}
      onReset={handleReset}
      openWithValue
      isExpanded
    >
      <ExpandableFilterPanelItem label={t('labels.hhaName')}>
        <BaseInput
          key={key}
          placeholder={t('labels.legalName')}
          allowClear
          defaultValue={data?.legalname}
          onChange={(legalname) =>
            debouncedUpdateFilter({ legalname, page: undefined })
          }
        />
      </ExpandableFilterPanelItem>
    </ExpandableFilterPanel>
  );
};

export default ExpandableAgencyFilters;
