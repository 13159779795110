import BillingClaimsAthenaHeader from 'components/templates/billingClaimsAthena/header';
import BillingClaimsAthenaTable from 'components/templates/billingClaimsAthena/table';
import { useBillingClaimsAthenaData } from 'infrastructure/hooks/billingClaimsAthena/useData';
import { useBillingClaimsAthenaFilters } from 'infrastructure/hooks/billingClaimsAthena/useFilters';

const BillingClaimsAthenaPage = () => {
  const { changePage, filters } = useBillingClaimsAthenaFilters();
  const { data, isLoading, refetch } = useBillingClaimsAthenaData(filters);

  return (
    <div>
      <BillingClaimsAthenaHeader onUpload={() => refetch()} />
      <BillingClaimsAthenaTable
        changePage={changePage}
        filters={filters}
        count={data?.count}
        data={data?.data}
        isLoading={isLoading}
      />
    </div>
  );
};

export default BillingClaimsAthenaPage;
