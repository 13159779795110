import i18next from 'i18next';

import s from './styles.module.scss';

import type { ThresholdLog } from 'infrastructure/classes/logs/threshold-log';
import type { IBaseTableColumns } from 'components/atoms/base-table/types';

export const thresholdLogsColumns = (): IBaseTableColumns<ThresholdLog> => {
  return [
    {
      label: i18next.t('labels.dateTime'),
      key: 'date',
      width: '170px',
      render(value) {
        return (
          <span className={s.date} data-cy="logs-dateChanged">
            {value}
          </span>
        );
      },
    },
    {
      label: i18next.t('labels.statusChanging'),
      key: 'from',
      width: '600px',
      responsive: ['md'],
      render(_, record) {
        return record.changes.map(({ field, from, to }, index) => (
          <div key={index} className={s.change}>
            <span className={s.date}>{field}</span>
            <div className={s.info}>
              <span className={s.from}>
                {`${i18next.t('labels.from')}: ${from}`}
              </span>
              &#10095;
              <span className={s.to}>{`${i18next.t('labels.to')}: ${to}`}</span>
            </div>
          </div>
        ));
      },
    },
    {
      label: i18next.t('labels.electronicallySigned'),
      align: 'right',
      key: 'author',
      width: '130px',
      render(_, record) {
        return (
          <span className={s.who} data-cy="logs-electronicallySigned">
            {`${record.author} - ${record.professionType}`}
          </span>
        );
      },
    },
  ];
};
