import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';

export const agencyDetailValidationSchema = () =>
  createValidationSchema({
    npiNumber: commonValidationSchemas.string({ nullable: true }),
    taxIdNumber: commonValidationSchemas.string({ nullable: true }),
    medicalIdTpiNumber: commonValidationSchemas.string({ nullable: true }),
    stateIdNumber: commonValidationSchemas.string({ nullable: true }),
    taxonomyNumber: commonValidationSchemas.string({ nullable: true }),
    medicareNumber: commonValidationSchemas.string({ nullable: true }),
  });
