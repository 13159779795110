import useUser from 'utils/useUser';
import { useMemo, type FC } from 'react';
import {
  useAddActivityComment,
  useGenAiComment,
} from 'infrastructure/hooks/rpm';
import { useAppSelector } from 'store';

import CommentsColumn from './components/comments-col';
import CommentColumn from './components/comment-col';
import ReviewedCheckbox from './components/reviewed-checkbox';
import s from './styles.module.scss';

import type { QueryKey } from '@tanstack/react-query';
import type { IActivityData } from 'infrastructure/interfaces/i-rpm-patient-activity';

interface CommentCellProps {
  createdAt: string;
  record: IActivityData;
  idx: number;
  showReviewCol: boolean;
  getActivityQueryKey: QueryKey;
}

const CommentCell: FC<CommentCellProps> = ({
  idx,
  record,
  showReviewCol,
  getActivityQueryKey,
}) => {
  const { userType, hasClinicalRole } = useUser();

  const isReviewed = useMemo(() => {
    const reviewedByUserType = record.comments.find(
      (c) => c.reviewed,
    )?.userType;
    return reviewedByUserType === userType && record.reviewed;
  }, [record.comments.length]);
  const actions = useAppSelector((state) => state.activityActions.actions[idx]);

  const { addActivityComment, InteractionStatusAlert } = useAddActivityComment({
    getActivityQueryKey,
    patientGuid: record.patient.guid,
    tableRowIdx: idx,
  });

  const {
    data: aiData,
    genAiComment,
    isCommentGenerationLimitReached,
    isLoading,
  } = useGenAiComment({
    patientGuid: record.patient.guid,
    tableRowIdx: idx,
  });
  const submitCommentLockByAi =
    Boolean(actions?.message) &&
    aiData?.result.comment === actions?.message &&
    hasClinicalRole;

  return (
    <>
      <div className={s['comments-cell']}>
        <div className={s['comments-wrapper']}>
          <CommentsColumn
            comments={record.comments}
            patientGuid={record.patient.guid}
          />
          <CommentColumn
            addComment={addActivityComment}
            genAiComment={genAiComment}
            deviceId={record.deviceId}
            readingId={record.id}
            idx={idx}
            key={`comment-col-${idx}`}
            createdAt={record.createdAt}
            hideAiButton={showReviewCol}
            currentInteractionStatus={record.patient.interactionStatus}
            patientGuid={record.patient.guid}
            genAiCommentLoading={isLoading}
            isCommentGenerationLimitReached={isCommentGenerationLimitReached}
            aiData={aiData}
          />
          {showReviewCol && (
            <div className={s.reviewed}>
              <ReviewedCheckbox
                key={record.id + record.deviceId}
                addComment={addActivityComment}
                deviceId={record.deviceId}
                readingId={record.id}
                idx={idx}
                currentInteractionStatus={record.patient.interactionStatus}
                isReviewed={isReviewed}
                submitCommentLockByAi={submitCommentLockByAi}
              />
            </div>
          )}
        </div>
      </div>
      {InteractionStatusAlert}
    </>
  );
};
export default CommentCell;
