import { useEffect, useState } from 'react';
import { applyFields } from 'infrastructure/functions';
import {
  parseSearchNumberValue,
  parseSearchStringValue,
} from 'infrastructure/functions/parse-query-string';

import { useQueryParams } from '../common/use-query-param';
import { useQueryFilterNew } from '../common/use-query-param-new';

import type { TParseFunctions } from '../common/use-query-param-new';
import type { IAgencyListFilters } from 'infrastructure/interfaces';

type AgencyFilterKey = {
  agency: IAgencyListFilters;
};

export const defaultValues: IAgencyListFilters = {
  page: 1,
  legalname: '',
};

const parseFunctions: TParseFunctions<IAgencyListFilters> = {
  page: parseSearchNumberValue,
  legalname: parseSearchStringValue,
};

interface IUseAgencyQueryFilter {
  count: number;
  filters: IAgencyListFilters;
  setFilters: (query: IAgencyListFilters | null) => void;
  getFilters: () => IAgencyListFilters;
  resetFilters: () => void;
  updateFilters: (query: IAgencyListFilters | null) => void;
}

export const useAgencyQueryFilter = (): IUseAgencyQueryFilter => {
  const { setQuery, getQueryByName, resetQuery } = useQueryParams();

  const {
    count,
    filter,
    resetFilter: resetFilters,
    updateFilter: updateFilters,
  } = useQueryFilterNew<IAgencyListFilters>(defaultValues, parseFunctions, [
    'page',
  ]);

  const [filters, setQueryFilters] =
    useState<IAgencyListFilters>(defaultValues);

  const setFilters = (query: IAgencyListFilters | null) => {
    if (query === null) {
      resetQuery();
      setQueryFilters(defaultValues);
      return;
    }
    const withoutEmptyValues = applyFields<IAgencyListFilters>({}, query);
    const preparedQuery: AgencyFilterKey = {
      agency: withoutEmptyValues,
    };

    setQuery(preparedQuery);
    setQueryFilters(withoutEmptyValues);
  };

  const getFilters = (): IAgencyListFilters => {
    const query = getQueryByName<IAgencyListFilters>('agency');
    if (query) return query;
    return defaultValues;
  };

  useEffect(() => {
    const query = getFilters();
    setQueryFilters(query);
  }, []);

  return {
    count,
    filters: { ...filters, ...filter },
    setFilters,
    getFilters,
    resetFilters,
    updateFilters: updateFilters as any,
  };
};
