import type { SortParam, SortState } from 'infrastructure/types';
import type { SorterField } from '../interfaces/i-modified-sorter-result';

export const createSortOrder = (sorterField: SorterField): SortParam => {
  let sortType: SortState = 'asc';

  if (!Array.isArray(sorterField)) {
    if (sorterField?.order === 'descend') sortType = 'desc';
    if (sorterField?.order === 'ascend') sortType = 'asc';
    if (sorterField?.order === undefined) sortType = '';
    return [
      `${sorterField?.column?.sortKey ?? sorterField?.columnKey}`,
      sortType,
    ];
  }

  return ['', ''];
};
