import ActivityIcon from 'components/atoms/icons/activityIcon';
import { useTranslation } from 'react-i18next';
import ActivityTable from 'components/molecules/activity-table';
import BaseSelectBox from 'components/atoms/base-select-box';
import { memo, type FC } from 'react';
import useUser from 'utils/useUser';

import s from './styles.module.scss';
import { usePatientActivity } from './hooks/use-patient-activity';

interface PatientActivityProps {
  patientGuid: string;
}
const PatientActivity: FC<PatientActivityProps> = ({ patientGuid }) => {
  const { t } = useTranslation();
  const {
    activity,
    queryKey,
    UnsavedCommentAlert,
    isLoading,
    OPTIONS,
    status,
    page,
    setPage,
    handleChangeType,
    onSort,
  } = usePatientActivity(patientGuid);
  const { isMobileDevice, isPatient } = useUser();

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <h4 className={s.title}>
          <ActivityIcon />
          {t('labels.activity')}
        </h4>
        {!isPatient && (
          <div className={s['header-select']}>
            {isMobileDevice ? (
              <p>{t('labels.type')}:</p>
            ) : (
              <p>{t('labels.activitiesType')}:</p>
            )}
            <BaseSelectBox
              value={status}
              style={{ width: isMobileDevice ? 120 : 160 }}
              onChange={handleChangeType}
              options={OPTIONS}
            />
          </div>
        )}
      </div>
      {!isMobileDevice && <div className={s.divider} />}
      <div className={s.body}>
        <ActivityTable
          activity={activity}
          loading={isLoading}
          page={page}
          setPage={setPage}
          itemsQueryKey={queryKey}
          showReviewCol={!status.value}
          view="patient"
          onSort={onSort}
        />
      </div>
      {UnsavedCommentAlert}
    </div>
  );
};
export default memo(
  PatientActivity,
  (prev, next) => prev.patientGuid === next.patientGuid,
);
