import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';
import ResetPasswordLinkPage from 'pages/public/resetPasswordLink';
import path, { publicPaths } from 'constants/paths';
import EmailUnsubscribePage from 'pages/public/emailSubscription';
import EmailUnsubscribedPage from 'pages/public/emailUnsubscribed';
import SSOLogin from 'pages/sso/login';
import { AccessRouteComponent } from 'components/atoms/routes/accessRoute';
import PatientPage from 'pages/rpm/patients/patient';
import DashboardPage from 'pages/rpm/dashboard';
import PatientDetails from 'pages/rpm/patients/patientDetails';
import ActivityReport from 'pages/rpm/practice/activityReport';
import AdherenceReport from 'pages/rpm/practice/adherenceReport';
import AdherenceLast30DaysReport from 'pages/rpm/practice/adherenceReport/last30DaysAdherence';
import ComplianceReport from 'pages/rpm/practice/complianceReport';
import ClinicalMetrics from 'pages/rpm/practice/clinicalMetrics';
import OnboardingStatus from 'pages/rpm/practice/onboardingStatus';
import MedicareBilling from 'pages/rpm/practice/medicareBilling';
import Profile from 'pages/profile';
import AdminPatients from 'pages/admin/patients';
import AdminPatientAdd from 'pages/admin/patientAdd';
import AdminUsers from 'pages/admin/users';
import AdminUserAdd from 'pages/admin/userAdd';
import AdminUser from 'pages/admin/user';
import AdminAgencyDoctorList from 'pages/admin/hhaDoctors';
import AdminAgencyDoctorInfo from 'pages/admin/hhaDoctor';
import AdminAgencyDoctorAdd from 'pages/admin/hhaDoctorAdd';
import AdminAgencyDoctorEdit from 'pages/admin/hhaDoctorEdit';
import AdminUserEdit from 'pages/admin/userEdit';
import AdminClinics from 'pages/admin/clinics';
import AdminAgencies from 'pages/admin/agencies';
import AdminAgencyViewPage from 'pages/admin/agency';
import AdminAgencyEdit from 'pages/admin/agencyEdit';
import AdminAgencyAdd from 'pages/admin/agencyAdd';
import AdminPatientDetailsPage from 'pages/admin/patient/index';
import TurkConfigurationPage from 'pages/admin/turkConfiguration';
import TurkUserAddPage from 'pages/admin/turkUserAdd';
import AdminTurkUsersPage from 'pages/admin/turkUsers';
import AdminTurkUserInfoPage from 'pages/admin/turkUser';
import TurkUserEditPage from 'pages/admin/turkUserEdit';
import CCMPatientsActivities from 'pages/ccm/patientsActivities';
import CCMBillingReport from 'pages/ccm/practice/billingReport';
import CCMBillingReportV2 from 'pages/ccm/practice/billingReportV2';
import CCMInteractiveTimeReport from 'pages/ccm/practice/ccmInteractiveReport';
import CCMPatientDetails from 'pages/ccm/patientDetails';
import CCMConsentForm from 'pages/ccm/consentForm';
import CCMStatusReport from 'pages/ccm/practice/statusReport';
import PatientsStatusReport from 'pages/rpm/practice/patientsStatusReport';
import StatisticsReport from 'pages/rpm/practice/statisticsReport';
import PerformanceReview from 'pages/rpm/practice/performanceReview';
import InteractionStatus from 'pages/rpm/practice/interactionStatus';
import SmsDashboard from 'pages/smsDashboard';
import SSO from 'pages/sso';
import NotFound from 'components/atoms/notFound';
import CcmOnboardingStatus from 'pages/ccm/practice/ccmOnboardingStatus';
import TurkDashboardPage from 'pages/turkDashboard';
import InteractiveTimeDashboardPage from 'pages/rpm/interactiveTimeDashboard';
import InteractiveTimeReportV2 from 'pages/rpm/practice/interactiveReportV2';
import AdminClinicAddPage from 'pages/admin/clinicAdd';
import AdminClinicEditPage from 'pages/admin/clinicEdit';
import AdminClinicViewPage from 'pages/admin/clinic';
import CcmAgentDashboardPage from 'pages/ccm/dashboards/ccmAgentDashboard';
import MainAppLayout from 'pages/app/layout';
import { ProtectedComponent } from 'components/atoms/routes/privateRoute';
import EligibilityPage from 'pages/tools/eligibility';
import EligibilityItemsPage from 'pages/tools/eligibility/items';
import AuthProviderLayout from 'components/organisms/authProvider/layout';
import EpicClaimsPage from 'pages/billingTools/claims/epic';
import BillingClaimsAthenaPage from 'pages/billingTools/claims/athena';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AuthProviderLayout />}>
      <Route
        path={publicPaths.emailSubscription}
        element={<EmailUnsubscribePage />}
      />
      <Route
        path={publicPaths.emailUnsubscribed}
        element={<EmailUnsubscribedPage />}
      />
      <Route path={publicPaths.ssoLogin} element={<SSOLogin />} />
      <Route
        path={path.dashboard}
        element={<ProtectedComponent component={MainAppLayout} />}
      >
        <Route
          index
          path={path.dashboard}
          element={
            <AccessRouteComponent
              component={DashboardPage}
              path={path.dashboard}
            />
          }
        />
        <Route
          path={path.patientDetails}
          element={
            <AccessRouteComponent
              component={PatientDetails}
              path={path.patientDetails}
            />
          }
        />
        <Route
          path={path.medicareBilling}
          element={
            <AccessRouteComponent
              component={MedicareBilling}
              path={path.medicareBilling}
            />
          }
        />
        <Route
          path={path.patientGuid}
          element={
            <AccessRouteComponent
              component={PatientPage}
              path={path.patientGuid}
            />
          }
        />
        <Route
          path={path.activityReport}
          element={
            <AccessRouteComponent
              component={ActivityReport}
              path={path.activityReport}
            />
          }
        />
        <Route
          path={path.interactiveReport}
          element={
            <AccessRouteComponent
              component={InteractiveTimeReportV2}
              path={path.interactiveReport}
            />
          }
        />
        <Route
          path={path.adherenceReport}
          element={
            <AccessRouteComponent
              component={AdherenceReport}
              path={path.adherenceReport}
            />
          }
        />
        <Route
          path={path.adherenceReportLast30Days}
          element={
            <AccessRouteComponent
              component={AdherenceLast30DaysReport}
              path={path.adherenceReportLast30Days}
            />
          }
        />
        <Route
          path={path.onboardingStatus}
          element={
            <AccessRouteComponent
              component={OnboardingStatus}
              path={path.onboardingStatus}
            />
          }
        />
        <Route
          path={path.complianceReport}
          element={
            <AccessRouteComponent
              component={ComplianceReport}
              path={path.complianceReport}
            />
          }
        />
        <Route
          path={path.clinicalMetrics}
          element={
            <AccessRouteComponent
              component={ClinicalMetrics}
              path={path.clinicalMetrics}
            />
          }
        />
        <Route
          path={path.adminPatients}
          element={
            <AccessRouteComponent
              component={AdminPatients}
              path={path.adminPatients}
            />
          }
        />
        <Route
          path={path.adminPatientAdd}
          element={
            <AccessRouteComponent
              component={AdminPatientAdd}
              path={path.adminPatientAdd}
            />
          }
        />
        <Route
          path={path.adminPatient}
          element={
            <AccessRouteComponent
              component={AdminPatientDetailsPage}
              path={path.adminPatient}
            />
          }
        />
        <Route
          path={path.adminUsers}
          element={
            <AccessRouteComponent
              component={AdminUsers}
              path={path.adminUsers}
            />
          }
        />
        <Route
          path={path.adminUser}
          element={
            <AccessRouteComponent component={AdminUser} path={path.adminUser} />
          }
        />
        <Route
          path={path.adminAgencyDoctorList}
          element={
            <AccessRouteComponent
              component={AdminAgencyDoctorList}
              path={path.adminAgencyDoctorList}
            />
          }
        />
        <Route
          path={path.adminAgencyDoctor}
          element={
            <AccessRouteComponent
              component={AdminAgencyDoctorInfo}
              path={path.adminAgencyDoctor}
            />
          }
        />
        <Route
          path={path.adminAgencyDoctorAdd}
          element={
            <AccessRouteComponent
              component={AdminAgencyDoctorAdd}
              path={path.adminAgencyDoctorAdd}
            />
          }
        />
        <Route
          path={path.adminAgencyDoctorEdit}
          element={
            <AccessRouteComponent
              component={AdminAgencyDoctorEdit}
              path={path.adminAgencyDoctorEdit}
            />
          }
        />
        <Route
          path={path.adminUserEdit}
          element={
            <AccessRouteComponent
              component={AdminUserEdit}
              path={path.adminUserEdit}
            />
          }
        />
        <Route
          path={path.adminUserAdd}
          element={
            <AccessRouteComponent
              component={AdminUserAdd}
              path={path.adminUserAdd}
            />
          }
        />
        <Route
          path={path.adminClinics}
          element={
            <AccessRouteComponent
              component={AdminClinics}
              path={path.adminClinics}
            />
          }
        />
        <Route
          path={path.adminClinic}
          element={
            <AccessRouteComponent
              component={AdminClinicViewPage}
              path={path.adminClinic}
            />
          }
        />
        <Route
          path={path.adminClinicEdit}
          element={
            <AccessRouteComponent
              component={AdminClinicEditPage}
              path={path.adminClinicEdit}
            />
          }
        />
        <Route
          path={path.adminClinicAdd}
          element={
            <AccessRouteComponent
              component={AdminClinicAddPage}
              path={path.adminClinicAdd}
            />
          }
        />
        <Route
          path={path.adminAgencies}
          element={
            <AccessRouteComponent
              component={AdminAgencies}
              path={path.adminAgencies}
            />
          }
        />
        <Route
          path={path.adminAgency}
          element={
            <AccessRouteComponent
              component={AdminAgencyViewPage}
              path={path.adminAgency}
            />
          }
        />
        <Route
          path={path.adminAgencyEdit}
          element={
            <AccessRouteComponent
              component={AdminAgencyEdit}
              path={path.adminAgencyEdit}
            />
          }
        />
        <Route
          path={path.adminAgencyAdd}
          element={
            <AccessRouteComponent
              component={AdminAgencyAdd}
              path={path.adminAgencyAdd}
            />
          }
        />
        <Route
          path={path.adminTurkConfigurations}
          element={
            <AccessRouteComponent
              component={TurkConfigurationPage}
              path={path.adminTurkConfigurations}
            />
          }
        />
        <Route
          path={path.adminTurkUsersAdd}
          element={
            <AccessRouteComponent
              component={TurkUserAddPage}
              path={path.adminTurkUsersAdd}
            />
          }
        />
        <Route
          path={path.adminTurkUsersEdit}
          element={
            <AccessRouteComponent
              component={TurkUserEditPage}
              path={path.adminTurkUsersEdit}
            />
          }
        />
        <Route
          path={path.adminTurkUserList}
          element={
            <AccessRouteComponent
              component={AdminTurkUsersPage}
              path={path.adminTurkUserList}
            />
          }
        />
        <Route
          path={path.adminTurkUser}
          element={
            <AccessRouteComponent
              component={AdminTurkUserInfoPage}
              path={path.adminTurkUser}
            />
          }
        />
        <Route
          path={path.ccmPatient}
          element={
            <AccessRouteComponent
              component={CCMPatientDetails}
              path={path.ccmPatient}
            />
          }
        />
        <Route
          path={path.ccm}
          element={
            <AccessRouteComponent
              component={CCMPatientsActivities}
              path={path.ccm}
            />
          }
        />
        <Route
          path={path.ccmBillingReport}
          element={
            <AccessRouteComponent
              component={CCMBillingReport}
              path={path.ccmBillingReport}
            />
          }
        />
        <Route
          path={path.ccmBillingReportV2}
          element={
            <AccessRouteComponent
              component={CCMBillingReportV2}
              path={path.ccmBillingReportV2}
            />
          }
        />
        <Route
          path={path.ccmInteractiveTimeReport}
          element={
            <AccessRouteComponent
              component={CCMInteractiveTimeReport}
              path={path.ccmInteractiveTimeReport}
            />
          }
        />
        <Route
          path={path.ccmOnboardingStatus}
          element={
            <AccessRouteComponent
              component={CcmOnboardingStatus}
              path={path.ccmOnboardingStatus}
            />
          }
        />
        <Route
          path={path.ccmConsent}
          element={
            <AccessRouteComponent
              component={CCMConsentForm}
              path={path.ccmConsent}
            />
          }
        />
        <Route
          path={path.patientsStatusReport}
          element={
            <AccessRouteComponent
              component={PatientsStatusReport}
              path={path.patientsStatusReport}
            />
          }
        />
        <Route
          path={path.statisticsReport}
          element={
            <AccessRouteComponent
              component={StatisticsReport}
              path={path.statisticsReport}
            />
          }
        />
        <Route
          path={path.performanceReview}
          element={
            <AccessRouteComponent
              component={PerformanceReview}
              path={path.performanceReview}
            />
          }
        />
        <Route
          path={path.interactionStatus}
          element={
            <AccessRouteComponent
              component={InteractionStatus}
              path={path.interactionStatus}
            />
          }
        />
        <Route
          path={path.smsDashboard}
          element={
            <AccessRouteComponent
              component={SmsDashboard}
              path={path.smsDashboard}
            />
          }
        />
        <Route
          path={path.turkDashboard}
          element={
            <AccessRouteComponent
              component={TurkDashboardPage}
              path={path.turkDashboard}
            />
          }
        />
        <Route
          path={path.ccmStatusReport}
          element={
            <AccessRouteComponent
              component={CCMStatusReport}
              path={path.ccmStatusReport}
            />
          }
        />
        <Route
          path={path.interactiveTimeDashboard}
          element={
            <AccessRouteComponent
              component={InteractiveTimeDashboardPage}
              path={path.interactiveTimeDashboard}
            />
          }
        />
        <Route
          path={path.ccmAgentDashboard}
          element={
            <AccessRouteComponent
              component={CcmAgentDashboardPage}
              path={path.ccmAgentDashboard}
            />
          }
        />
        <Route
          path={path.sso}
          element={<AccessRouteComponent component={SSO} path={path.sso} />}
        />
        <Route
          path={path.profile}
          element={
            <AccessRouteComponent component={Profile} path={path.profile} />
          }
        />
        <Route
          path={path.eligibilityTool}
          element={
            <AccessRouteComponent
              component={EligibilityPage}
              path={path.eligibilityTool}
            />
          }
        />
        <Route
          path={path.eligibilityToolItems}
          element={
            <AccessRouteComponent
              component={EligibilityItemsPage}
              path={path.eligibilityToolItems}
            />
          }
        />
        <Route
          path={path.billingClaimsAthena}
          element={
            <AccessRouteComponent
              component={BillingClaimsAthenaPage}
              path={path.billingClaimsAthena}
            />
          }
        />
        <Route
          path={path.billingClaimsEpic}
          element={
            <AccessRouteComponent
              component={EpicClaimsPage}
              path={path.billingClaimsEpic}
            />
          }
        />
        <Route
          path={path.notFound}
          element={
            <AccessRouteComponent component={NotFound} path={path.notFound} />
          }
        />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path={path.resetPassword} element={<ResetPasswordLinkPage />} />
    </Route>,
  ),
  {
    future: {
      v7_relativeSplatPath: true,
      v7_partialHydration: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_skipActionErrorRevalidation: true,
    },
  },
);
