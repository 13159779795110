import { useState } from 'react';
import BaseButton from 'components/atoms/baseButton';
import { useTranslation } from 'react-i18next';
import RedirectIcon from 'components/atoms/icons/redirect-icon';

import s from './style.module.scss';
import ModalBody from './body';

import type { FC } from 'react';
import type { BillingClaimsEpicResult } from 'infrastructure/hooks/billingTools/epic/types';

interface Props {
  result: BillingClaimsEpicResult | null;
  filename: string;
}

const BillingClaimsResultModal: FC<Props> = ({ result, filename }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const disabled = !result;

  return (
    <>
      <BaseButton
        disabled={disabled}
        label={disabled ? t('labels.inProgress') : t('labels.view')}
        onClick={() => setIsOpen(true)}
        type="secondary"
        width="100%"
        height={26}
        icon={disabled ? null : <RedirectIcon className={s['button-icon']} />}
      />
      {result && (
        <ModalBody
          isOpen={isOpen}
          onCancel={() => setIsOpen(false)}
          result={result}
          filename={filename}
        />
      )}
    </>
  );
};

export default BillingClaimsResultModal;
