import BaseButton from 'components/atoms/baseButton';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useFormik } from 'formik';
import BaseModal from 'components/atoms/base-modal';
import BaseFormItem from 'components/atoms/base-form/item';
import BaseInput from 'components/atoms/base-input';
import BaseForm from 'components/atoms/base-form';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';
import dataService from 'services/dataService';
import { API_ELIGIBILITY_PROCESS_UPDATE_CREDENTIALS } from 'services/dataService/resources';

import s from './style.module.scss';

import type { FormikHelpers } from 'formik';
import type { FC } from 'react';

const schema = yup.object({
  username: yup.string().trim().nullable(),
  password: yup.string().trim().nullable(),
});

type Credential = yup.InferType<typeof schema>;

const EligibilityUpdateCredentials: FC = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (
    values: Credential,
    formikHelpers: FormikHelpers<Credential>,
  ) => {
    setIsLoading(true);
    const { error } = await dataService.updateOnly(
      API_ELIGIBILITY_PROCESS_UPDATE_CREDENTIALS,
      values,
    );
    setIsLoading(false);

    if (error) {
      notificationController.error({
        message: error,
      });
      return;
    }
    formikHelpers.resetForm();
    notificationController.success({
      message: t('messages.eligibilityCredentialsUpdated'),
    });
    setIsOpen(false);
  };

  const formik = useFormik<Credential>({
    initialValues: { password: '', username: '' },
    onSubmit,
    validateOnChange: false,
    validationSchema: schema,
  });

  return (
    <div className={s['upload-button-wrapper']}>
      <BaseButton
        onClick={() => setIsOpen(true)}
        type="primary"
        label={t('labels.updateCredentials')}
        className={s['upload-button']}
      />
      <BaseModal
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        title={t('labels.updateCredentials')}
        withFooter={[
          <BaseButton
            label={t('controls.cancel')}
            type="secondary"
            onClick={() => setIsOpen(false)}
            key={t('controls.cancel')}
            loading={isLoading}
          />,
          <BaseButton
            label={t('controls.save')}
            onClick={() => {
              formik.submitForm();
            }}
            key={t('controls.save')}
            loading={isLoading}
            dataCy="save-button"
            disabled={!formik.values.username && !formik.values.password}
          />,
        ]}
      >
        <div className={s.label}>
          {t('messages.keepCurrentValueUsernameAndPassword')}
        </div>
        <BaseForm loading={isLoading} formik={formik}>
          <BaseFormItem name="username" label={t('labels.username')}>
            <BaseInput />
          </BaseFormItem>
          <BaseFormItem name="password" label={t('labels.password')}>
            <BaseInput />
          </BaseFormItem>
        </BaseForm>
      </BaseModal>
    </div>
  );
};

export default EligibilityUpdateCredentials;
