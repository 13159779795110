import React from 'react';
import { Select } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';

import type { IBaseSelectBoxProps } from './types';

const BaseSelectBox: React.FC<IBaseSelectBoxProps> = (props) => {
  const { t } = useTranslation();

  const {
    value,
    placeholder = t('controls.select'),
    disabled,
    plaintext,
    readonly,
    invalid,
    onChange,
    className,
    style,
    allowClear = false,
    mode,
    maxWidth,
    minWidth,
    defaultValue,
    icon,
    labelRender,
    options = [],
    dataCy = 'base-select-box',
    size,
    onDeselect,
    onClear,
    maxTagTextLength,
  } = props;

  const selectBoxClassNames = classNames(
    s.select,
    {
      [s.readonly]: readonly,
    },
    className,
  );

  return (
    <div data-cy={dataCy}>
      <Select
        style={{
          width: '100%',
          minWidth,
          maxWidth,
          ...style,
        }}
        options={options}
        mode={mode}
        value={defaultValue ? undefined : value ?? null}
        onChange={(val: any, option: any) => {
          if (onChange) onChange(val ?? null, option);
        }}
        disabled={disabled}
        placeholder={placeholder}
        className={selectBoxClassNames}
        variant={plaintext ? 'borderless' : 'outlined'}
        status={invalid ? 'error' : undefined}
        suffixIcon={disabled || readonly ? null : icon}
        allowClear={allowClear}
        labelRender={labelRender}
        defaultValue={defaultValue}
        optionRender={(option) => (
          <div data-cy="select-option">{option.label}</div>
        )}
        size={size}
        onClear={onClear}
        onDeselect={onDeselect}
        optionFilterProp="label"
        maxTagTextLength={maxTagTextLength}
      />
    </div>
  );
};

export const BaseSelectBoxDisplayName = 'BaseSelectBox';
BaseSelectBox.displayName = BaseSelectBoxDisplayName;

export default BaseSelectBox;
