import path from 'constants/paths';
import useUser from 'utils/useUser';
import { Navigate } from 'react-router-dom';
import { isPatient, isTurkUserRole } from 'utils/userTypeHelper';
import {
  PLATFORM_MODE_ADMIN_PANEL_ACCESS,
  PLATFORM_MODE_CCM,
  PLATFORM_MODE_PATIENT_ACCEPTANCE,
} from 'utils/accessManager/constants';

import { useDashboardMode } from './use-dashboard-mode';
import OverviewDashboardPageOld from './old';
import OverviewDashboardPageNew from './new';

const OverviewDashboardPage = () => {
  const { mode, setPreferMode } = useDashboardMode();
  const { userType, guid, platformMode, roleNames } = useUser();

  if (isPatient(userType)) return <Navigate to={`/patient/${guid}`} />;
  if (isTurkUserRole(roleNames)) return <Navigate to={path.turkDashboard} />;
  if (platformMode === PLATFORM_MODE_CCM) return <Navigate to={path.ccm} />;
  if (
    platformMode === PLATFORM_MODE_PATIENT_ACCEPTANCE ||
    platformMode === PLATFORM_MODE_ADMIN_PANEL_ACCESS
  )
    return <Navigate to={path.adminPatients} />;

  if (mode === 'old')
    return <OverviewDashboardPageOld setMode={setPreferMode} />;

  return <OverviewDashboardPageNew setMode={setPreferMode} />;
};

export default OverviewDashboardPage;
