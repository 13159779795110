import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import HeaderNavbar from 'components/molecules/navbar';
import path from 'constants/paths';
import { filterRoutes } from 'utils/accessManager/index';
import { PLATFORM_MODE_RPM } from 'utils/accessManager/constants';
import useUser from 'utils/useUser';
import {
  isAdmin,
  isSmsDashboardOrUnregisteredRole,
} from 'utils/userTypeHelper';
import HeaderNavbarMobileNew from 'components/molecules/navbar/mobile-new';

const patientsOptions = [
  {
    label: 'Overview dashboard',
    link: path.dashboard,
    dataCy: 'nav-patients__dashboard',
    ariaLabel: 'BAA form link',
  },
  {
    label: 'CCM Agent Dashboard',
    link: path.ccmAgentDashboard,
    dataCy: 'nav-patients__ccm-agent-dashboard',
  },
  {
    label: "Patient's details",
    link: path.patientDetails,
    dataCy: 'nav-patients__details',
  },
  {
    label: 'SMS Dashboard',
    link: path.smsDashboard,
    dataCy: 'nav-patients__sms-dashboard',
  },
  {
    label: 'Interactive Time Dashboard',
    link: path.interactiveTimeDashboard,
    dataCy: 'nav-patients__interactive-time-dashboard',
  },
];

const adminPanelOptions = [
  {
    label: 'Agencies',
    link: path.adminAgencies,
    dataCy: 'nav-admin__agencies',
  },
  {
    label: 'Agency Users',
    link: path.adminAgencyDoctorList,
    dataCy: 'nav-admin__agency-users',
  },
  {
    label: 'Clinics',
    link: path.adminClinics,
    dataCy: 'nav-admin__clinics',
  },
  {
    label: 'Patients',
    link: path.adminPatients,
    dataCy: 'nav-admin__patients',
  },
  {
    label: 'Users',
    link: path.adminUsers,
    dataCy: 'nav-admin__users',
  },
  {
    label: 'Turk Users',
    link: path.adminTurkUserList,
    dataCy: 'nav-admin__turk-users',
  },
  {
    label: 'Turk Configuration',
    link: path.adminTurkConfigurations,
    dataCy: 'nav-admin__turk-configuration',
  },
];

const practiceOptions = [
  {
    label: 'Activity',
    link: path.activityReport,
    dataCy: 'nav-practice__activity-report',
  },
  {
    label: 'Adherence',
    link: path.adherenceReport,
    dataCy: 'nav-practice__adherence-report',
  },
  {
    label: 'Adherence 30 Days',
    link: path.adherenceReportLast30Days,
    dataCy: 'nav-practice__adherence-30-days-report',
  },
  {
    label: 'CCM Billing',
    link: path.ccmBillingReport,
    dataCy: 'nav-practice__ccm-billing',
  },
  {
    label: 'CCM Interactive Time',
    link: path.ccmInteractiveTimeReport,
    dataCy: 'nav-practice__ccm-interactive-time-report',
  },
  {
    label: 'CCM Onboarding Status',
    link: path.ccmOnboardingStatus,
    dataCy: 'nav-practice__ccm-onboarding-status',
  },
  {
    label: 'CCM Patient Status',
    link: path.ccmStatusReport,
    dataCy: 'nav-practice__ccb-status-report',
  },
  {
    label: 'Clinical Metrics',
    link: path.clinicalMetrics,
    dataCy: 'nav-practice__clinical-metrics',
  },
  {
    label: 'Daily Reading Compliance',
    link: path.complianceReport,
    dataCy: 'nav-practice__compliance-report',
  },
  {
    label: 'Interactive Time',
    link: path.interactiveTime,
    dataCy: 'nav-practice__interactive-time-report',
  },
  {
    label: 'Medicare Billing',
    link: path.medicareBilling,
    dataCy: 'nav-practice__medicare-billing',
  },
  {
    label: 'Monthly Interaction Status',
    link: path.interactionStatus,
    dataCy: 'nav-practice__monthly-interactive-status',
  },
  {
    label: 'Onboarding Status',
    link: path.onboardingStatus,
    dataCy: 'nav-practice__onboarding-status',
  },
  {
    label: 'Patients Status',
    link: path.patientsStatusReport,
    dataCy: 'nav-practice__patients-status-report',
  },
  {
    label: 'Performance Review',
    link: path.performanceReview,
    dataCy: 'nav-practice__performance-review',
  },
  // {
  //   label: 'Practice Overview',
  //   link: path.practiceOverview,
  //   dataCy: 'nav-practice__practice-overview',
  // },
  {
    label: "Yesterday's Statistics",
    link: path.statisticsReport,
    dataCy: 'nav-practice__yesterdays-statistics',
  },
];
const formsOptions = [
  {
    label: 'BAA',
    link: path.BAA,
    dataCy: 'nav-forms__baa',
    type: 'windows-link',
    ariaLabel: 'BAA form link',
  },
  {
    label: 'Patient Referral',
    link: path.patientReferral,
    dataCy: 'nav-forms__patient-referral',
    type: 'windows-link',
    ariaLabel: 'Patient Referral form link',
  },
  {
    label: 'W9 ',
    link: path.W9,
    dataCy: 'nav-forms__w9',
    type: 'windows-link',
    ariaLabel: 'W9 form link',
  },
];
const profileOptions = [
  {
    label: 'My Profile',
    link: path.profile,
    dataCy: 'nav-profile__profile',
  },
];

const billingToolsOptions = [
  {
    label: 'Claims - Athena',
    link: path.billingClaimsAthena,
    dataCy: 'nav-claims__athena',
  },
  {
    label: 'Claims - Epic',
    link: path.billingClaimsEpic,
    dataCy: 'nav-claims__epic',
  },
];

const Header = ({ disableNavigation }) => {
  const user = useUser();
  const mode = user.platformMode;

  let filteredPracticeOptions = filterRoutes(user, practiceOptions);
  let filteredPatientsOptions = filterRoutes(user, patientsOptions);
  const filteredAdminPanelOptions = filterRoutes(user, adminPanelOptions);
  const filteredBillingToolsOptions = filterRoutes(user, billingToolsOptions);
  const ccmOptions = [
    path.ccmBillingReport,
    path.ccmInteractiveTimeReport,
    path.ccmOnboardingStatus,
    path.ccmStatusReport,
  ];

  if (mode === PLATFORM_MODE_RPM) {
    filteredPracticeOptions = filteredPracticeOptions.filter(
      (item) => !ccmOptions.includes(item.link),
    );
  } else {
    filteredPracticeOptions = filteredPracticeOptions.filter((item) =>
      ccmOptions.includes(item.link),
    );
  }
  const hasAccessToSmsDashboard =
    isSmsDashboardOrUnregisteredRole(user.roleNames) || isAdmin(user.userType);

  if (!hasAccessToSmsDashboard) {
    filteredPatientsOptions = filteredPatientsOptions.filter(
      (item) => item.link !== path.smsDashboard,
    );
  }

  if (window.location.pathname.includes('/ccm/consent/')) return null;
  return (
    <div className="header">
      <Container>
        <HeaderNavbar
          adminPanelOptions={filteredAdminPanelOptions}
          formsOptions={formsOptions}
          practiceOptions={filteredPracticeOptions}
          patientsOptions={filteredPatientsOptions}
          profileOptions={profileOptions}
          billingToolsOptions={filteredBillingToolsOptions}
          firstName={user.firstName}
          lastName={user.lastName}
          title={user.title}
          disableNavigation={disableNavigation}
        />
        <HeaderNavbarMobileNew
          adminPanelOptions={filteredAdminPanelOptions}
          formsOptions={formsOptions}
          practiceOptions={filteredPracticeOptions}
          patientsOptions={filteredPatientsOptions}
          profileOptions={profileOptions}
          firstName={user.firstName}
          lastName={user.lastName}
          title={user.title}
          disableNavigation={disableNavigation}
        />
      </Container>
    </div>
  );
};

Header.propTypes = {
  disableNavigation: PropTypes.bool,
};

export default Header;
