import React, { useEffect } from 'react';
import classNames from 'classnames';
import { format, isToday, isYesterday, parse } from 'date-fns';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import Message from 'components/atoms/chat/message';
import InfiniteScroll from 'components/atoms/infinityScroll';
import common from 'constants/common';
import ConversationEmptyIcon from 'components/atoms/icons/conversationEmptyIcon';
import MessagesSearchIcon from 'components/atoms/icons/messagesSearchEmptyIcon';
import { useSmsDashboardMessages } from 'infrastructure/hooks/smsDashboard/useMessages';

import type {
  IConversation,
  IMessage,
  ConversationType,
  IMessagesListRes,
} from 'infrastructure/interfaces';
import type { RootState } from 'store';

interface IMessagesListProps {
  currentConversation: IConversation;
}

const messagesWrapperClass = 'messages-wrapper';
export const scrollMessagesToBottom = () => {
  const el = document.querySelector(`.${messagesWrapperClass}`);
  if (!el) return;
  el.scrollTop = el.scrollHeight;
};

const MessagesList: React.FC<IMessagesListProps> = (props) => {
  const { currentConversation } = props;
  const { t } = useTranslation();

  const { messagesSearchValue, conversationType } = useSelector(
    (state: RootState) => state.smsDashboard,
  );
  const { messages, fetchNextPage, messagesLoading, isLoading, isFetched } =
    useSmsDashboardMessages({
      conversationId: currentConversation.id,
      cursor: messagesSearchValue.cursor,
      searchQuery: messagesSearchValue.searchQuery,
    });

  useEffect(() => {
    scrollMessagesToBottom();
  }, [currentConversation, isFetched]);

  const groupByDate = (data: (IMessagesListRes | undefined)[] | undefined) => {
    const dataByDate: { [key: string]: IMessage[] } = {};
    data?.forEach((page) => {
      page?.items.forEach((item) => {
        const messageDate = new Date(item.createdAt);
        const formattedDate = format(
          messageDate,
          common.dateFormats.dateReverseHyphenSeparator,
        );

        if (!dataByDate[formattedDate]) {
          dataByDate[formattedDate] = [];
        }

        dataByDate[formattedDate].push(item);
      });
    });

    return Object.fromEntries(
      Object.entries(dataByDate).sort(([a], [b]) => b.localeCompare(a)),
    );
  };

  const groupedByDate = messages ? Object.entries(groupByDate(messages)) : [];

  const formatDate = (dateToFormat: Date | string) => {
    const date = new Date(dateToFormat);
    if (isToday(date)) {
      return t('smsDashboard.today');
    }
    if (isYesterday(date)) {
      return t('smsDashboard.yesterday');
    }
    return format(date, common.dateFormats.fullDateUS);
  };

  const messageGroupClassNames = (date: string) =>
    classNames('message-group', {
      active:
        messagesSearchValue?.cursor === undefined
          ? false
          : String(date) ===
            String(
              format(
                parse(
                  messagesSearchValue.cursor,
                  common.dateFormats.dateUTC,
                  new Date(),
                ),
                common.dateFormats.dateReverseHyphenSeparator,
              ),
            ),
    });

  const messageGroupDateTitle = (date: string) =>
    format(new Date(date), common.dateFormats.fullDateUS);

  return (
    <div className={messagesWrapperClass}>
      {groupedByDate.map((group) => (
        <div className={messageGroupClassNames(group[0])} key={group[0]}>
          <div
            className="message-group-date"
            title={messageGroupDateTitle(group[0])}
          >
            {formatDate(group[0])}
          </div>
          {(group[1] as IMessage[])
            .reverse()
            .filter((message: any, index: number, self: any) => {
              return (
                self.findIndex(
                  (otherMessage: any) => otherMessage.id === message.id,
                ) === index
              );
            })
            .map(
              (
                {
                  body,
                  authorType,
                  status,
                  createdAt,
                  from,
                  to,
                  action,
                  author,
                  mediaUrl,
                }: IMessage,
                index: number,
              ) => {
                const authorName =
                  authorType === 'patient'
                    ? currentConversation?.patient?.fullNameWithOnlyTitle
                    : author?.fullNameWithProfession;
                return (
                  <Message
                    left={authorType === 'patient'}
                    body={body}
                    action={action}
                    authorType={authorType}
                    authorName={authorName}
                    status={status}
                    createdAt={createdAt}
                    to={to}
                    from={from}
                    conversationType={conversationType as ConversationType}
                    key={index}
                    mediaUrl={mediaUrl}
                  />
                );
              },
            )}
        </div>
      ))}
      {!groupedByDate?.length &&
        isLoading === false &&
        messagesLoading === false && (
          <div className="empty-conversation" style={{ height: '100%' }}>
            <div className="message-wrapper">
              {messagesSearchValue.conversationId === undefined &&
              messagesSearchValue.cursor === undefined &&
              messagesSearchValue.searchQuery === undefined ? (
                <div className="empty-message">
                  <ConversationEmptyIcon />
                  <div className="message">
                    {t('smsDashboard.conversation_is_empty')}
                  </div>
                </div>
              ) : (
                <div className="empty-message search-empty">
                  <MessagesSearchIcon />
                  <div className="message" style={{ width: 300 }}>
                    <Trans
                      i18nKey="smsDashboard.empty_conversation_message"
                      values={{
                        description1: `${t(
                          'smsDashboard.no_result_found',
                        )},<br/>`,
                        description2: t('smsDashboard.change_filter'),
                      }}
                      components={{
                        primary: <span />,
                        secondary: <span />,
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      <InfiniteScroll
        loadMore={fetchNextPage}
        disabled={messagesLoading}
        allLoaded={
          messages === undefined || !messages[messages.length - 1]?.hasMore
        }
        uniqueId="messages-list"
      />
    </div>
  );
};

export default MessagesList;
