import PropTypes from 'prop-types';
import { format } from 'date-fns';
import FileUploadModal from 'components/molecules/csvUploadModal';
import { SOP_CSV_REPORT } from 'services/dataService/resources';
import dataService from 'services/dataService';
import { downloadCSV } from 'utils';
import { useTranslation } from 'react-i18next';

const SopReportModal = ({ isOpen, toggle }) => {
  const { t } = useTranslation();

  const uploadCsvFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    const { error, data } = await dataService.uploadFile(
      SOP_CSV_REPORT,
      formData,
    );

    if (error) {
      return { error };
    }

    if (data) {
      if (toggle) {
        toggle();
      }
      if (typeof data === 'object' && Object.keys(data).length > 0) {
        const today = new Date();
        const fileName = format(today, 'MM/dd/yyyy');
        for (let i = 0; i < Object.keys(data).length; i++) {
          const fileData = Object.values(data)[i];
          let patientsType = 'LoginCheck';
          if (Object.keys(data)[i].includes('emr')) {
            patientsType = 'PatientMRNCheck';
          }
          downloadCSV(fileData, `${fileName}_${patientsType}.csv`);
        }
      } else {
        throw new Error('Invalid response');
      }
    }
    return {};
  };

  return (
    <FileUploadModal
      isOpen={isOpen}
      toggle={toggle}
      title={t('adminTools.checkForExistingPatients')}
      sampleFileUrl="/csvTemplates/sopTemplate.csv"
      uploadCsvFile={uploadCsvFile}
    />
  );
};

SopReportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default SopReportModal;
