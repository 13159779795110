import { useRef, useEffect, type FC, type MutableRefObject } from 'react';
import Quill, { type QuillOptions } from 'quill';

const emptyValue = '<p><br></p>';

const theme = 'snow';

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ color: [] }, { background: [] }],
    ['link'],
    ['clean'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'list',
  'indent',
  'link',
  'color',
  'background',
];

export interface Props {
  value: string;
  setValue: (value: string) => void;
  disabled: boolean;
}

const ReactQuill: FC<Props> = ({ setValue, value, disabled }) => {
  const divRef = useRef<HTMLDivElement>(null);
  const quillRef: MutableRefObject<Quill | null> = useRef(null);

  useEffect(() => {
    if (divRef.current) {
      const opts: QuillOptions = {
        modules,
        formats,
        theme,
      };
      const quill = new Quill(divRef.current, opts);
      quillRef.current = quill;

      quill.on(Quill.events.TEXT_CHANGE, () => {
        setValue(quill.root.innerHTML);
      });
    }
  }, []);

  useEffect(() => {
    const currentValue = quillRef.current?.root.innerHTML;
    if ((currentValue === emptyValue || !currentValue) && value) {
      quillRef.current?.clipboard.dangerouslyPasteHTML(value);
    }
  }, [value]);

  useEffect(() => {
    quillRef.current?.enable(!disabled);
  }, [quillRef, disabled]);

  return <div ref={divRef} />;
};

export default ReactQuill;
