import {
  DEFAULT_DATE_RANGE,
  useActivityFilter,
  useGetInsuranceTypes,
  useSearchClinics,
  useSearchDoctors,
  useSearchPatients,
} from 'infrastructure/hooks/rpm';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { endOfDay, startOfDay } from 'date-fns';
import { timePresetKeys } from 'infrastructure/enums';

import { calculateChangedFields } from '../utils/calculate-changed-fields';

import type { PresetKeyEnum } from 'infrastructure/enums';

export type TDashboardFilter = ReturnType<typeof useDashboardFilter>;

export const useDashboardFilter = () => {
  const {
    filter,
    doctorSearchValue,
    updateQueryParams,
    updateQueryParamsAndResetPage,
    resetFilter,
  } = useActivityFilter();
  const { searchClinics, getClinicByGuid, clinics, searchClinicsLoading } =
    useSearchClinics();
  const { searchPatients, getPatientByGuid, patients, searchPatientsLoading } =
    useSearchPatients();
  const { insuranceTypes, refetchInsuranceType } = useGetInsuranceTypes(
    Boolean(filter.clinicGuid),
  );

  const { searchDoctors, doctors, searchDoctorsLoading, resetSearchDoctors } =
    useSearchDoctors(filter.clinicGuid);

  const countOfChangedFilterField = calculateChangedFields(filter);

  const defaultDateValues: [Date, Date] = useMemo(
    () =>
      filter.startTime && filter.endTime
        ? [new Date(filter.startTime), new Date(filter.endTime)]
        : DEFAULT_DATE_RANGE,
    [],
  );

  const dateValues: [Date, Date] | undefined = useMemo(
    () =>
      filter.startTime && filter.endTime
        ? [new Date(filter.startTime), new Date(filter.endTime)]
        : undefined,
    [filter.startTime, filter.endTime],
  );

  const debounceSearchClinics = useCallback(debounce(searchClinics, 800), []);
  const debounceSearchDoctors = useCallback(debounce(searchDoctors, 800), []);
  const debounceSearchPatients = useCallback(debounce(searchPatients, 800), []);

  const onDateChange = async (
    dates: [Date | null, Date | null],
    dateString: [string, string],
    info: { range?: 'start' | 'end' },
    presetKey?: string,
  ) => {
    const [startDate, endDate] = dates;

    if (startDate === null && endDate === null) {
      updateQueryParamsAndResetPage({
        startTime: undefined,
        endTime: undefined,
      });
      return;
    }

    const isTimePreset =
      presetKey && timePresetKeys.includes(presetKey as PresetKeyEnum);

    if (startDate && info.range === 'start') {
      updateQueryParamsAndResetPage({
        startTime: (isTimePreset ? startDate : startOfDay(startDate)).getTime(),
      });
    }
    if (endDate && info.range === 'end') {
      updateQueryParamsAndResetPage({
        endTime: (isTimePreset ? endDate : endOfDay(endDate)).getTime(),
      });
    }
  };

  const handleChangeValue = async (key: keyof typeof filter, value: string) => {
    if (key === 'clinicGuid') {
      updateQueryParamsAndResetPage({
        insuranceType: undefined,
        clinicGuid: value,
      });
      refetchInsuranceType();

      return;
    }

    if (key === 'reviewed') {
      updateQueryParamsAndResetPage({ action: Boolean(value) });

      return;
    }

    if (key === 'doctorGuid') {
      resetSearchDoctors();
    }

    updateQueryParamsAndResetPage({ [key]: value });
  };

  const handleClearValue = async (key: keyof typeof filter) => {
    updateQueryParamsAndResetPage({ [key]: undefined });
    if (key === 'clinicGuid') {
      updateQueryParamsAndResetPage({
        insuranceType: undefined,
        clinicGuid: undefined,
      });
    }
    if (key === 'doctorGuid') {
      updateQueryParamsAndResetPage({
        doctorGuid: undefined,
        doctorSearchValue: undefined,
      });
    }
  };

  const onSelectProvider = async (value: string) => {
    updateQueryParamsAndResetPage({
      doctorGuid: filter.doctorGuid ? filter.doctorGuid.concat(value) : [value],
    });
  };

  const onDeselectProvider = async (value: string) => {
    const newValue =
      filter.doctorGuid?.length === 1
        ? undefined
        : filter.doctorGuid?.filter((guid) => guid !== value);
    updateQueryParamsAndResetPage({
      doctorGuid: newValue,
    });
  };

  const onChangeProvider = async (value: string) => {
    updateQueryParamsAndResetPage({ doctorSearchValue: value });
    debounceSearchDoctors(value);
  };

  const onResetFilter = async () => {
    resetFilter();
  };

  useEffect(() => {
    if (filter?.clinicGuid) getClinicByGuid(filter?.clinicGuid);
    if (filter?.patientGuid) getPatientByGuid(filter?.patientGuid);
    if (doctorSearchValue) searchDoctors(doctorSearchValue);
    if (!filter.startTime || !filter.endTime) {
      const [startTime, endTime] = defaultDateValues;
      updateQueryParams({
        startTime: startTime.getTime(),
        endTime: endTime.getTime(),
      });
    }
  }, []);

  return {
    searchClinicsLoading,
    searchDoctorsLoading,
    searchPatientsLoading,
    debounceSearchClinics,
    onResetFilter,
    clinics,
    doctors,
    patients,
    debounceSearchDoctors,
    debounceSearchPatients,
    values: filter,
    dateValues,
    countOfChangedFilterField,
    insuranceTypes,
    onDateChange,
    handleChangeValue,
    handleClearValue,
    onSelectProvider,
    onDeselectProvider,
    onChangeProvider,
  };
};
