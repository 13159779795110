import { t } from 'i18next';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';

export const copyTextWithResult = async (text: string): Promise<boolean> => {
  try {
    await navigator.clipboard.writeText(text);
    notificationController.success({
      message: t('controls.copiedToClipboard'),
      duration: 2,
    });
    return true;
  } catch {
    notificationController.error({
      message: t('controls.errorCopying'),
      duration: 4,
    });
    return false;
  }
};
