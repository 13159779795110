import React from 'react';
import { Link } from 'react-router-dom';
import { Routes } from 'infrastructure/consts/routes';
import { fullNamePresenter } from 'utils';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';

import type { IAdminPanelPatient } from 'infrastructure/interfaces';

interface IProps {
  record: IAdminPanelPatient;
}

const PatientInfo: React.FC<IProps> = ({ record }) => {
  const { firstName, middleName, lastName, title } = record;

  const { t } = useTranslation();

  const showStatuses =
    record.holdingStatus?.status !== 'active' || record.consumablesArrivingDate;

  return (
    <div data-cy="patientTable-patientName" className={s['name-wrapper']}>
      <Link to={Routes.adminPatientDetail(record.guid)}>
        {fullNamePresenter({ firstName, middleName, lastName, title })}
      </Link>
      {showStatuses && (
        <div className={s['status-wrapper']}>
          {record.holdingStatus?.status !== 'active' && (
            <p className={s['holding-status']}>
              {capitalize(record.holdingStatus.status)}
            </p>
          )}
          {record.consumablesArrivingDate && (
            <p className={s['holding-status']}>
              {t('labels.awaitingConsumables')}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default PatientInfo;
