import dataService from 'services/dataService';
import { ACTIVITY_CSV_REPORT } from 'services/dataService/resources';
import { useMutation } from '@tanstack/react-query';
import { showResult } from 'infrastructure/hooks/utils';

import type { TActivityReportFilter } from './use-get-activity-report';

export const useActivitySendReport = (filters: TActivityReportFilter) => {
  const postData = {
    startTime: filters.startTime,
    endTime: filters.endTime,
    status: filters.status,
    clinicGuid: filters.clinicGuid,
    patientGuid: filters.patientGuid,
    doctorGuid: filters.doctorGuid,
    insuranceType: filters.insuranceType,
    reverse: filters.reverse,
    childClinic: filters.childClinic,
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: async () => {
      const { error, data } = await dataService.createOne(
        ACTIVITY_CSV_REPORT,
        postData,
      );
      if (error) {
        showResult(error);
        return;
      }
      return data;
    },
  });

  return {
    isLoading,
    sendReportToEmail: mutate,
  };
};
