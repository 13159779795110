import BaseButton from 'components/atoms/baseButton';
import FileUploadModal from 'components/molecules/csvUploadModal';
import { showResult } from 'infrastructure/hooks/utils';
import { useState } from 'react';
import dataService from 'services/dataService';
import { API_BILLING_CLAIMS_ATHENA_CREATE } from 'services/dataService/resources';
import { useTranslation } from 'react-i18next';
import { decodeAndStripBOM } from 'utils/helpers/decode-and-strip-BOM';
import { safeParse } from 'utils/helpers/safe-parse-json';
import PlusLgIcon from 'components/atoms/icons/plusLg';

import s from './style.module.scss';

import type { FC } from 'react';

interface Props {
  onUpload: () => any;
}

const BillingClaimsAthenaUpload: FC<Props> = ({ onUpload }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen((prev) => !prev);

  const uploadCsvFile = async (file: File) => {
    const fileName = file.name;
    const athenaJsonString = await decodeAndStripBOM(file);

    const { data: athenaJson, error: parsingError } =
      safeParse<Record<string, any>>(athenaJsonString);
    if (parsingError) {
      return { error: parsingError.message };
    }
    const { error, data } = await dataService.createOne(
      API_BILLING_CLAIMS_ATHENA_CREATE,
      {
        fileName,
        athenaJson,
      },
    );

    if (error) {
      return { error };
    }
    if (data) {
      setIsOpen(false);
      showResult();
      onUpload();
    }
    return {};
  };

  return (
    <div className={s['upload-button-wrapper']}>
      <BaseButton
        onClick={toggle}
        type="primary"
        label={t('labels.createNewProcess')}
        icon={<PlusLgIcon />}
        className={s['upload-button']}
      />
      <FileUploadModal
        isOpen={isOpen}
        toggle={toggle}
        title={t('labels.createNewProcess')}
        uploadCsvFile={uploadCsvFile}
        fileType="json"
      />
    </div>
  );
};

export default BillingClaimsAthenaUpload;
