import common from 'constants/common';
import i18next from 'i18next';
import { AVERAGE_HIT_VALUES } from 'infrastructure/consts/average-units';
import { ThresholdType } from 'infrastructure/enums';
import { ReadingStatus } from 'infrastructure/enums/reading-statuses';
import { isNotUndefined } from 'infrastructure/functions';

import type { IAverage, IPatientThresholds } from 'infrastructure/interfaces';

export type TAverageHits = {
  minRisk?: number;
  maxRisk?: number;
  minCritical?: number;
  maxCritical?: number;
};

export type TAverageStatus = {
  status: ReadingStatus;
  text: string;
};

export const getAverageStatus = (
  average: IAverage,
  thresholds?: void | IPatientThresholds,
): TAverageStatus | undefined => {
  const { name, status, value } = average;

  if (!thresholds || !(name in AVERAGE_HIT_VALUES)) return;

  const parsedValue = parseFloat(value);
  if (Number.isNaN(parsedValue) || parsedValue === 0) return;

  const itemHitKeys = Object.entries(AVERAGE_HIT_VALUES[name]);
  const averageHits = Object.entries(thresholds).reduce<TAverageHits>(
    (acc, [thresholdKey, thresholdValue]) => {
      const [hitKey] =
        itemHitKeys.find(([_, hitValue]) => hitValue === thresholdKey) ?? [];

      if (hitKey) {
        acc[hitKey as keyof TAverageHits] = thresholdValue;
      }

      if (average.name === ThresholdType.Temperature) {
        acc.maxCritical = common.temperatureCriticals.max;
        acc.minCritical = common.temperatureCriticals.min;
      }

      return acc;
    },
    {},
  );

  const getClosestHit = (
    minKey: keyof TAverageHits,
    maxKey: keyof TAverageHits,
    currentStatus: ReadingStatus,
  ): TAverageStatus | undefined => {
    const minHit = averageHits[minKey];
    const maxHit = averageHits[maxKey];

    let minText = i18next.t('labels.minCritical');
    let maxText = i18next.t('labels.maxCritical');

    if (currentStatus === ReadingStatus.Risk) {
      minText = i18next.t('labels.minAtRisk');
      maxText = i18next.t('labels.maxAtRisk');
    }

    if (isNotUndefined(minHit) && isNotUndefined(maxHit)) {
      return Math.abs(minHit - parsedValue) < Math.abs(maxHit - parsedValue)
        ? { status, text: `${minText}: ${minHit}` }
        : { status, text: `${maxText}: ${maxHit}` };
    }
    return undefined;
  };

  if (status === ReadingStatus.Critical) {
    return getClosestHit('minCritical', 'maxCritical', status);
  }

  if (status === ReadingStatus.Risk) {
    return getClosestHit('minRisk', 'maxRisk', status);
  }
};
