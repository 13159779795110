import React from 'react';
import {
  ADMIN_PANEL_PATIENTS_PER_PAGE,
  useGetAdminPanelPatients,
} from 'infrastructure/hooks/patient/use-admin-panel-patients';
import { useTranslation } from 'react-i18next';
import BaseTableReport from 'components/atoms/base-table/report/report';

import { adminPanelPatientsColumns } from './columns';
import { usePatientsFilter } from '../patients-filter/use-patients-filter';
import s from './styles.module.scss';

const AdminPatientsTable: React.FC = () => {
  const { t } = useTranslation();

  const { filter, updateQueryParams } = usePatientsFilter();
  const {
    status,
    page,
    ccmStatus,
    patientGuid,
    clinicGuid,
    excludeClinicGuid,
    allExcluded,
  } = filter;
  const { patients, isFetching } = useGetAdminPanelPatients({
    status,
    page,
    ccmStatus,
    clinicGuid,
    excludeClinicGuid,
    patientGuid,
  });
  const tableItems = allExcluded ? [] : patients?.items ?? [];
  const patientsCount = allExcluded ? 0 : patients?.count;

  return (
    <BaseTableReport
      className={s.table}
      rowKey="guid"
      headerTitle={t('labels.patients')}
      dataCy="patients-table"
      columns={adminPanelPatientsColumns}
      dataSource={tableItems}
      isLoading={isFetching}
      pagination={{
        current: page,
        pageSize: ADMIN_PANEL_PATIENTS_PER_PAGE,
        total: patientsCount,
        onChange: (newPage) => {
          updateQueryParams({ page: newPage });
        },
      }}
      showCount
      anchor
    />
  );
};
export default AdminPatientsTable;
