import EpicClaimsHeader from 'components/templates/billingTools/epic/header';
import EpicProcessesTable from 'components/templates/billingTools/epic/table';
import { useEpicData } from 'infrastructure/hooks/billingTools/epic/useData';
import { useEpicEmrNames } from 'infrastructure/hooks/billingTools/epic/useEpicEmrNames';
import { useEpicClaimsFilters } from 'infrastructure/hooks/billingTools/epic/useFilters';

const EpicClaimsPage = () => {
  const { changePage, filters } = useEpicClaimsFilters();
  const { data, isLoading, refetch } = useEpicData(filters);
  const { data: emrNames, isLoading: emrNamesLoading } = useEpicEmrNames();

  return (
    <div>
      <EpicClaimsHeader
        onUpload={() => refetch()}
        isEmrNamesLoading={emrNamesLoading}
        emrNameList={emrNames.data}
      />
      <EpicProcessesTable
        changePage={changePage}
        filters={filters}
        count={data?.count}
        data={data?.data}
        isLoading={isLoading}
      />
    </div>
  );
};

export default EpicClaimsPage;
