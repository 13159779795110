import { Button, notification } from 'antd';
import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  WarningOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import COLORS from 'assets/styles/color.module.scss';
import i18next from 'i18next';

import s from './styles.module.scss';

import type { ArgsProps } from 'antd/lib/notification';
import type { CSSProperties } from 'react';

type NotificationProps = ArgsProps;

const wrapperStyles: CSSProperties = {
  borderRadius: '10px',
  color: COLORS.WHITE,
  boxSizing: 'border-box',
  width: 500,
};

const openSuccessNotification = (config: NotificationProps): void => {
  notification.success({
    ...config,
    icon: (
      <div className={s['icon-wrapper']}>
        <CheckCircleFilled />
      </div>
    ),
    message: <div className={s['message-wrapper']}>{config.message}</div>,
    description: config.description ? (
      <div>{config.description}</div>
    ) : (
      <div className={s['empty-description']} />
    ),
    style: {
      ...wrapperStyles,
      backgroundColor: COLORS.RANGE_LAND,
    },
  });
};

const openInfoNotification = (config: NotificationProps): void => {
  notification.info({
    ...config,
    icon: (
      <div className={s['icon-wrapper']}>
        <InfoCircleOutlined />
      </div>
    ),
    message: <div className={s['message-wrapper']}>{config.message}</div>,
    description: config.description ? (
      <div className={s['description-wrapper']}>{config.description}</div>
    ) : (
      <div className={s['empty-description']} />
    ),
    style: {
      ...wrapperStyles,
      backgroundColor: COLORS.TELOPEA,
    },
  });
};

const openWarningNotification = (config: NotificationProps): void => {
  notification.warning({
    ...config,
    icon: (
      <div className={s['icon-wrapper']}>
        <ExclamationCircleFilled />
      </div>
    ),
    message: <div className={s['message-wrapper']}>{config.message}</div>,
    description: config.description ? (
      <div>{config.description}</div>
    ) : (
      <div className={s['empty-description']} />
    ),
  });
};

const openErrorNotification = (config: NotificationProps): void => {
  notification.error({
    ...config,
    icon: (
      <div className={s['icon-wrapper']}>
        <WarningOutlined />
      </div>
    ),
    message: <div className={s['message-wrapper']}>{config.message}</div>,
    description: config.description ? (
      <div>{config.description}</div>
    ) : (
      <div className={s['empty-description']} />
    ),
    style: {
      ...wrapperStyles,
      backgroundColor: COLORS.ROCKIN_RED,
    },
  });
};

const destroy = (key: string | number) => {
  notification.destroy(key);
};

interface UndoNotificationProps extends NotificationProps {
  onUndo: () => void;
}

const openUndoNotification = (config: UndoNotificationProps): void => {
  const key = config.key || `openUndoNotification${Date.now()}`;

  notification.info({
    ...config,
    closable: false,
    pauseOnHover: false,
    showProgress: true,
    key,
    icon: (
      <div className={s['icon-wrapper']}>
        <InfoCircleOutlined />
      </div>
    ),
    message: (
      <div className={s['undo-wrapper']}>
        <div className={s['message-wrapper']}>{config.message}</div>
        <Button
          onClick={() => {
            notification.destroy(key);
            config.onUndo();
          }}
        >
          {i18next.t('controls.undo')}
        </Button>
      </div>
    ),
    description: config.description ? (
      <div>{config.description}</div>
    ) : (
      <div className={s['empty-description']} />
    ),
    style: {
      ...wrapperStyles,
      backgroundColor: COLORS.TELOPEA,
    },
  });
};

export const notificationController = {
  success: openSuccessNotification,
  info: openInfoNotification,
  warning: openWarningNotification,
  error: openErrorNotification,
  destroy,
  showUndo: openUndoNotification,
};
