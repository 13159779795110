import BaseTableReport from 'components/atoms/base-table/report/report';
import { useTranslation } from 'react-i18next';
import { ACTIVITY_REPORT_PER_PAGE } from 'infrastructure/hooks/rpm/activityReport/use-get-activity-report';

import { useColumns } from './useColumns';

import type { TActivityReportFilter } from 'infrastructure/hooks/rpm/activityReport/use-get-activity-report';
import type { IRpmPatientActivity } from 'infrastructure/interfaces/i-rpm-patient-activity';
import type { FC } from 'react';

interface Props {
  isLoading: boolean;
  activity?: {
    data?: IRpmPatientActivity[] | undefined;
    count?: number | undefined;
  };
  filters: TActivityReportFilter;
  changePage: (page: number) => void;
}

const ActivityReportTable: FC<Props> = ({
  activity,
  isLoading,
  filters,
  changePage,
}) => {
  const { t } = useTranslation();
  const columns = useColumns();

  return (
    <div>
      <BaseTableReport
        isLoading={isLoading}
        columns={columns}
        headerTitle={t('labels.readings')}
        dataSource={activity?.data}
        pagination={{
          current: filters.page,
          pageSize: ACTIVITY_REPORT_PER_PAGE,
          total: activity?.count,
          onChange: changePage,
        }}
        showCount
        anchor
        rowClassName="activity-row"
      />
    </div>
  );
};

export default ActivityReportTable;
