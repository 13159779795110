import PropTypes from 'prop-types';
import FileUploadModal from 'components/molecules/csvUploadModal';
import { RESET_INTERACTION_STATUS } from 'services/dataService/resources';
import dataService from 'services/dataService';
import { downloadCSV } from 'utils';
import { useTranslation } from 'react-i18next';

const ResetInteractionStatus = ({ isOpen, toggle }) => {
  const { t } = useTranslation();
  const uploadCsvFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    const { error, data } = await dataService.uploadFile(
      RESET_INTERACTION_STATUS,
      formData,
    );

    if (error) {
      return { error };
    }

    if (data) {
      downloadCSV(data, `${file.name}_interactionStatusResult.csv`);
      if (toggle) {
        toggle();
      }
    }
    return {};
  };

  return (
    <FileUploadModal
      isOpen={isOpen}
      toggle={toggle}
      title={t('adminTools.resetInteractionStatus')}
      sampleFileUrl="/csvTemplates/interactionStatusTemplate.csv"
      uploadCsvFile={uploadCsvFile}
    />
  );
};

ResetInteractionStatus.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default ResetInteractionStatus;
