export const safeParse = <T = any>(
  json: string,
): { data: T; error: null } | { data: null; error: SyntaxError | Error } => {
  try {
    const data: T = JSON.parse(json);
    return { data, error: null };
  } catch (error) {
    if (error instanceof SyntaxError) {
      return { data: null, error };
    }
    if (error instanceof Error) {
      return { data: null, error };
    }
    return {
      data: null,
      error: new Error(`JSON parsing error: ${error}}`),
    };
  }
};
