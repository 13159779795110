import { createModel } from '@rematch/core';

import type { RootModel } from 'store/models';

export type TActionValue = {
  sending?: boolean;
  message?: string;
};

export type TAction = Record<string, TActionValue>;
export type TState = {
  actions: TAction;
  aiCommentGenCount: number;
};

const INITIAL_STATE: TState = {
  actions: {},
  aiCommentGenCount: 0,
};

const activityActions = createModel<RootModel>()({
  state: INITIAL_STATE,
  reducers: {
    updateAction: (
      { actions, ...other },
      { idx, action }: { idx: number; action: TActionValue },
    ) => ({
      ...other,
      actions: {
        ...actions,
        [idx]: { ...actions[idx], ...action },
      },
    }),
    resetActions: (state) => ({ ...state, actions: {} }),
    resetCurrentActions: (state, idx: number) => ({
      ...state,
      actions: { ...state.actions, [idx]: {} },
    }),
    increaseAiCommentCount: ({ aiCommentGenCount, ...other }) => ({
      ...other,
      aiCommentGenCount: aiCommentGenCount + 1,
    }),
    decreaseAiCommentCount: ({ aiCommentGenCount, ...other }) => ({
      ...other,
      aiCommentGenCount: aiCommentGenCount - 1,
    }),
  },
});

export default activityActions;
