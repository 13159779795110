import {
  format as formatDate,
  parseISO,
  parse,
  startOfDay,
  endOfDay,
  isValid,
} from 'date-fns';
import { DateFormats, Timezones } from 'infrastructure/enums';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import type { DateRange } from 'infrastructure/types';

interface IDateFormatterProps {
  date?: string | Date;
  format?: DateFormats;
}

export const DateFormatter = (props: IDateFormatterProps) => {
  const { date, format = DateFormats['MM-dd-yyyy'] } = props;
  if (!date) return '';

  const localDate = typeof date === 'string' ? parseISO(date) : date;

  if (isValid(localDate)) return formatDate(localDate, format);

  return `${date}`;
};

export const startOfDate = (date: Date) => startOfDay(date);
export const endOfDate = (date: Date) => endOfDay(date);

export const prepareDateRange = (range: DateRange) => {
  return [startOfDate(range[0]), endOfDate(range[1])];
};

export const getUserTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const convertDateToTimeZone = (
  date: Date,
  targetTimeZone: string,
): Date => {
  const targetTime = utcToZonedTime(date, targetTimeZone);
  return targetTime;
};

export const convertTimeToTimeZone = (
  time: string,
  targetTimeZone: string,
  timeZone: string = Timezones['America/Chicago'],
): Date => {
  const currentDate = new Date();
  const [hours, minutes] = time.split(':').map(Number);

  const dateTime = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    hours,
    minutes,
  );

  const utcTime = zonedTimeToUtc(dateTime, timeZone);
  const targetTime = utcToZonedTime(utcTime, targetTimeZone);

  return targetTime;
};

export const convertTime = (
  time?: string,
  timezone = Timezones['America/Chicago'],
) => {
  if (!time) return time;
  const date = convertTimeToTimeZone(time, timezone);
  return DateFormatter({
    date,
    format: DateFormats['h:mm a'],
  });
};

export const convertToDate = (
  dateString: string,
  formatString: DateFormats,
): Date => {
  const parsedDate = parse(dateString, formatString, new Date());

  if (Number.isNaN(parsedDate.getTime())) {
    throw new Error('Incorrect date format');
  }

  return parsedDate;
};

export const changeFormat = (
  date: string,
  formatFrom: DateFormats,
  formatTo: DateFormats,
) => {
  const dateParsed = convertToDate(date, formatFrom);
  if (isValid(dateParsed)) return formatDate(dateParsed, formatTo);
};
