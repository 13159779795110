import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import BaseForm from 'components/atoms/base-form';
import BaseFormGroup from 'components/atoms/base-form/group';
import BaseFormItem from 'components/atoms/base-form/item';
import BaseGrid from 'components/atoms/base-grid';
import BaseInput from 'components/atoms/base-input';
import { mergeFieldsValue } from 'infrastructure/functions';

import { agencyContactInformationValidationSchema } from './validation-schema';

import type { AgencyInfo } from 'infrastructure/classes/agency/agency-info';
import type { IAgencyContactInformation } from 'infrastructure/interfaces';
import type {
  IIndependentFormProps,
  IIndependentFormRef,
} from 'infrastructure/interfaces/common/i-independent-form';

const prepareValue = (data?: AgencyInfo): IAgencyContactInformation => {
  let defaultValue: IAgencyContactInformation = {
    legalname: '',
    email: null,
    phone: null,
    fax: null,
  };
  if (data) {
    defaultValue = mergeFieldsValue(defaultValue, data);
  }

  return defaultValue;
};

const FormDisplayName = 'AgencyContactInformationForm';

interface IProps extends IIndependentFormProps {
  data?: AgencyInfo;
}

const AgencyContactInformationForm = forwardRef<
  IIndependentFormRef<IAgencyContactInformation>,
  IProps
>((props, ref) => {
  const { data } = props;
  const { t } = useTranslation();

  const formik = useFormik<IAgencyContactInformation>({
    initialValues: prepareValue(data),
    onSubmit: () => {},
    validateOnChange: false,
    validationSchema: agencyContactInformationValidationSchema(),
  });

  const submitForm = async () => {
    await formik.submitForm();
    const isValid = await formik
      .validateForm()
      .then((res) => !Object.keys(res).length);

    return {
      values: formik.values,
      formName: FormDisplayName,
      isValid,
    };
  };

  useImperativeHandle(ref, () => ({
    submit: submitForm,
    reset: formik.resetForm,
  }));

  useEffect(() => {
    if (data) formik.resetForm({ values: prepareValue(data) });
  }, [data]);

  return (
    <BaseForm formik={formik}>
      <BaseFormGroup label={t('labels.contactInformation')}>
        <BaseGrid columns={2}>
          <BaseFormItem
            name="legalname"
            label={t('labels.legalName')}
            required
            isBlackLabel
          >
            <BaseInput />
          </BaseFormItem>
          <BaseFormItem
            name="phone"
            label={t('labels.businessPhone')}
            isBlackLabel
          >
            <BaseInput placeholder={t('labels.phoneNumber')} />
          </BaseFormItem>
          <BaseFormItem
            name="email"
            label={t('labels.businessEmail')}
            isBlackLabel
          >
            <BaseInput />
          </BaseFormItem>
          <BaseFormItem name="fax" label={t('labels.businessFax')} isBlackLabel>
            <BaseInput />
          </BaseFormItem>
        </BaseGrid>
      </BaseFormGroup>
    </BaseForm>
  );
});

AgencyContactInformationForm.displayName = FormDisplayName;

export default AgencyContactInformationForm;
