export enum PresetKeyEnum {
  LastSevenDays = 'lastSevenDays',
  LastFourteenDays = 'lastFourteenDays',
  LastTwentyFourDays = 'lastTwentyFourDays',
  LastFourHours = 'lastFourHours',
  CurrentMonth = 'currentMonth',
  PreviousMonth = 'previousMonth',
  TwoMonthsPast = 'twoMonthsPast',
  ThreeMonthsPast = 'threeMonthsPast',
}

export const timePresetKeys = [
  PresetKeyEnum.LastFourHours,
  PresetKeyEnum.LastSevenDays,
  PresetKeyEnum.LastFourteenDays,
  PresetKeyEnum.LastTwentyFourDays,
];

export enum CCMAgentDashboardPresetKeyEnum {
  ThisWeek = 'thisWeek',
  NextWeek = 'nextWeek',
  CurrentMonth = 'currentMonth',
  PreviousMonth = 'previousMonth',
  TwoMonthsPast = 'twoMonthsPast',
  ThreeMonthsPast = 'threeMonthsPast',
}
