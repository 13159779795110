import RadioButton from 'components/atoms/radioButton';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'utils';
import { differenceInCalendarDays, isFuture } from 'date-fns';

import s from './styles.module.scss';

import type { IItemHeaderProps } from './types';
import type { FC } from 'react';

const ItemHeader: FC<IItemHeaderProps> = ({
  mode,
  nextHoldingStatus,
  options,
  values,
  setStatusCallback,
}) => {
  const { isEnabled, isDefault, isEdit, isRemoved } = mode;
  const { t } = useTranslation();

  const today = new Date();

  const remainingDays =
    values.dateRange?.[1] &&
    differenceInCalendarDays(new Date(values.dateRange?.[1]), today);

  const isStatusInFuture =
    values.dateRange?.[0] && isFuture(new Date(values.dateRange?.[0]));

  const currentHoldingStatusText =
    typeof values.holdingStatus !== 'undefined' &&
    typeof remainingDays !== 'undefined'
      ? `${Number(remainingDays) + 1} Day${
          Number(remainingDays) > 1 ? 's' : ''
        } Left`
      : '';

  return (
    <div className={s.header} data-cy="holding-status-header">
      {(isDefault || isRemoved) && (
        <>
          <div className={s['header-left']}>{t('labels.holdingStatus')}</div>
          <div className={s['header-reason']}>
            <span>{t('labels.reason')}:</span>

            <div className={s['radio-buttons']}>
              {options.map((option, index) => (
                <RadioButton
                  key={index}
                  name="holdingStatus"
                  id={option.id}
                  label={option.label}
                  onChange={() => {
                    setStatusCallback(option.id);
                  }}
                  isChecked={values.holdingStatus === option.id}
                />
              ))}
            </div>
          </div>
        </>
      )}

      {(isEdit || isEnabled) && (
        <>
          <div className={s['header-left']}>
            <span className={s.circle} />
            {capitalize(nextHoldingStatus || values.holdingStatus)}
          </div>
          {!isStatusInFuture && (
            <div className={s['header-reason']}>{currentHoldingStatusText}</div>
          )}
        </>
      )}
    </div>
  );
};

export default ItemHeader;
