export const isVersionGreater = (oldVersion: string, newVersion: string) => {
  const parts1 = oldVersion.split('.').map(Number);
  const parts2 = newVersion.split('.').map(Number);

  for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
    const num1 = parts1[i] || 0;
    const num2 = parts2[i] || 0;

    if (num1 < num2) return true;
    if (num1 > num2) return false;
  }
  return false;
};
