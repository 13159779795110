import s from './style.module.scss';

import type { FC } from 'react';

interface Props {
  jsonString: string;
}

function syntaxHighlight(json: string) {
  const escapedJson = json
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');

  return escapedJson.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\\-]?\d+)?)/g,

    (match) => {
      let cls = s.number;

      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = s.key;
        } else {
          cls = s.string;
        }
      } else if (/true|false/.test(match)) {
        cls = s.boolean;
      } else if (/null/.test(match)) {
        cls = s.null;
      }

      return `<span class="${cls}">${match}</span>`;
    },
  );
}

const SyntaxHighlight: FC<Props> = ({ jsonString }) => {
  const highlightedJSON = syntaxHighlight(jsonString);

  return (
    <code
      className={s.code}
      dangerouslySetInnerHTML={{ __html: highlightedJSON }}
    />
  );
};

export default SyntaxHighlight;
