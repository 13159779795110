import paths from 'constants/paths';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { hasPatientAcceptanceRole } from 'utils/accessManager/helpers';
import { makeid } from 'utils/helpers/makeid';
import useUser from 'utils/useUser';
import {
  isAdmin,
  isAgencyDoctor,
  isRoleNameAdminTools,
} from 'utils/userTypeHelper';

export const useAdminDropdownButtons = () => {
  const { t } = useTranslation();
  const [vitalCsvUploadOpen, setVitalCsvUploadOpen] = useState(false);
  const [sopCsvUploadOpen, setSopCsvUploadOpen] = useState(false);
  const [resendEmrOpen, setResendEmrOpen] = useState(false);
  const [hidePatientDataOpen, setHidePatientDataOpen] = useState(false);
  const [resetInteractionStatusOpen, setResetInteractionStatusOpen] =
    useState(false);
  const [billingFileUploaderOpen, setBillingFileUploaderOpen] = useState(false);
  const navigate = useNavigate();
  const { userType, roleNames } = useUser();

  const iamAdminOrAdminTools = useMemo(
    () => isAdmin(userType) || isRoleNameAdminTools(roleNames),
    [userType, roleNames],
  );
  const iamAdminOrHHA = useMemo(
    () => isAdmin(userType) || isAgencyDoctor(userType),
    [userType],
  );
  const iamPatientAcceptance = useMemo(
    () => hasPatientAcceptanceRole(roleNames),
    [roleNames],
  );
  const iamAdmin = useMemo(() => isAdmin(userType), [userType]);
  const addButtons = [
    {
      key: makeid(5),
      label: t('adminTools.newPatients'),
      dataCy: 'add-new-patient',
      handleClick: () => {
        navigate(paths.adminPatientAdd);
      },
      render: true,
    },
    {
      key: makeid(5),
      label: t('adminTools.newUsers'),
      dataCy: 'add-new-users',
      handleClick: () => {
        navigate(paths.adminUserAdd);
      },
      render: true,
    },
    {
      key: makeid(5),
      label: t('adminTools.newAgencyUser'),
      dataCy: 'add-new-agency-users',
      handleClick: () => {
        navigate(paths.adminAgencyDoctorAdd);
      },
      render: iamAdminOrHHA,
    },
    {
      key: makeid(5),
      label: t('adminTools.newClinics'),
      dataCy: 'add-new-clinics',
      handleClick: () => {
        navigate(paths.adminClinicAdd);
      },
      render: iamAdminOrHHA,
    },
    {
      key: makeid(5),
      label: t('adminTools.newHHA'),
      dataCy: 'add-new-hha',
      handleClick: () => {
        navigate(paths.adminAgencyAdd);
      },
      render: iamAdmin,
    },
    {
      key: makeid(5),
      label: t('adminTools.newTurkUser'),
      dataCy: 'add-new-turk-user',
      handleClick: () => {
        navigate(paths.adminTurkUsersAdd);
      },
      render: iamAdmin,
    },
  ].filter((x) => x.render);

  const csvButtons = [
    {
      key: makeid(5),
      label: t('adminTools.createBatchReports'),
      dataCy: 'csv-batch-reports',
      handleClick: () => {
        setVitalCsvUploadOpen(true);
      },
      render: true,
    },
    {
      key: makeid(5),
      label: t('adminTools.checkForExistingPatients'),
      dataCy: 'csv-check-for-existing-patients',
      handleClick: () => {
        setSopCsvUploadOpen(true);
      },
      render: true,
    },
    {
      key: makeid(5),
      label: t('adminTools.resetInteractionStatus'),
      dataCy: 'csv-reset-interaction-status',
      handleClick: () => {
        setResetInteractionStatusOpen(true);
      },
      render: true,
    },
    {
      key: makeid(5),
      label: t('adminTools.resendEMRReadings'),
      dataCy: 'csv-resend-emr-readings',
      handleClick: () => {
        setResendEmrOpen(true);
      },
      render: true,
    },
    {
      key: makeid(5),
      label: t('adminTools.hidePatientData'),
      dataCy: 'csv-hide-patient-data',
      handleClick: () => {
        setHidePatientDataOpen(true);
      },
      render: iamAdmin,
    },
    {
      key: makeid(5),
      label: t('adminTools.uploadBillingFile'),
      dataCy: 'csv-billing-file-uploader',
      handleClick: () => {
        setBillingFileUploaderOpen(true);
      },
      render: iamAdminOrAdminTools,
    },
  ].filter((e) => e.render);

  return {
    addButtons,
    csvButtons,
    vitalCsvUploadOpen,
    sopCsvUploadOpen,
    isShowCsv: iamAdminOrAdminTools,
    isShowAdd: !iamPatientAcceptance,
    resendEmrOpen,
    resetInteractionStatusOpen,
    setResetInteractionStatusOpen,
    setResendEmrOpen,
    setSopCsvUploadOpen,
    setVitalCsvUploadOpen,
    hidePatientDataOpen,
    setHidePatientDataOpen,
    billingFileUploaderOpen,
    setBillingFileUploaderOpen,
  };
};
