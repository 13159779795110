import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { serviceDeliveryThresholdsDataSource } from 'infrastructure/data-sources/service-delivery-thresholds';
import SlaCrownIcon from 'components/atoms/icons/sla-crown.svg?react';

import s from './styles.module.scss';

interface IProps {
  value?: number;
}

const Sla: React.FC<IProps> = ({ value }) => {
  const { t } = useTranslation();

  const sla = serviceDeliveryThresholdsDataSource({
    shortName: true,
  }).findByField('id', value);

  return (
    <div
      data-cy="patientTable-sla"
      className={classNames(s.sla, { [s['no-sla']]: !sla })}
    >
      <SlaCrownIcon width={16} height={16} />
      <p>{sla ? sla.name : t('controls.no')}</p>
    </div>
  );
};

export default Sla;
