import { useQuery } from '@tanstack/react-query';
import QueryParams from 'utils/queryParams';
import { API_BILLING_CLAIMS_ATHENA_LIST } from 'services/dataService/resources';
import dataService from 'services/dataService';
import { secondsToMilliseconds } from 'date-fns';

import type { BillingClaimsAthenaData, Filters } from './types';

const REFETCH_INTERVAL_MS = secondsToMilliseconds(30);

export const useBillingClaimsAthenaData = (filters: Filters) => {
  const queryKey = ['billingClaimsAthena', filters] as const;

  const { data, error, isLoading, isPreviousData, refetch } = useQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: () => getItems(filters),
    refetchInterval: (_data) => {
      const haveUncompleted = _data?.items.some((item) => item.result === null);
      if (haveUncompleted) {
        return REFETCH_INTERVAL_MS;
      }
      return false;
    },
  });

  return {
    data: {
      data: data?.items,
      count: data?.count,
    },
    refetch,
    isLoading: isLoading || isPreviousData,
    error,
  };
};

const getItems = async (filters: Filters) => {
  const url = QueryParams.stringifyUrl(API_BILLING_CLAIMS_ATHENA_LIST, filters);
  const { error, data } = await dataService.getList<BillingClaimsAthenaData>(
    url,
  );
  if (error) {
    throw new Error(error);
  }
  return data;
};
