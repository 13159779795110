import { DateFormats } from 'infrastructure/enums';
import { DateFormatter, groupArrayByFieldname } from 'infrastructure/functions';
import EmptyState from 'components/atoms/icons/empty-comments.svg?react';
import { useTranslation } from 'react-i18next';
import { Fragment, useEffect, useRef, type FC } from 'react';
import { makeid } from 'utils/helpers/makeid';

import s from './styles.module.scss';
import MessageItem from './message-item';
import ReviewedItem from './reviewed-item';
import CallItem from './call-item';

import type { IActivityComments } from 'infrastructure/interfaces/i-rpm-patient-activity';

interface CommentsColumnProps {
  comments: IActivityComments[];
  patientGuid: string;
}

const CommentsColumn: FC<CommentsColumnProps> = ({ comments, patientGuid }) => {
  const listWrapper = useRef<HTMLUListElement>(null);
  const { t } = useTranslation();
  const sortedCommentsByDate = comments.sort((a, b) =>
    // eslint-disable-next-line no-nested-ternary
    new Date(b.createdAt).getTime() < new Date(a.createdAt).getTime()
      ? -1
      : new Date(b.createdAt).getTime() > new Date(a.createdAt).getTime()
      ? 1
      : 0,
  );
  const commentWithFormattedDate = sortedCommentsByDate.map((comment) => ({
    ...comment,
    formattedCreateAt: DateFormatter({
      date: comment.createdAt,
      format: DateFormats['MMM d, yyyy'],
    }),
  }));
  const groupedComments = groupArrayByFieldname(
    commentWithFormattedDate,
    'formattedCreateAt',
  );

  useEffect(() => {
    if (listWrapper.current) {
      listWrapper.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [groupedComments]);

  return (
    <ul data-cy="comment-list" className={s['comments-list']} ref={listWrapper}>
      {Object.keys(groupedComments).length === 0 ? (
        <li className={s['empty-list']} key={`empty-list-${makeid(5)}`}>
          <div>
            <EmptyState width={64} height={62} />
            <p>{t('messages.emptyComments')}</p>
          </div>
        </li>
      ) : (
        Object.keys(groupedComments).map((date) => {
          const commentsOfDate = groupedComments[date];

          return (
            <Fragment key={makeid(5)}>
              <li
                data-cy="comments-day"
                className={s['comments-day']}
                key={date + makeid(5)}
              >
                <p>{date}</p>
              </li>
              {commentsOfDate.map((comment) => {
                const isMockComment = comment.guid.startsWith('id_');

                return (
                  <Fragment key={makeid(5)}>
                    {comment.type === 'text' && comment.message === null && (
                      <ReviewedItem
                        comment={comment}
                        key={comment.guid + makeid(5)}
                      />
                    )}
                    {comment.type === 'text' && comment.message !== null && (
                      <MessageItem
                        comment={comment}
                        isMockComment={isMockComment}
                        key={comment.guid + +makeid(5)}
                      />
                    )}
                    {comment.type === 'call' && (
                      <CallItem
                        comment={comment}
                        patientGuid={patientGuid}
                        key={comment.guid + makeid(5)}
                      />
                    )}
                  </Fragment>
                );
              })}
            </Fragment>
          );
        })
      )}
    </ul>
  );
};
export default CommentsColumn;
