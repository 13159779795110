import { ReadingStatus } from 'infrastructure/enums/reading-statuses';

import type {
  IActivityData,
  StatusKeys,
} from 'infrastructure/interfaces/i-rpm-patient-activity';

const readingsUnits: Record<StatusKeys, string> = {
  glucose: 'mg/dL',
  systolic: 'mmHg',
  diastolic: 'mmHg',
  pulse: 'bpm',
  weight: 'lbs',
  spo2: '%',
  fev1: 'L',
  pef: 'L/min',
  temperature: '°F',
  oxygen: '%',
};

export const hiddenThresholds = [
  'duration',
  'rem',
  'respiration',
  'bodyTurnOver',
  'lightSleep',
  'deepSleep',
  'bedTime',
  'getUpTime',
  'unit',
  'diastolic',
];

const thresholdPresenter: Partial<Record<StatusKeys, string>> = {
  pef: 'PEF',
  fev1: 'FEV1',
  systolic: 'Blood Pressure',
};

export const getReadingUnit = (threshold: StatusKeys) =>
  readingsUnits[threshold] || '';

const getBloodPressureStatus = (
  systolic: ReadingStatus,
  diastolic: ReadingStatus,
): ReadingStatus => {
  if (
    systolic === ReadingStatus.Critical ||
    diastolic === ReadingStatus.Critical
  ) {
    return ReadingStatus.Critical;
  }
  if (systolic === ReadingStatus.Risk || diastolic === ReadingStatus.Risk) {
    return ReadingStatus.Risk;
  }
  return ReadingStatus.Normal;
};

type TReadingInfo = {
  key: string;
  value: string;
  unit: string;
  className: string;
};

export const getReadingInfo = (
  statuses: IActivityData['statuses'],
  value: IActivityData['value'],
  threshold: StatusKeys,
): TReadingInfo => {
  const result = {
    key: thresholdPresenter[threshold] ?? threshold,
    value: value[threshold].toString(),
    unit: getReadingUnit(threshold),
    className: (statuses as Record<StatusKeys, ReadingStatus>)[threshold],
  };

  if (threshold === 'systolic' && threshold in statuses) {
    result.value = `${value.systolic}/${value.diastolic}`;
    result.className = getBloodPressureStatus(
      statuses.systolic,
      statuses.diastolic,
    );
  }

  if (threshold === 'spo2' && 'oxygen' in statuses) {
    result.className = statuses.oxygen;
  }

  return result;
};
