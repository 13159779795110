import PropTypes from 'prop-types';
import FileUploadModal from 'components/molecules/csvUploadModal';
import { BATCH_CSV_REPORT } from 'services/dataService/resources';
import dataService from 'services/dataService';
import { showResult } from 'infrastructure/hooks/utils';
import { useTranslation } from 'react-i18next';

const BatchReportModal = ({ isOpen, toggle }) => {
  const { t } = useTranslation();
  const uploadCsvFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    const { error, data } = await dataService.uploadFile(
      BATCH_CSV_REPORT,
      formData,
    );

    if (error) {
      return { error };
    }

    if (data) {
      showResult();
      if (toggle) {
        toggle();
      }
    }
    return {};
  };

  return (
    <FileUploadModal
      isOpen={isOpen}
      toggle={toggle}
      title={t('adminTools.createBatchReports')}
      sampleFileUrl="/csvTemplates/reportTemplate.csv"
      uploadCsvFile={uploadCsvFile}
    />
  );
};

BatchReportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default BatchReportModal;
