import React from 'react';
import BaseTableNew from 'components/atoms/base-table/new';
import { useThresholdLog } from 'infrastructure/hooks/logs/use-threshold-log';

import { thresholdLogsColumns } from './cols';

interface IPatientThresholdLogProps {
  patientGuid: string;
  dateRange?: [Date, Date];
}

const PatientThresholdLog: React.FC<IPatientThresholdLogProps> = (props) => {
  const { patientGuid, dateRange } = props;

  const { loading, items, meta, setMeta } = useThresholdLog({
    patientGuid,
    meta: {
      dateRange,
    },
  });

  const onChangePage = (page: number) => {
    setMeta({
      ...meta,
      page,
    });
  };

  return (
    <BaseTableNew
      rowKey="key"
      dataCy="threshold-log-table"
      columns={thresholdLogsColumns()}
      items={items}
      loading={loading}
      pagination={{
        current: meta.page,
        pageSize: meta.items,
        total: meta.totalCount,
      }}
      onChangePage={onChangePage}
      bordered
    />
  );
};

export default PatientThresholdLog;
