import React, { useMemo, useState } from 'react';
import BaseTabs from 'components/atoms/base-tabs';
import { useTranslation } from 'react-i18next';
import PatientStatusLog from 'components/molecules/admin-panel/forms/patient-status-log';
import PatientHoldingStatusLog from 'components/molecules/admin-panel/forms/patient-holding-status-log';
import PatientCcmStatusLog from 'components/molecules/admin-panel/forms/patient-ccm-status-log';
import PatientBlock from 'components/molecules/patientBlock';
import BaseRangeDatepicker from 'components/atoms/base-range-datepicker';
import PatientCcmClaimStatusLog from 'components/molecules/admin-panel/forms/patient-ccm-claim-status-log';
import PatientRpmClaimStatusLog from 'components/molecules/admin-panel/forms/patient-rpm-claim-status-log';
import { makeid } from 'utils/helpers/makeid';
import PatientConsumableLog from 'components/molecules/admin-panel/forms/consumable-log';
import { ModuleType } from 'infrastructure/enums';
import PatientEditorLog from 'components/molecules/admin-panel/forms/patient-editor-log';
import PatientThresholdLog from 'components/molecules/admin-panel/forms/threshold-log';

import s from './styles.module.scss';

import type { DateRange } from 'infrastructure/types';
import type { IBaseTab } from 'components/atoms/base-tabs';

interface IPatientLogTabsProps {
  patientGuid: string;
  module: ModuleType;
}

const PatientLogTabs: React.FC<IPatientLogTabsProps> = (props) => {
  const { patientGuid, module } = props;

  const [key, setKey] = useState<string>(makeid(5));
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dateRange, setDateRange] = useState<DateRange | undefined>();
  const { t } = useTranslation();

  const tabs: IBaseTab[] = useMemo(() => {
    const items = [
      {
        key: `patientStatusLog-${key}`,
        label: t('labels.patientStatusLog'),
        children: (
          <PatientStatusLog patientGuid={patientGuid} dateRange={dateRange} />
        ),
      },
      {
        key: `rpmClaimStatusLog-${key}`,
        label: t('labels.rpmClaimStatusLog'),
        children: (
          <PatientRpmClaimStatusLog
            patientGuid={patientGuid}
            dateRange={dateRange}
          />
        ),
      },
      {
        key: `patientCcmStatusLog-${key}`,
        label: t('labels.patientCcmStatusLog'),
        children: (
          <PatientCcmStatusLog
            patientGuid={patientGuid}
            dateRange={dateRange}
          />
        ),
      },
      {
        key: `ccmClaimStatusLog-${key}`,
        label: t('labels.ccmClaimStatusLog'),
        children: (
          <PatientCcmClaimStatusLog
            patientGuid={patientGuid}
            dateRange={dateRange}
          />
        ),
      },
      {
        key: `holdingStatusLog-${key}`,
        label: t('labels.holdingStatusLog'),
        children: (
          <PatientHoldingStatusLog
            patientGuid={patientGuid}
            dateRange={dateRange}
          />
        ),
      },
    ];

    if (module === ModuleType.ADMIN) {
      items.push({
        key: `historyLog-${key}`,
        label: t('labels.historyLog'),
        children: (
          <PatientEditorLog patientGuid={patientGuid} dateRange={dateRange} />
        ),
      });
    }

    if (module === ModuleType.RPM) {
      items.push({
        key: `consumablesLog-${key}`,
        label: t('labels.consumablesLog'),
        children: (
          <PatientConsumableLog
            patientGuid={patientGuid}
            dateRange={dateRange}
          />
        ),
      });
    }
    if (module === ModuleType.ADMIN || module === ModuleType.RPM) {
      items.push({
        key: `thresholdLog-${key}`,
        label: t('labels.thresholdsLog'),
        children: (
          <PatientThresholdLog
            patientGuid={patientGuid}
            dateRange={dateRange}
          />
        ),
      });
    }
    return items;
  }, [dateRange]);

  return (
    <PatientBlock
      title={t('labels.patientLogs')}
      canDelete={false}
      canEdit={false}
    >
      <div className={s.wrapper}>
        <BaseTabs
          key={key}
          tabs={tabs}
          defaultIndex={selectedIndex}
          contentClassName={s.content}
          type="rounded"
          onChange={(_, index) => setSelectedIndex(index)}
          wrapperClassName={s['tabs-wrapper']}
        />
        <div className={s['date-picker']}>
          <BaseRangeDatepicker
            withPresets
            onChange={(range) => {
              setKey(makeid(5));
              setDateRange(range);
            }}
          />
        </div>
      </div>
    </PatientBlock>
  );
};

export default PatientLogTabs;
