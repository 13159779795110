import React from 'react';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import AdminHeaderAddIcon from 'components/atoms/icons/adminHeaderAddIcon';
import AdminHeaderCsvIcon from 'components/atoms/icons/adminHeaderCsvIcon';
import BatchReportModal from 'components/organisms/adminHeader/batchReportModal';
import SopReportModal from 'components/organisms/adminHeader/sopReportModal';
import ResendEmrModal from 'components/organisms/adminHeader/resendEmrModal';
import ResetInteractionStatus from 'components/organisms/adminHeader/resetInteractionStatus';
import HidePatientDataModal from 'components/organisms/adminHeader/hidePatientDataModal';
import NavigationHeader from 'components/molecules/navigation-header';
import BillingFileUploaderModal from 'components/organisms/adminHeader/billingFileUploaderModal';

import s from './styles.module.scss';
import { useAdminDropdownButtons } from './hook';

import type { INavigationHeaderProps } from 'components/molecules/navigation-header';
import type { FC } from 'react';

interface IProps extends INavigationHeaderProps {
  controls?: React.ReactNode;
}

const AdminNavigationHeader: FC<IProps> = (props) => {
  const { goBackPath, title, controls } = props;
  const {
    addButtons,
    csvButtons,
    isShowCsv,
    isShowAdd,
    vitalCsvUploadOpen,
    sopCsvUploadOpen,
    resendEmrOpen,
    resetInteractionStatusOpen,
    setResetInteractionStatusOpen,
    setResendEmrOpen,
    setSopCsvUploadOpen,
    setVitalCsvUploadOpen,
    hidePatientDataOpen,
    setHidePatientDataOpen,
    billingFileUploaderOpen,
    setBillingFileUploaderOpen,
  } = useAdminDropdownButtons();

  return (
    <NavigationHeader goBackPath={goBackPath} title={title}>
      <div className={s.wrapper}>
        {isShowCsv && (
          <UncontrolledDropdown>
            <DropdownToggle
              data-cy="search-bar-csv-dropdown-toggle"
              className={s.toggle}
              tag="div"
            >
              <AdminHeaderCsvIcon width={40} height={40} />
            </DropdownToggle>
            <DropdownMenu right className={s.menu} dir="bottom">
              {csvButtons.map((btn) => (
                <DropdownItem
                  key={btn.key}
                  data-cy={btn.dataCy}
                  className={s['menu-item']}
                  onClick={btn.handleClick}
                >
                  {btn.label}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
        {isShowAdd && (
          <UncontrolledDropdown>
            <DropdownToggle
              data-cy="search-bar-add-dropdown-toggle"
              className={s.toggle}
              tag="div"
            >
              <AdminHeaderAddIcon width={40} height={40} />
            </DropdownToggle>
            <DropdownMenu right className={s.menu} dir="bottom">
              {addButtons.map((btn) => (
                <DropdownItem
                  key={btn.key}
                  data-cy={btn.dataCy}
                  className={s['menu-item']}
                  onClick={btn.handleClick}
                >
                  {btn.label}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
        {controls}
      </div>
      {isShowCsv && (
        <BatchReportModal
          isOpen={vitalCsvUploadOpen}
          toggle={setVitalCsvUploadOpen}
        />
      )}
      <SopReportModal isOpen={sopCsvUploadOpen} toggle={setSopCsvUploadOpen} />
      <ResendEmrModal isOpen={resendEmrOpen} toggle={setResendEmrOpen} />
      <ResetInteractionStatus
        isOpen={resetInteractionStatusOpen}
        toggle={setResetInteractionStatusOpen}
      />
      <HidePatientDataModal
        isOpen={hidePatientDataOpen}
        toggle={() => setHidePatientDataOpen((prev) => !prev)}
      />
      <BillingFileUploaderModal
        isOpen={billingFileUploaderOpen}
        toggle={() => setBillingFileUploaderOpen((prev) => !prev)}
      />
    </NavigationHeader>
  );
};
export default AdminNavigationHeader;
