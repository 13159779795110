import { useState } from 'react';
import { makeid } from 'utils/helpers/makeid';

type UseKey = {
  key: string;
  changeKey: () => void;
};

const useKey = (): UseKey => {
  const [key, setKey] = useState(makeid(10));

  const changeKey = () => {
    setKey(makeid(10));
  };

  return {
    key,
    changeKey,
  };
};

export default useKey;
