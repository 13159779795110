import { Pagination, Table } from 'antd';
import ScrollToTopButton from 'components/atoms/scroll-to-top-button';
import BaseLoader from 'components/atoms/base-loader';
import classNames from 'classnames';
import { createSortOrder } from 'infrastructure/antd/functions/create-sort-order';

import s from './styles.module.scss';
import EmptyTable from './empty';

import type { SorterField } from 'infrastructure/antd/interfaces/i-modified-sorter-result';
import type { SortParam } from 'infrastructure/types';
import type { FilterValue } from 'antd/es/table/interface';
import type { TablePaginationConfig, TableProps } from 'antd';
import type { FC } from 'react';

export interface IBaseTableReportProps extends TableProps {
  pagination: TablePaginationConfig;
  isLoading?: boolean;
  headerTitle?: string;
  className?: string;
  dataCy?: string;
  anchor?: boolean;
  showCount?: boolean;
  onSort?: (sort?: SortParam) => void;
}

const BaseTableReport: FC<IBaseTableReportProps> = (props) => {
  const {
    isLoading,
    className,
    showCount,
    dataCy,
    pagination,
    anchor,
    headerTitle,
    rowKey = 'guid',
    rowClassName,
    onSort,
  } = props;
  const mergedClassNames = classNames(s.table, className);

  const paginationProps: TablePaginationConfig = {
    hideOnSinglePage: true,
    showSizeChanger: false,
    disabled: isLoading,
    ...pagination,
  };

  const footerClassNames = classNames(s.footer, {
    [s.anchor]:
      pagination.total &&
      pagination.pageSize &&
      pagination.total < pagination.pageSize,
  });

  const handleTableChange = async (
    pag: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterField,
  ) => {
    const sort = createSortOrder(sorter);
    if (onSort) onSort(sort);
  };

  return (
    <div>
      {headerTitle && (
        <div className={s.header}>
          <p>
            {headerTitle}
            {showCount && (
              <>
                :<span> {pagination?.total} </span>
              </>
            )}
          </p>
          <Pagination data-cy="pagination" {...paginationProps} />
        </div>
      )}
      <div className={s.divider} />
      <Table
        {...props}
        className={mergedClassNames}
        data-cy={dataCy}
        scroll={{ x: true }}
        bordered
        loading={{
          spinning: isLoading,
          indicator: <BaseLoader loading />,
        }}
        locale={{
          emptyText: <EmptyTable isLoading={isLoading} />,
        }}
        rowHoverable={false}
        pagination={false}
        rowKey={rowKey}
        onChange={handleTableChange}
        rowClassName={rowClassName}
      />
      <div className={footerClassNames}>
        <Pagination {...paginationProps} />
        {!isLoading && anchor && <ScrollToTopButton />}
      </div>
    </div>
  );
};

export default BaseTableReport;
