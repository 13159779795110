import { notificationController } from 'infrastructure/antd/controllers/notification-controller';
import { isVersionGreater } from 'infrastructure/functions/is-version-greater';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BroadcastTopics } from 'services/wsService';
import { Button } from 'antd';
import { t } from 'i18next';
import { APP_VERSION } from 'utils/env';

import type { RootState } from 'store';

const toastId = 'versionToastKey';

window.APP_VERSION = APP_VERSION;

const versionUpdateHandler = (data: { version: string }) => {
  const newVersion = data.version;
  const currentVersion = window.APP_VERSION || '1.0.0';
  if (isVersionGreater(currentVersion, newVersion)) {
    notificationController.info({
      key: toastId,
      message: t('messages.newVersionAvailable'),
      description: t('messages.clickButtonToUpdate'),
      closable: true,
      duration: 0,
      btn: (
        <Button onClick={() => window.location.reload()}>
          {t('controls.refreshThePage')}
        </Button>
      ),
    });
  }
};

export const useVersionUpdateListener = () => {
  const socket = useSelector((state: RootState) => state.socket);

  useEffect(() => {
    if (!socket?.client) return;

    socket.client.on(
      BroadcastTopics.FrontendVersionChange,
      versionUpdateHandler,
    );
  }, [socket.isConnected]);
};
