import paths from 'constants/paths';
import CcmIcon from 'components/atoms/icons/ccmIcon';
import RpmIcon from 'components/atoms/icons/rpmIcon';

export const ADMIN_USER_TYPE = 'admin';
export const PRACTICE_USER_TYPE = 'practice';
export const HHA_USER_TYPE = 'homeHealth';
export const PATIENT_USER_TYPE = 'patient';

export const PATIENT_ACCEPTANCE_ROLE = 'patientAcceptance';
export const ADMINISTRATOR_ROLE_NAME = 'admin-panel-access';
export const PHYSICIAN_ASSISTANT_ROLE_NAME = 'physicianAssistant';
export const NURSE_PRACTITIONER_ROLE_NAME = 'nursePractitioner';
export const REGISTERED_NURSE_ROLE_NAME = 'registeredNurse';
export const QHCP_ROLE_NAME_ROLE_NAME = 'QHCP';
export const BILLER_ROLE_NAME = 'biller';
export const DOCTOR_ROLE_NAME = 'doctor';
export const SUPPORT_ROLE_NAME = 'support';
export const CRITICAL_ALERTING = 'criticalAlerting';
export const AT_RISK_ALERTING = 'atRiskAlerting';
export const CLINICAL_ROLE_NAME = 'clinical';
export const ADMIN_DEFAULT_NAME = 'default';
export const BILLING_TOOLS_NAME = 'billingTools';

export const CCM_PREFIX = 'ccm-';

export const PLATFORM_MODE_RPM = 'RPM';
export const PLATFORM_MODE_CCM = 'CCM';
export const PLATFORM_MODE_PATIENT_ACCEPTANCE = 'PATIENT_ACCEPTANCE';
export const PLATFORM_MODE_ADMIN_PANEL_ACCESS = 'ADMIN-PANEL-ACCESS';

export const PLATFORM_MODE_LOCALSTORAGE_KEY = 'EVELYN_PLATFORM_MODE';

export const PLATFORM_CONFIG = {
  [PLATFORM_MODE_CCM]: {
    dashboard: paths.ccm,
    switch: PLATFORM_MODE_RPM,
    icon: CcmIcon,
  },
  [PLATFORM_MODE_RPM]: {
    dashboard: paths.dashboard,
    switch: PLATFORM_MODE_CCM,
    icon: RpmIcon,
  },
  [PLATFORM_MODE_PATIENT_ACCEPTANCE]: {
    dashboard: paths.adminPatients,
  },
  [PLATFORM_MODE_ADMIN_PANEL_ACCESS]: {
    dashboard: paths.adminPatients,
  },
};
