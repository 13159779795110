import BaseRangeDatepicker from 'components/atoms/base-range-datepicker';
import { endOfDate, startOfDate } from 'infrastructure/functions';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import { CCMAgentDashboardPresetKeyEnum } from 'infrastructure/enums';

import s from './styles.module.scss';

import type {
  RangePickerValue,
  TOnCalendarChange,
} from 'components/atoms/base-range-datepicker';
import type {
  Filters,
  AppendFilters,
} from 'infrastructure/hooks/ccmAgentDashboard/types';
import type { FC } from 'react';

type TRange = {
  nextAppointmentDateFrom?: string;
  nextAppointmentDateTo?: string;
};
const toISODateRange = ({
  start,
  end,
}: {
  start?: Date | null;
  end?: Date | null;
}) => {
  const dateRange: TRange = {
    nextAppointmentDateFrom: undefined,
    nextAppointmentDateTo: undefined,
  };
  if (start)
    dateRange.nextAppointmentDateFrom = startOfDate(start).toISOString();
  if (end) {
    dateRange.nextAppointmentDateTo = endOfDate(end).toISOString();
  }

  return dateRange;
};

interface Props {
  filters: Filters;
  appendFilters: AppendFilters;
}

const NextAppointmentDateRange: FC<Props> = ({ appendFilters, filters }) => {
  const changeTimeRange: TOnCalendarChange = async (dates) => {
    const [start, end] = dates;
    if (!start && !end) {
      appendFilters(toISODateRange({}));
      return;
    }
    appendFilters(toISODateRange({ start, end }));
  };

  const selectedRange: RangePickerValue = [
    filters.nextAppointmentDateFrom
      ? new Date(filters.nextAppointmentDateFrom)
      : null,
    filters.nextAppointmentDateTo
      ? new Date(filters.nextAppointmentDateTo)
      : null,
  ];

  const { t } = useTranslation();

  dayjs.extend(updateLocale);
  dayjs.updateLocale('en', {
    weekStart: 1,
  });

  const presets = [
    {
      label: <span data-cy="ranges">{t('dates.thisWeek')}</span>,
      value: [dayjs().startOf('w').toDate(), dayjs().endOf('w').toDate()] as [
        Date,
        Date,
      ],
      key: CCMAgentDashboardPresetKeyEnum.ThisWeek,
    },
    {
      label: <span data-cy="ranges">{t('dates.nextWeek')}</span>,
      value: [
        dayjs().add(7, 'd').startOf('w').toDate(),
        dayjs().add(7, 'd').endOf('w').toDate(),
      ] as [Date, Date],
      key: CCMAgentDashboardPresetKeyEnum.NextWeek,
    },
    {
      label: <span data-cy="ranges">{t('dates.currentMonth')}</span>,
      value: [dayjs().startOf('M').toDate(), dayjs().endOf('M').toDate()] as [
        Date,
        Date,
      ],
      key: CCMAgentDashboardPresetKeyEnum.CurrentMonth,
    },
    {
      label: <span data-cy="ranges">{t('dates.previousMonth')}</span>,
      value: [
        dayjs().add(-1, 'M').startOf('M').toDate(),
        dayjs().add(-1, 'M').endOf('M').toDate(),
      ] as [Date, Date],
      key: CCMAgentDashboardPresetKeyEnum.PreviousMonth,
    },
    {
      label: <span data-cy="ranges">{t('dates.twoMonthsPast')}</span>,
      value: [
        dayjs().add(-2, 'M').startOf('M').toDate(),
        dayjs().add(-2, 'M').endOf('M').toDate(),
      ] as [Date, Date],
      key: CCMAgentDashboardPresetKeyEnum.TwoMonthsPast,
    },
    {
      label: <span data-cy="ranges">{t('dates.threeMonthsPast')}</span>,
      value: [
        dayjs().add(-3, 'M').startOf('M').toDate(),
        dayjs().add(-3, 'M').endOf('M').toDate(),
      ] as [Date, Date],
      key: CCMAgentDashboardPresetKeyEnum.ThreeMonthsPast,
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <div className={s['next-appointment-date-range']}>
        <label htmlFor="nextAppointmentDate">
          {t('labels.nextAppointmentDate')}
        </label>
        <div className={s['input-wrapper']}>
          <BaseRangeDatepicker
            withPresets
            presets={() => presets}
            value={selectedRange}
            onCalendarChange={changeTimeRange}
          />
        </div>
      </div>
    </div>
  );
};

export default NextAppointmentDateRange;
