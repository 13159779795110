import { memo, type FC } from 'react';
import ExpandableFilterPanel from 'components/molecules/expandable-filter-panel';
import { useActivityReportFilter } from 'infrastructure/hooks/rpm/activityReport/use-filters';

import Body from './body';

interface FilterProps {}

const Filter: FC<FilterProps> = () => {
  const { countOfChangedFilterField, onResetFilter, ...bodyProps } =
    useActivityReportFilter();

  return (
    <>
      <ExpandableFilterPanel
        filtersCount={countOfChangedFilterField}
        onReset={onResetFilter}
        isExpanded
      >
        <Body {...bodyProps} />
      </ExpandableFilterPanel>
    </>
  );
};
export default memo(Filter);
